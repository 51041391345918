import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AbstractAuditTrailService } from '@iot-platform/audit-trail';
import { ENVIRONMENT } from '@iot-platform/core';
import { DiffUtils } from '@iot-platform/iot-platform-utils';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { CommonApiListResponse, Environment } from '@iot-platform/models/common';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type HistoryLogParams = { deviceIdentifier: string };

@Injectable({
  providedIn: 'root'
})
export class DevicesAuditTrailService extends AbstractAuditTrailService<HistoryLogParams> {
  protected readonly httpClient: HttpClient = inject(HttpClient);
  protected readonly environment: Environment = inject(ENVIRONMENT);

  getBaseUrl(id: string) {
    return `${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${id}/trails`;
  }

  getHistoryLogs(params: HistoryLogParams): Observable<HistoryLog[]> {
    return this.httpClient
      .get<CommonApiListResponse<HistoryLog>>(this.getBaseUrl(params.deviceIdentifier))
      .pipe(map((response) => response.content.map((item) => ({ ...item, concept: 'device' }))));
  }

  getHistoryLogById(params: { id: string } & HistoryLogParams): Observable<HistoryLogDetail> {
    return this.httpClient.get<HistoryLogDetail>(`${this.getBaseUrl(params.deviceIdentifier)}/${params.id}`).pipe(
      map((logDetail: HistoryLogDetail) => {
        if (logDetail.updatedAttributes.includes('currentConfiguration')) {
          const oldConfig = get(logDetail, 'oldImage.currentConfiguration', {});
          const newConfig = get(logDetail, 'newImage.currentConfiguration', {});
          const predicate = (a: { v: number | string }, b: { v: number | string }): boolean => a?.v !== b?.v;
          logDetail.oldImage.currentConfiguration = { ...DiffUtils.diffObjectsWith(oldConfig, newConfig, predicate) };
          logDetail.newImage.currentConfiguration = { ...DiffUtils.diffObjectsWith(newConfig, oldConfig, predicate) };
        }
        return logDetail;
      })
    );
  }
}
