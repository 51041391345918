import { AssetVariable } from '@iot-platform/models/i4b';
import { BaseWidgetOptions } from './base-widget-options.model';

export interface TimeseriesWidgetOptions extends BaseWidgetOptions {
  variables: any[];
  chart?: {
    searchBar?: {
      enabled?: boolean;
    };
    legend?: {
      enabled?: boolean;
      // eslint-disable-next-line @typescript-eslint/ban-types
      labelFormat?: Function;
    };
    export?: {
      enabled?: boolean;
    };
  };
}

export interface LastValueWidgetOptions extends BaseWidgetOptions {
  variable: AssetVariable;
}
