<div [ngClass]="{ 'simple-card--with-icon': icon() || image() }" class="simple-card {{ cssClasses() }}">
  <div fxLayout="row" fxLayout.lt-md="column">
    @if (image() || icon()) {
      <div class="simple-card__image-container" fxLayout="column wrap" fxLayoutAlign="center center" fxFlex="150px"
           fxFlex.lt-md="100%">
        @if (image()) {
          <img class="simple-card__image" [src]="image()" alt="" />
        }
        @if (icon()) {
          <mat-icon class="simple-card__icon" [svgIcon]="icon()" color="accent" />
        }
      </div>
    }
    <div class="simple-card__content full-width" fxFlex="100%" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start stretch">
        <ng-content select="[cardContent]"></ng-content>
        @if (withMenu()) {
          <div class="simple-card__menu app-round-button">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              @for (action of actions(); track action) {
                @if (action?.enabled) {
                  <button mat-menu-item (click)="onDispatchEvent(action)">{{ action?.label | translate | uppercase }}
                  </button>
                }
              }
            </mat-menu>
          </div>
        }
      </div>
    </div>
  </div>
</div>
