export enum ReportExportSource {
  ROTAREX = 'time-list',
  MESSAGES = 'regular-com',
  FIELD_TESTERS = 'field-testers',
  MAINTENANCE = 'maintenance',
  SERVITRAX_ASSETS_DATA_GAPS = 'export-inconsistencies',
  CONNECTOR_TO_BE_MAINTAINED = 'connector-to-be-maintained',
  OPERATING_EXPORT = 'operating-export',
  FINANCIAL = 'financial-report'
}
