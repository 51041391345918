import { LabelValue } from '../common/label-value';

export type ModbusListItem = LabelValue;

export enum ModbusListName {
  LstLat = 'LstLat',
  LstLong = 'LstLong',
  LstComTab = 'LstComTab',
  LstRat = 'LstRat',
  LstSF = 'LstSF',
  LstEC = 'LstEC',
  LstBW = 'LstBW',
  LstConfig = 'LstConfig',
  LstEnergyMode = 'LstEnergyMode',
  LstStaMode = 'LstStaMode',
  LstParity = 'LstParity',
  LstMbStopbits = 'LstMbStopbits',
  LstChannel = 'LstChannel',
  LstChannelInternal = 'LstChannelInternal',
  LstChannelSensor = 'LstChannelSensor',
  LstChannelCalculation = 'LstChannelCalculation',
  LstModemDiag = 'LstModemDiag',
  LstModemPhase = 'LstModemPhase',
  LstModemStatus = 'LstModemStatus',
  LstRelayAction = 'LstRelayAction',
  LstMbType = 'LstMbType',
  LstKindType = 'LstKindType',
  LstMeasStatus = 'LstMeasStatus',
  LstTypeEnum = 'LstTypeEnum',
  LstEnable = 'LstEnable',
  LstTypeModem = 'LstTypeModem',
  LstUnitEnum = 'LstUnitEnum',
  LstAlarmType = 'LstAlarmType',
  LstPathEnum = 'LstPathEnum',
  LstRecMode = 'LstRecMode',
  LstTrigAct = 'LstTrigAct',
  LstTrigACQ = 'LstTrigACQ',
  LstFctMB = 'LstFctMB',
  LstAlarmStatus = 'LstAlarmStatus',
  LstSignalStrength = 'LstSignalStrength',
  LstModbusRole = 'LstModbusRole',
  LstADCType = 'LstADCType',
  LstAlimType = 'LstAlimType',
  LstLightContext = 'LstLightContext',
  LstTankType = 'LstTankType',
  LstAutonomyStatus = 'LstAutonomyStatus',
  LstGasType = 'LstGasType',
  LstHTTPmode = 'LstHTTPmode',
  LstGatewayMode = 'LstGatewayMode',
  LstPcktType = 'LstPcktType',
  LstPHY = 'LstPHY',
  LstMbReqStatus = 'LstMbReqStatus',
  LstMaintenanceStatus = 'LstMaintenanceStatus',
  LstModbusError = 'LstModbusError',
  LstLoRaChannel = 'LstLoRaChannel',
  LstKstStep = 'LstKstStep',
  LstRegion = 'LstRegion',
  LstAuthentificationMode = 'LstAuthentificationMode',
  LstSXRAT = 'LstSXRAT',
  LstOnOFF = 'LstOnOFF',
  LstCertComParam = 'LstCertComParam',
  LstModem = 'LstModem',
  LstSMSgatewayShortID = 'LstSMSgatewayShortID',
  LstHardVersion = 'LstHardVersion',
  LstStartingDate = 'LstStartingDate'
}

export enum ModbusMappingType {
  uint16 = 'uint16',
  float = 'float',
  float32 = 'float32',
  int32 = 'int32',
  uint32 = 'uint32',
  string = 'string',
  btimestamp = 'btimestamp',
  int16 = 'int16'
}

export enum ModbusMappingAccessControl {
  WRITABLE = 'writable',
  READ_ONLY = 'readOnly'
}

export interface ModbusMapping {
  id: string;
  type: ModbusMappingType;
  label: string;
  address10: number;
  address16: string;
  acls: ModbusMappingAccessControl;
  listName?: ModbusListName;
  minimum?: number;
  maximum?: number;
  maxLength?: number;
}

export type ModbusList = ModbusListItem[];

export interface ModbusTable {
  mappings: { [key: string]: ModbusMapping };
  lists: { [key: string]: ModbusList };
}
