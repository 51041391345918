import { I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';

export interface I4BColumn<Head extends I4BColumnHeader, Conf extends I4BColumnConfiguration, Opt extends I4BColumnOptions> {
  className: any;
  columnId: string;
  dataLibs: DataLibrary[];
  header: Head;
  configuration: Conf;
  options: Opt;
}
