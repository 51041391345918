<mat-nav-list [disableRipple]="true" class="iot-platform-ui-list-view-items">

  @for (item of items(); track $index) {
    <mat-list-item
      (click)="onSelectItem(item)"
      [lines]="2"
      [ngClass]="{ 'mat-mdc-list-item--selected': selectedItem()?.id === item?.id }"
      data-cy="iot-platform-ui-list-view-items-item"
      class="iot-platform-ui-list-view-items__item {{item?.status}} {{item?.cssClassName}}"
    >
      @if (item?.canRemove) {
        <div class="iot-platform-ui-list-view-items__item_avatar text-error"
             data-cy="iot-platform-ui-list-view-items-item-avatar"
             matListItemAvatar>
          <mat-icon>delete</mat-icon>
        </div>
      } @else if (item?.imageUrl) {
        <img [src]="item?.imageUrl"
             alt="iot-platform-ui-list-view-items-item-avatar"
             class="iot-platform-ui-list-view-items__item_avatar"
             data-cy="iot-platform-ui-list-view-items-item-avatar"
             matListItemAvatar />
      } @else if (item?.svgIcon) {
        <div class="iot-platform-ui-list-view-items__item_avatar"
             data-cy="iot-platform-ui-list-view-items-item-avatar"
             matListItemAvatar>
          <mat-icon [svgIcon]="item?.svgIcon" />
        </div>
      } @else if (item?.icon) {
        <div class="iot-platform-ui-list-view-items__item_avatar"
             data-cy="iot-platform-ui-list-view-items-item-avatar"
             matListItemAvatar>
          <mat-icon>{{ item?.icon }}</mat-icon>
        </div>
      }

      <span data-cy="iot-platform-ui-list-view-items-item-title" class="iot-platform-ui-list-view-items__item_title"
            matListItemTitle>
        {{ item.label | translate }}
        @if (item?.canRemove) {
          <span class="text-error fw-700">{{ item.removeHint | translate }}</span>
        }
      </span>

      <span data-cy="iot-platform-ui-list-view-items-item-description"
            class="iot-platform-ui-list-view-items__item_description"
            matListItemLine>{{ item?.description }}</span>

      @if (selectedItem()?.id === item?.id) {
        <div data-cy="iot-platform-ui-list-view-items-item-meta" matListItemMeta>
          <mat-icon>arrow_right</mat-icon>
        </div>
      }
    </mat-list-item>
  }

</mat-nav-list>
