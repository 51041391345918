import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { BaseCardDetailsWidgetComponent } from '../base-card-details-widget/base-card-details-widget.component';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, BaseCardDetailsWidgetComponent, NgTemplateOutlet, AsyncPipe, MatTooltip, MatButtonModule, InfoDisplayPipe],
  selector: 'iot-platform-ui-card-details-metadata-widget',
  templateUrl: './card-details-metadata-widget.component.html',
  styleUrls: ['./card-details-metadata-widget.component.scss']
})
export class CardDetailsMetadataWidgetComponent extends BaseCardDetailsWidgetComponent {}
