import { Injectable } from '@angular/core';
import { ApiFeatureSelector, CommonIndexedPagination, Filter, TagCategory } from '@iot-platform/models/common';
import * as app from '../reducers';
import * as tagCategory from '../reducers/tag-categories.reducer';

@Injectable({
  providedIn: 'root'
})
export class TagCategoriesSelectors extends ApiFeatureSelector<tagCategory.TagCategoryState, TagCategory, CommonIndexedPagination, Filter> {
  constructor() {
    super(app.applicationFeatures.selectTagCategories, tagCategory.tagCategoriesAdapter);
  }
}
