import { BaseState, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { DeviceVariable } from '@iot-platform/models/dalia';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DeviceVariablesActions } from '../actions';

export interface State extends BaseState<DeviceVariable, Pagination, Filter> {
  // Generated dalia variable list based on modbus table
  // To be used when merging i4b variable list
  daliaVariableList: DeviceVariable[];
}

export const adapter: EntityAdapter<DeviceVariable> = createEntityAdapter<DeviceVariable>({
  selectId: (entity: DeviceVariable) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  daliaVariableList: [],
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const deviceVariablesFeature = createFeature({
  name: 'deviceVariables',
  reducer: createReducer(
    initialState,

    on(
      DeviceVariablesActions.setCurrentVariable,
      (state: State, { variable: entity }): State => ({
        ...state,
        entity
      })
    ),

    on(
      DeviceVariablesActions.loadDeviceVariables,
      (state: State): State => ({
        ...state,
        entity: null
      })
    ),
    on(
      DeviceVariablesActions.loadDeviceVariablesSuccess,
      (state: State, { response }): State =>
        adapter.setAll(response, {
          ...state
        })
    ),

    on(
      DeviceVariablesActions.setDaliaVariableList,
      (state: State, { variables }): State => ({
        ...state,
        daliaVariableList: [...variables]
      })
    ),

    on(
      DeviceVariablesActions.loadDeviceVariables,
      (state: State): State => ({
        ...state,
        loading: true,
        loaded: false
      })
    ),
    on(
      DeviceVariablesActions.loadDeviceVariablesSuccess,
      (state: State): State => ({
        ...state,
        loading: false,
        loaded: true
      })
    ),
    on(
      DeviceVariablesActions.loadDeviceVariablesFailure,
      (state: State, { error }): State => ({
        ...state,
        loading: false,
        loaded: false,
        error
      })
    )
  )
});
