<iot-platform-ui-simple-card
  (dispatchEvent)="onDispatchEvent($event)"
  [actions]="actions()"
  [cssClasses]="cssClasses()"
  [element]="element()"
  [icon]="icon()"
  [withMenu]="withMenu()"
  class="card iot-platform-ui-display-value-card"
  data-cy="iot-platform-ui-display-value-card"
>
  <div cardContent fxFlex>
    <div class="iot-platform-ui-display-value-card__content" fxLayout="row" fxLayoutAlign="start center">
      <div class="iot-platform-ui-display-value-card__left-side" fxFlex="50%">
        <div class="iot-platform-ui-display-value-card__description">
          {{ description() | translate | infoDisplay }}
        </div>
      </div>
      <div class="iot-platform-ui-display-value-card__right-side" fxFlex="50%" fxLayout="column" fxLayoutAlign="center flex-end">
        <div (click)="onDispatchEvent($event); onValueClicked?.()" class="iot-platform-ui-display-value-card__value">
          {{ value() | translate | infoDisplay }}
        </div>
        @if (unit() !== null && unit() !== undefined) {
          <div class="iot-platform-ui-display-value-card__unit">
            {{ unit() | translate | infoDisplay }}
          </div>
        }
      </div>
    </div>
  </div>
</iot-platform-ui-simple-card>
