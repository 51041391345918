import { NgModule } from '@angular/core';
import { BaseCardDetailsWidgetComponent } from './base-card-details-widget/base-card-details-widget.component';
import { CardDetailsMetadataWidgetComponent } from './card-details-metadata-widget/card-details-metadata-widget.component';
import { CardDetailsWidgetComponent } from './card-details-widget/card-details-widget.component';

@NgModule({
  imports: [CardDetailsWidgetComponent, BaseCardDetailsWidgetComponent, CardDetailsMetadataWidgetComponent],
  exports: [BaseCardDetailsWidgetComponent, CardDetailsWidgetComponent, CardDetailsMetadataWidgetComponent]
})
export class CardDetailsWidgetModule {}
