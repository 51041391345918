export enum Concept {
  SITE = 'SITE',
  ASSET = 'ASSET',
  DEVICE = 'DEVICE',
  TAG = 'TAG',
  MAINTENANCE = 'MAINTENANCE',
  PRODUCT = 'PRODUCT',
  ORGANIZATION_TREE = 'ORGANIZATION_TREE',
  BUSINESS_PROFILE = 'BUSINESS_PROFILE',
  USER = 'USER',
  SITE_PRODUCT = 'SITE_PRODUCT',
  ORDER = 'ORDER',
  EVENT = 'EVENT',
  ROLE = 'ROLE'
}
