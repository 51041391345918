<!--
  Using deferrable view as workaround to fix the following Error:
  NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
-->
@defer {
  <div [ngClass]="{
        'iot-platform-ui-infos-display-card--active': active(),
        'iot-platform-ui-infos-display-card--has-type': !!type(),
        'iot-platform-ui-infos-display-card--with-separator': !!separator(),
      }"
       class="iot-platform-ui-infos-display-card {{cssClassName()}} iot-platform-ui-infos-display-card--{{type()}}"
       data-cy="iot-platform-ui-infos-display-card"
       fxLayout="row"
       fxLayout.lt-sm="column"
       fxLayoutAlign="start center"
       fxLayoutAlign.lt-sm="center center"
       fxLayoutGap="16px">

    @if (label?.template) {
      <div class="iot-platform-ui-infos-display-card__label" fxFlex.lt-sm="100%">
        <ng-container *ngTemplateOutlet="label.template" />
      </div>
    }

    <div class="iot-platform-ui-infos-display-card__content" fxFlex fxLayout="row wrap"
         fxLayoutAlign="start center" fxLayoutAlign.lt-sm="center center"
         fxLayoutGap="10px">

      @for (item of properties; track item) {
        <ng-container *ngTemplateOutlet="item.template" />
      }
    </div>

  </div>
}
