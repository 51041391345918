import { BaseState, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { DeviceModbusRequestTable } from '@iot-platform/models/dalia';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DeviceModbusRequestTablesActions } from '../actions';

export interface State extends BaseState<DeviceModbusRequestTable, Pagination, Filter> {
  currentDeviceModbusRequestTableIndex: number | null;
}

export const adapter: EntityAdapter<DeviceModbusRequestTable> = createEntityAdapter<DeviceModbusRequestTable>({
  selectId: (entity: DeviceModbusRequestTable) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  currentDeviceModbusRequestTableIndex: null,
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const deviceModbusRequestTableFeature = createFeature({
  name: 'deviceModbusRequestTable',
  reducer: createReducer(
    initialState,

    on(
      DeviceModbusRequestTablesActions.setCurrentDeviceModbusRequestTable,
      (state: State, { deviceModbusRequestTable: entity }): State => ({
        ...state,
        entity
      })
    ),
    on(
      DeviceModbusRequestTablesActions.setCurrentDeviceModbusRequestTableIndex,
      (state: State, { index: currentDeviceModbusRequestTableIndex }): State => ({
        ...state,
        currentDeviceModbusRequestTableIndex
      })
    ),
    on(
      DeviceModbusRequestTablesActions.setDaliaDeviceModbusRequestTables,
      (state: State, { deviceModbusRequestTables }): State =>
        adapter.setAll(deviceModbusRequestTables, {
          ...state
        })
    )
  )
});
