import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  DestroyRef,
  effect,
  EnvironmentInjector,
  inject,
  Injector,
  Input,
  OnInit,
  runInInjectionContext,
  Signal,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormControl, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RestrictInputDirective } from '@iot-platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FormControlType } from '../form-control-type.model';
import { FormField } from '../form-field.model';

@Component({
  selector: 'iot-platform-ui-form-field',
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    RestrictInputDirective,
    TranslateModule,
    NgTemplateOutlet,
    MatTooltipModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.scss'
})
export class FormFieldComponent implements OnInit {
  @Input() control!: UntypedFormControl;
  @Input() field!: Partial<FormField>;
  FormControlType = FormControlType;
  trackByFn: (index: number, item: unknown) => unknown;
  displayByFn: (item: unknown) => string;
  autoCompleteSearchControl = new FormControl<string>('');
  defaultDebounceTime = 300;
  autocompleteFilteredOptions: WritableSignal<any[]> = signal([]);
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  protected readonly injector: Injector = inject(Injector);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly environmentInjector: EnvironmentInjector = inject(EnvironmentInjector);

  constructor() {
    this.trackByFn = this.trackBy.bind(this);
    this.displayByFn = this.autoCompleteDisplayWrapper.bind(this);
  }

  ngOnInit(): void {
    runInInjectionContext(this.environmentInjector, () => {
      if (this.control) {
        const controlValueChange: Signal<any> = toSignal(
          this.control?.valueChanges?.pipe(
            debounceTime(this.field?.debounceTime?.() ?? this.defaultDebounceTime),
            tap((value) => this.field?.valueChange?.(value)),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
          )
        );
        effect(
          () => {
            const value = controlValueChange();
            this.autoCompleteSearchControl.setValue(value);
          },
          { injector: this.injector, allowSignalWrites: true }
        );
      }
      const autoCompleteSearchValueChange: Signal<any> = toSignal(
        this.autoCompleteSearchControl?.valueChanges?.pipe(
          debounceTime(this.field?.debounceTime?.() ?? this.defaultDebounceTime),
          tap((value) => this.field?.valueChange?.(value)),
          distinctUntilChanged(),
          takeUntilDestroyed(this.destroyRef)
        )
      );
      effect(
        () => {
          const value = autoCompleteSearchValueChange();
          this.filterAutocomplete(value);
        },
        { injector: this.injector, allowSignalWrites: true }
      );
    });

    effect(
      () => {
        const disabled = this.field?.disabled;
        if (disabled) {
          if (disabled()) {
            this.control?.disable?.();
          } else {
            this.control?.enable?.();
          }
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );

    effect(
      () => {
        const required = this.field?.required;
        if (required) {
          if (required()) {
            this.control?.addValidators?.(Validators.required);
          } else {
            this.control?.removeValidators?.(Validators.required);
          }
          this.control?.updateValueAndValidity?.();
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  trackBy = (_: number, item: unknown) => this.field?.trackBy?.(item);

  onUndoBtnClicked(): void {
    this.control.setValue(this.field.undo?.initialValue?.());
    this.field?.undo?.onClick?.(this.field);
  }

  filterAutocomplete(searchTerm: unknown): void {
    const value = searchTerm !== null && searchTerm !== undefined ? searchTerm : '';
    const values = (this.field?.items?.() as any[])?.filter((item: any) => {
      const searchStr = typeof value === 'string' ? value.toLowerCase() : JSON.stringify(value).toLowerCase();
      return JSON.stringify(item)?.toLowerCase().indexOf(searchStr) !== -1;
    });
    this.autocompleteFilteredOptions.set(values);
  }

  clearAutocomplete($event: any): void {
    $event.stopPropagation();
    this.autoCompleteSearchControl.reset();
    this.control.reset();
    this.inputAutoComplete?.nativeElement?.focus();
  }

  private autoCompleteDisplayWrapper(item: unknown) {
    return this.field?.displayBy?.(item) as string;
  }
}
