import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceModbusRequestTable } from '@iot-platform/models/dalia';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import * as fromDeviceModbusRequestTable from '../reducers/device-modbus-request-table.reducer';

@Injectable({
  providedIn: 'root'
})
export class DeviceModbusRequestTableSelectors extends ApiFeatureSelector<fromDeviceModbusRequestTable.State, DeviceModbusRequestTable, Pagination, Filter> {
  selectCurrentDeviceModbusRequestTableIndex: MemoizedSelector<fromDeviceModbusRequestTable.State, number> = createSelector(
    this.selectState,
    (state: fromDeviceModbusRequestTable.State): number => state.currentDeviceModbusRequestTableIndex
  );

  constructor() {
    super(fromDeviceModbusRequestTable.deviceModbusRequestTableFeature.selectDeviceModbusRequestTableState, fromDeviceModbusRequestTable.adapter);
  }
}
