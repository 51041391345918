import { ThemePalette } from '@angular/material/core';
import { IotToolbarEvent } from '@iot-platform/models/common';

export interface IotToolbarMenuButtonOption {
  label: string;
  tooltip: string;
  icon: string;
  disableOption: boolean;
  dispatchAction: IotToolbarEvent;
  order: number;
  divider?: boolean;
}

export interface IotToolbarButtonConfig {
  key: string;
  icon: string;
  svgIcon: string;
  tooltip: string;
  dispatchAction: IotToolbarEvent;
  displayButton: boolean;
  color: ThemePalette;
  type: string;
  disabled: boolean;
  isToggleOn: boolean;
  order: number;
  menuOptions: Partial<IotToolbarMenuButtonOption>[];
  toggleOnClass: string;
  toggleOffClass: string;
  cssClassName: string;
  removable: boolean;
  actionLabel: string | null;
  actionSubLabel: string | null;
  displayNestedAction: boolean;
  nestedActionLabel: string | null;
  customTemplate: boolean;
}
