<div class="timeline-list" data-cy="iot-platform-ui-timeline-list" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="timeline-list__title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    @if (icon()) {
      <mat-icon [svgIcon]="icon()"></mat-icon>
    }
    @if (title()) {
      <span>{{ title() | translate }}</span>
    }
  </div>
  <ng-content></ng-content>
</div>
