import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ListViewEvent, OverviewCard, OverviewCardActions } from '@iot-platform/models/common';
import { CardLoaderModule } from '../../card-loader/card-loader.module';
import { OverviewCardComponent } from '../../overview-card/overview-card.component';

@Component({
  selector: 'iot-platform-ui-list-view-content',
  standalone: true,
  imports: [FlexLayoutModule, OverviewCardComponent, CardLoaderModule],
  templateUrl: './list-view-content.component.html'
})
export class ListViewContentComponent {
  loading = input<boolean>(false);
  card = input.required<OverviewCard>();
  dispatchEvent = output<ListViewEvent>();

  onDispatchCardEvent(action: OverviewCardActions): void {
    this.dispatchEvent.emit({ type: action });
  }
}
