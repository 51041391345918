import { Signal, signal, WritableSignal } from '@angular/core';
import { get } from 'lodash';

export interface SectionHeaderOptions {
  svgIcon: WritableSignal<string> | Signal<string>;
  cssClassName: WritableSignal<string> | Signal<string>;
  styles: WritableSignal<any> | Signal<any>;
  icon: WritableSignal<string> | Signal<string>;
  text: WritableSignal<string> | Signal<string>;
  tooltip: WritableSignal<string> | Signal<string>;
  visible: WritableSignal<boolean> | Signal<boolean>;
  disabled: WritableSignal<boolean> | Signal<boolean>;
}

export class SectionHeaderAction {
  action: string;
  svgIcon: WritableSignal<string | null> | Signal<string | null>;
  cssClassName: WritableSignal<string | null> | Signal<string | null>;
  styles: WritableSignal<any> | Signal<any>;
  icon: WritableSignal<string | null> | Signal<string | null>;
  text: WritableSignal<string | null> | Signal<string | null>;
  tooltip: WritableSignal<string | null> | Signal<string | null>;
  visible: WritableSignal<boolean> | Signal<boolean>;
  disabled: WritableSignal<boolean> | Signal<boolean>;

  constructor(options: { action: string } & Partial<SectionHeaderOptions>) {
    this.action = options.action;
    this.icon = get(options, 'icon', signal(null));
    this.svgIcon = get(options, 'svgIcon', signal(null));
    this.text = get(options, 'text', signal(null));
    this.tooltip = get(options, 'tooltip', signal(null));
    this.visible = get(options, 'visible', signal(true));
    this.disabled = get(options, 'disabled', signal(false));
    this.cssClassName = get(options, 'cssClassName', signal(null));
    this.styles = get(options, 'styles', signal(null));
  }
}
