import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'iot-platform-users-user-profile-api-keys-info-popup',
  templateUrl: './user-profile-api-keys-info-popup.component.html',
  styleUrls: ['./user-profile-api-keys-info-popup.component.scss']
})
export class UserProfileApiKeysInfoPopupComponent {
  constructor(
    public clipboard: Clipboard,
    public dialogRef: MatDialogRef<UserProfileApiKeysInfoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { apiKeyConsumerKey: string }
  ) {}

  copy(): void {
    this.clipboard.copy(this.data.apiKeyConsumerKey);
  }

  exit() {
    this.dialogRef.close();
  }
}
