import { LicenseManager as AgGridLicenseManager } from '@ag-grid-enterprise/core';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import {
  ApplicationConfig,
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  importProvidersFrom,
  makeEnvironmentProviders,
  provideZoneChangeDetection
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { provideAuthModule } from '@iot-platform/auth';
import {
  ApiInterceptorModule,
  CustomRouterSerializer,
  LocalStorageModule,
  provideCoreTrackingProviders,
  provideDefaultProvidersToken
} from '@iot-platform/core';
import { provideDaliaAuditTrailSettings } from '@iot-platform/dalia/data-access/devices';
import { FavoriteFilterEngineService, trackingSettings, TrackingSettingsProviderService } from '@iot-platform/dalia/util';
import { I18nModule } from '@iot-platform/i18n';
import { FilterEngineModule } from '@iot-platform/iot-platform-ui';
import { AppName } from '@iot-platform/models/common';
import { FavoriteViewsModule } from '@iot-platform/shared/components';
import { PreferencesModule } from '@iot-platform/users';
import { EffectsModule } from '@ngrx/effects';
import { DEFAULT_ROUTER_FEATURENAME, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, provideStore, StoreModule } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { Amplify } from 'aws-amplify';
import { environment } from '../environments/environment';
import { APP_ROUTES } from './app.routes';

function debugState(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return function (state, action) {
    console.log(action, state);
    return reducer(state, action);
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const metaReducers: MetaReducer<unknown>[] = environment.production ? [] : [debugState];

const storeSettings = {
  // metaReducers,
  runtimeChecks: {
    strictStateImmutability: false,
    strictActionImmutability: false,
    strictStateSerializability: false,
    strictActionSerializability: false,
    strictActionTypeUniqueness: false
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideDefaultProvidersToken({ environment }),
    provideRouter(APP_ROUTES, withEnabledBlockingInitialNavigation()),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([])),
    provideApplicationInitializer(),
    provideStore(
      {
        [DEFAULT_ROUTER_FEATURENAME]: routerReducer
      },
      storeSettings
    ),
    provideRouterStore({ serializer: CustomRouterSerializer }),
    provideStoreDevtools({ maxAge: 30, logOnly: environment.production }),
    provideAuthModule({
      ssoDisabled: false,
      appName: AppName.DALIA
    }),
    importProvidersFrom(
      StoreModule.forRoot({}, storeSettings),
      EffectsModule.forRoot([]),
      I18nModule.forRoot({ langs: ['fr', 'en', 'de'] }),
      I18nModule.getInstance(),
      LocalStorageModule.forRoot({ prefix: environment.storage.appPrefix }),
      FavoriteViewsModule,
      PreferencesModule,
      ApiInterceptorModule.forRoot({
        excludedApis: ['/admin-profiles', '/v2undefined'],
        replaceApis: [
          {
            endpoint: `${environment.api.url}/dalia-devices`,
            replaceBy: `${environment.api.url}${environment.api.endpoints.devices}`
          },
          {
            endpoint: `${environment.api.url}/dalia-firmwares`,
            replaceBy: `${environment.api.url}${environment.api.endpoints.firmwares}`
          },
          {
            endpoint: `${environment.api.url}/dalia-sensors`,
            replaceBy: `${environment.api.url}${environment.api.endpoints.sensors}`
          },
          {
            endpoint: `${environment.api.url}/dalia-device-templates`,
            replaceBy: `${environment.api.url}${environment.api.endpoints.deviceTemplates}`
          }
        ]
      }),
      FilterEngineModule.withSettings({
        favoriteFilterEngineService: FavoriteFilterEngineService
      })
    ),
    provideCoreTrackingProviders(trackingSettings, TrackingSettingsProviderService),
    provideDaliaAuditTrailSettings()
  ]
};

function provideApplicationInitializer(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => {
        // eslint-disable-next-line import/no-deprecated
        Amplify.configure({
          Auth: {
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            region: environment.cognito.region,
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: environment.cognito.userPoolId,
            userPoolWebClientId: environment.cognito.clientId
            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            // authenticationFlowType: 'USER_PASSWORD_AUTH'
          }
        });
        AgGridLicenseManager.setLicenseKey(environment.apiKeys.agGrid);
      }
    }
  ]);
}
