import { EnergyModes, ModbusTable, ModemRadioBands } from '@iot-platform/models/dalia';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DirectoryActions = createActionGroup({
  source: 'Directory',
  events: {
    'Load Modbus Table': emptyProps(),
    'Load Modbus Table Success': props<{
      response: ModbusTable;
    }>(),
    'Load Modbus Table Failure': props<{
      error: unknown;
    }>(),

    'Load Modem Radio Bands': emptyProps(),
    'Load Modem Radio Bands Success': props<{
      response: ModemRadioBands;
    }>(),
    'Load Modem Radio Bands Failure': props<{
      error: unknown;
    }>(),

    'Load Energy modes': emptyProps(),
    'Load Energy modes Success': props<{
      response: EnergyModes;
    }>(),
    'Load Energy modes Failure': props<{
      error: unknown;
    }>()
  }
});
