export interface DateRangeElement {
  label: string;
  value: string;
  date: Date;
  hours: number;
  minutes: number;
  seconds: number;
  timestamp: number;
}

export interface DateRange {
  startDate: DateRangeElement;
  endDate: DateRangeElement;
}
