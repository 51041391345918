import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, NgClass],
  selector: 'iot-platform-ui-cards-container',
  templateUrl: './cards-container.component.html',
  styleUrls: ['./cards-container.component.scss']
})
export class CardsContainerComponent {
  horizontalLayout = input<boolean>(false);
  virtualScroll = input<boolean>(false);
}
