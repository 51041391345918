import { EnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { DeviceConfigurationEffects } from '../effects/device-configuration.effects';
import { DeviceModbusRequestTablesEffects } from '../effects/device-modbus-request-table.effects';
import { DeviceTanksEffects } from '../effects/device-tanks.effects';
import { DeviceTemplatesEffects } from '../effects/device-templates.effects';
import { DeviceVariablesEffects } from '../effects/device-variables.effects';
import { DevicesEffects } from '../effects/devices.effects';
import { deviceModbusRequestTableFeature } from './device-modbus-request-table.reducer';
import { deviceTanksFeature } from './device-tanks.reducer';
import { deviceTemplatesFeature } from './device-templates.reducer';
import { deviceVariablesFeature } from './device-variables.reducer';
import { devicesFeature } from './devices.reducer';

export function provideDevicesState(): EnvironmentProviders[] {
  return [provideState(devicesFeature.name, devicesFeature.reducer), provideEffects(DevicesEffects)];
}

export function provideDeviceVariablesState(): EnvironmentProviders[] {
  return [provideState(deviceVariablesFeature.name, deviceVariablesFeature.reducer), provideEffects(DeviceVariablesEffects)];
}

export function provideDeviceTemplatesState(): EnvironmentProviders[] {
  return [provideState(deviceTemplatesFeature.name, deviceTemplatesFeature.reducer), provideEffects(DeviceTemplatesEffects)];
}

export function provideDeviceTanksState(): EnvironmentProviders[] {
  return [provideState(deviceTanksFeature.name, deviceTanksFeature.reducer), provideEffects(DeviceTanksEffects)];
}

export function provideDeviceModbusRequestTableState(): EnvironmentProviders[] {
  return [provideState(deviceModbusRequestTableFeature.name, deviceModbusRequestTableFeature.reducer), provideEffects(DeviceModbusRequestTablesEffects)];
}

export function provideDeviceConfigurationState(): EnvironmentProviders[] {
  return [provideEffects(DeviceConfigurationEffects)];
}
