import { inject } from '@angular/core';
import { DirectoryFacade } from '@iot-platform/dalia/data-access';
import { DeviceModbusRequestTableHelpers } from '@iot-platform/dalia/util/devices';
import { DeviceModbusRequestTable } from '@iot-platform/models/dalia';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { map } from 'rxjs/operators';
import { DeviceModbusRequestTablesActions, DeviceTemplatesActions } from '../actions';
import { DevicesActions } from '../actions/devices.actions';
import { DeviceTemplatesFacade } from '../facades/device-templates.facade';
import { DevicesFacade } from '../facades/devices.facade';

const getDeviceModbusRequestTables = (deviceModbusRequestTables: DeviceModbusRequestTable[], deactivateActions: boolean): DeviceModbusRequestTable[] =>
  deviceModbusRequestTables.map((mrt: DeviceModbusRequestTable) => {
    if (deactivateActions) {
      return {
        ...mrt,
        canConfigure: false
      };
    }
    return mrt;
  });

const loadDeviceByIdSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), directoryFacade = inject(DirectoryFacade), devicesFacade = inject(DevicesFacade)) =>
    actions$.pipe(
      ofType(DevicesActions.loadDeviceByIdSuccess),
      concatLatestFrom(() => directoryFacade.modbusTable$),
      map(([{ response }, modbusTable]) => DeviceModbusRequestTableHelpers.getDeviceModbusRequestTables(response, modbusTable)),
      map((deviceModbusRequestTables: DeviceModbusRequestTable[]) =>
        getDeviceModbusRequestTables(deviceModbusRequestTables, devicesFacade.deactivateActions())
      ),
      map((deviceModbusRequestTables: DeviceModbusRequestTable[]) =>
        DeviceModbusRequestTablesActions.setDaliaDeviceModbusRequestTables({ deviceModbusRequestTables })
      )
    ),
  { functional: true }
);

const loadTemplateByIdSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), directoryFacade = inject(DirectoryFacade), deviceTemplatesFacade = inject(DeviceTemplatesFacade)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.loadTemplateByIdSuccess),
      concatLatestFrom(() => directoryFacade.modbusTable$),
      map(([_, modbusTable]) => ({
        device: deviceTemplatesFacade.currentDevice(),
        modbusTable
      })),
      map(({ device, modbusTable }) => DeviceModbusRequestTableHelpers.getDeviceModbusRequestTables(device, modbusTable)),
      map((deviceModbusRequestTables: DeviceModbusRequestTable[]) =>
        getDeviceModbusRequestTables(deviceModbusRequestTables, deviceTemplatesFacade.deactivateActions())
      ),
      map((deviceModbusRequestTables: DeviceModbusRequestTable[]) =>
        DeviceModbusRequestTablesActions.setDaliaDeviceModbusRequestTables({ deviceModbusRequestTables })
      )
    ),
  { functional: true }
);

export const DeviceModbusRequestTablesEffects = {
  loadDeviceByIdSuccess$,
  loadTemplateByIdSuccess$
};
