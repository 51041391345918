import { inject, Injectable } from '@angular/core';
import { DeviceDetails, DeviceTemplate } from '@iot-platform/models/dalia';
import { Store } from '@ngrx/store';
import { DeviceConfigurationActions } from '../actions/device-configuration.actions';

@Injectable({
  providedIn: 'root'
})
export class DeviceConfigurationFacade {
  protected store: Store = inject(Store);

  publishConfiguration(device: DeviceDetails): void {
    this.store.dispatch(DeviceConfigurationActions.publishConfiguration({ device }));
  }

  cancelTargetConfiguration(device: DeviceDetails): void {
    this.store.dispatch(DeviceConfigurationActions.cancelTargetConfiguration({ device }));
  }

  updateConfiguration(device: DeviceDetails, configuration: { [key: string]: unknown }): void {
    this.store.dispatch(DeviceConfigurationActions.updateConfiguration({ device, configuration }));
  }

  patchConfiguration(device: DeviceDetails, configuration: { [key: string]: unknown }): void {
    this.store.dispatch(DeviceConfigurationActions.patchConfiguration({ device, configuration }));
  }

  applyTemplate(device: DeviceDetails, deviceTemplate: DeviceTemplate): void {
    this.store.dispatch(DeviceConfigurationActions.applyTemplate({ device, deviceTemplate }));
  }

  copyConfiguration(source: DeviceDetails, target: DeviceDetails): void {
    this.store.dispatch(DeviceConfigurationActions.copyConfiguration({ source, target }));
  }
}
