import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { DeviceDetails, DeviceTemplate } from '@iot-platform/models/dalia';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceConfigurationService {
  protected readonly environment: Environment = inject(ENVIRONMENT);
  protected readonly http: HttpClient = inject(HttpClient);

  publishConfiguration(device: DeviceDetails) {
    return this.http.post<DeviceDetails>(
      `${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${device.identifier}/configuration/pending/publish`,
      {}
    );
  }

  cancelTargetConfiguration(device: DeviceDetails) {
    return this.http.post<DeviceDetails>(
      `${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${device.identifier}/configuration/target/cancel`,
      {}
    );
  }

  updateConfiguration(device: DeviceDetails, config: { [key: string]: unknown }): Observable<DeviceDetails> {
    return this.http.put<DeviceDetails>(
      `${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${device.identifier}/configuration/pending`,
      config
    );
  }

  patchConfiguration(device: DeviceDetails, config: { [key: string]: unknown }): Observable<DeviceDetails> {
    return this.http.patch<DeviceDetails>(
      `${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${device.identifier}/configuration/pending`,
      config
    );
  }

  applyTemplate(device: DeviceDetails, deviceTemplate: DeviceTemplate): Observable<DeviceDetails> {
    return this.http.put<DeviceDetails>(
      `${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${device.identifier}/configuration/apply-template/${deviceTemplate.id}`,
      deviceTemplate.config
    );
  }

  copyConfiguration(source: DeviceDetails, target: DeviceDetails): Observable<DeviceDetails> {
    return this.http.put<DeviceDetails>(
      `${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${target.identifier}/configuration/copy-from/${source.identifier}`,
      {}
    );
  }
}
