import { DeviceTank } from '@iot-platform/models/dalia';
import { createActionGroup, props } from '@ngrx/store';

export const DeviceTanksActions = createActionGroup({
  source: 'Device Tanks',
  events: {
    'Set Dalia Tanks List': props<{
      tanks: DeviceTank[];
    }>(),
    'Set Current Tank': props<{
      tank: DeviceTank;
    }>(),
    'Set Current Tank Index': props<{
      index: number;
    }>()
  }
});
