import { DatePipe, NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslateModule, FlexLayoutModule, NgClass, MatIcon, DatePipe],
  providers: [DatePipe],
  selector: 'iot-platform-ui-timeline-list-item',
  templateUrl: './timeline-list-item.component.html',
  styleUrls: ['./timeline-list-item.component.scss']
})
export class TimelineListItemComponent {
  icon = input<string>();
  timestamp = input<string>();
  label = input<string>();
  description = input<string>();
  cssClassName = input<string>();
}
