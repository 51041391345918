import { Component, input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-infos-display-card-label',
  standalone: true,
  template: `
    <ng-template>
      @if (label()) {
        <span data-cy="iot-platform-ui-infos-display-card-label" class="iot-platform-ui-infos-display-card-label">{{ label() }}</span>
      }
      <ng-content />
    </ng-template>
  `,
  styleUrl: './infos-display-card-label.component.scss'
})
export class InfosDisplayCardLabelComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild(TemplateRef) public template!: TemplateRef<any>;
  label = input<string>();
}
