import { BaseState, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { DeviceTank } from '@iot-platform/models/dalia';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DeviceTanksActions } from '../actions';

export interface State extends BaseState<DeviceTank, Pagination, Filter> {
  currentTankIndex: number | null;
}

export const adapter: EntityAdapter<DeviceTank> = createEntityAdapter<DeviceTank>({
  selectId: (entity: DeviceTank) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  currentTankIndex: null,
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const deviceTanksFeature = createFeature({
  name: 'deviceTanks',
  reducer: createReducer(
    initialState,

    on(
      DeviceTanksActions.setCurrentTank,
      (state: State, { tank: entity }): State => ({
        ...state,
        entity
      })
    ),
    on(
      DeviceTanksActions.setCurrentTankIndex,
      (state: State, { index: currentTankIndex }): State => ({
        ...state,
        currentTankIndex
      })
    ),
    on(
      DeviceTanksActions.setDaliaTanksList,
      (state: State, { tanks }): State =>
        adapter.setAll(tanks, {
          ...state
        })
    )
  )
});
