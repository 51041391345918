export interface GoogleAnalyticsTrackingData {
  event_category: string;
  event_label: string;
  value: unknown;
  non_interaction: boolean;
}

export interface HotjarAnalyticsTrackingData {
  event_name: string;
}

export interface TrackingData {
  [key: string]: unknown;
}

export type AnalyticsData = GoogleAnalyticsTrackingData | HotjarAnalyticsTrackingData;
