import { CommonGenericModel } from '../../../common/core';
import { ExportParams } from '../../export-params';
import { I4BColumnConfiguration } from '../configurations';
import { I4BColumn } from '../definitions';
import { I4BColumnOptions } from '../options';
import { I4BColumnHeader } from './i4b-column-header.model';
import { I4BGridData } from './i4b-grid-data.model';
import { I4BGridOptions } from './i4b-grid-options.model';

export interface I4BGrid<options extends I4BGridOptions, gridData extends I4BGridData> extends CommonGenericModel {
  id?: string;
  name: string | null;
  isDefault: boolean;
  isAppDefault: boolean;
  isUserDefault: boolean;
  masterview: string;
  userId: string | null;
  businessProfileId?: string;
  favoriteViewId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;

  data: gridData;
  gridOptions: options;
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[];
  export?: (params?: ExportParams) => void;
}
