import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface CustomRouterState {
  url: string;
  queryParams: Params;
  params: Params;
}

export class CustomRouterSerializer implements RouterStateSerializer<CustomRouterState> {
  serialize(routerState: RouterStateSnapshot): CustomRouterState {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    const { params } = this.extractAllParams(routerState.root);
    return {
      url,
      queryParams,
      params
    };
  }

  private extractAllParams(route: ActivatedRouteSnapshot): { params: Params } {
    let res = {};
    while (route.firstChild) {
      const { params } = route;
      res = { ...params, ...res };
      route = route.firstChild;
    }
    return {
      params: { ...res, ...route.params } as Params
    };
  }
}
