import { EnvironmentProviders } from '@angular/core';
import { provideAuditTrailSettings } from '@iot-platform/audit-trail';
import { DevicesAuditTrailService } from './devices-audit-trail.service';

export { DeviceTemplatesService } from './device-templates.service';
export { DeviceConfigurationService } from './device-configuration.service';
export { DevicesService } from './devices.service';
export { DeviceVariablesService } from './device-variables.service';

export const provideDaliaAuditTrailSettings = (): EnvironmentProviders =>
  provideAuditTrailSettings({
    auditTrailService: DevicesAuditTrailService
  });
