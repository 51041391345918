import { TrackingData } from './tracking-data.model';

export type TrackingEventId = string;

export interface GoogleAnalyticsTrackingTag {
  eventName: string;
  category: string;
  label: string;
}

export interface HotjarAnalyticsTrackingTag {
  pageName: string;
}

export type AnalyticsTag = GoogleAnalyticsTrackingTag | HotjarAnalyticsTrackingTag;

export interface TrackingTag {
  getGoogleTag: (data: TrackingData) => GoogleAnalyticsTrackingTag; // Get google tag manager
  getHotjarTag: (data: TrackingData) => HotjarAnalyticsTrackingTag; // Get hotjar tag manager
}
