import { inject } from '@angular/core';
import { EnergyModes, ModbusTable, ModemRadioBands } from '@iot-platform/models/dalia';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { DirectoryService } from '../../services/directory.service';
import { DirectoryActions } from '../actions/directory.actions';

const getModbusTable$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), directoryService = inject(DirectoryService)) =>
    actions$.pipe(
      ofType(DirectoryActions.loadModbusTable),
      switchMap(() => directoryService.getModbusTable()),
      map((response: ModbusTable) => DirectoryActions.loadModbusTableSuccess({ response }))
    ),
  { functional: true }
);

const getModemRadioBands$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), directoryService = inject(DirectoryService)) =>
    actions$.pipe(
      ofType(DirectoryActions.loadModemRadioBands),
      switchMap(() => directoryService.getModemRadioBands()),
      map((response: ModemRadioBands) => DirectoryActions.loadModemRadioBandsSuccess({ response }))
    ),
  { functional: true }
);

const getEnergyModes$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), directoryService = inject(DirectoryService)) =>
    actions$.pipe(
      ofType(DirectoryActions.loadEnergyModes),
      switchMap(() => directoryService.getEnergyModes()),
      map((response: EnergyModes) => DirectoryActions.loadEnergyModesSuccess({ response }))
    ),
  { functional: true }
);

export const DirectoryEffects = {
  getModbusTable$,
  getModemRadioBands$,
  getEnergyModes$
};
