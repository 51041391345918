import { Filter } from './filter.model';

export interface FavoriteView {
  id?: string;
  name?: string;
  pinned?: boolean;
  shared?: boolean;
  owner?: string;
  count?: number;
  businessProfileId?: string;
  concept?: string;
  masterView?: string;
  color?: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  filters?: Filter[];
  loading?: boolean;
  description?: string;
  gridId?: string;
}
