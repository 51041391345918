import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { DeviceVariable as DaliaDeviceVariable } from '@iot-platform/models/dalia';
import { DeviceVariable as I4bDeviceVariable, I4BBulkOperationApiResponse } from '@iot-platform/models/i4b';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceVariablesService {
  protected readonly environment: Environment = inject(ENVIRONMENT);
  protected readonly http: HttpClient = inject(HttpClient);

  getVariablesByDeviceId(id: string): Observable<I4bDeviceVariable[]> {
    return this.http
      .get<{
        content: I4bDeviceVariable[];
      }>(`${this.environment.api.url + this.environment.api.endpoints.devices}/${id}/variables?limit=3000&page=0`)
      .pipe(map((data) => data.content));
  }

  bulkDeleteDeviceVariables(variables: DaliaDeviceVariable[]): Observable<I4BBulkOperationApiResponse> {
    return this.http.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.bulkDeleteDeviceVariables}`, {
      variablesIds: variables.map((variable) => variable.id)
    });
  }
}
