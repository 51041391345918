import { Component, computed, input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'iot-platform-ui-timeline-entry',
  templateUrl: './timeline-entry.component.html',
  styleUrls: ['./timeline-entry.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]': 'hostCssClass()'
  }
})
export class TimelineEntryComponent {
  active = input<boolean>(false);

  hostCssClass = computed(() => {
    const active = this.active();
    return `timeline__entry ${active ? 'timeline__entry--active' : ''}`;
  });
}
