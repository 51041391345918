import { inject, Pipe, PipeTransform } from '@angular/core';
import { DateIntervalUtils } from '@iot-platform/iot-platform-utils';
import { Duration } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment/moment';

type DurationArgs = 'd' | 'h' | 'm' | 's';

@Pipe({
  name: 'duration',
  standalone: true
})
export class DurationPipe implements PipeTransform {
  protected readonly translateService: TranslateService = inject(TranslateService);

  transform(seconds: number, ...args: DurationArgs[]): string {
    const startDate: moment.Moment = moment().clone();
    const endDate: moment.Moment = startDate.clone().add(seconds, 'seconds');
    const duration: Duration = DateIntervalUtils.diffDates(startDate, endDate);
    return this.formatDuration(duration, args);
  }

  private formatDuration(duration: Duration, args: DurationArgs[]) {
    const alwaysText = this.translateService.instant('IOT_DICTIONARY.ALWAYS');
    if (!duration || duration.seconds < 0) {
      return alwaysText;
    }
    let days = '';
    let h = '';
    let m = '';
    let s = '';
    const hasArgs = args?.length > 0;
    const canAppend = (arg: DurationArgs) => (hasArgs && args.includes(arg)) || !hasArgs;

    if (canAppend('d') && duration.days > 0) {
      days = `${duration.days}${this.translateService.instant('IOT_DICTIONARY.TIME.DAY')}`;
    }
    if (canAppend('h') && duration.hours > 0) {
      h = ` ${duration.hours}${this.translateService.instant('IOT_DICTIONARY.TIME.HOUR')}`;
    }
    if (canAppend('m') && duration.minutes > 0) {
      m = ` ${duration.minutes}${this.translateService.instant('IOT_DICTIONARY.TIME.MINUTE')}`;
    }
    if (canAppend('s') && duration.seconds > 0) {
      s = ` ${duration.seconds}${this.translateService.instant('IOT_DICTIONARY.TIME.SECOND')}`;
    }
    if (days === '' && h === '' && m === '' && s === '') {
      return alwaysText;
    }
    return `${days}${h}${m}${s}`.trim();
  }
}
