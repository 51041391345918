<div class="timeline__dot" fxLayout="column" fxLayoutAlign="start center">
  <span class="timeline__dot_icon"
    ><mat-icon [ngStyle]="style()">{{ icon() }}</mat-icon></span
  >
  @if (label()) {
    <span class="timeline__dot_label">{{ label() }}</span>
  }
  <div class="timeline__dot_content">
    <ng-content></ng-content>
  </div>
</div>
