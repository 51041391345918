import { Component, input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'iot-platform-ui-timeline-header',
  templateUrl: './timeline-header.component.html',
  styleUrls: ['./timeline-header.component.scss']
})
export class TimelineHeaderComponent {
  label = input<string>();
}
