import { Entity } from '@iot-platform/models/common';
import { DeviceConfiguration, DeviceTemplate } from '@iot-platform/models/dalia';
import { get } from 'lodash';
import { DeviceTankHelpers } from './device-tank.helpers';
import { DeviceVariableHelpers } from './device-variable.helpers';

export class DeviceTemplateHelpers {
  public static getTemplateInstance(template: DeviceTemplate, sessionEntity: Entity): DeviceTemplate {
    const configuration = { current: get(template, ['config'], {}) } as DeviceConfiguration;
    const variableNames = DeviceVariableHelpers.getAllVariableNames(configuration, false);
    const tankIndexes = DeviceTankHelpers.getAllTankIndexes(configuration, false);
    return {
      ...template,
      totalVariables: variableNames.length,
      totalTanks: tankIndexes.length,
      isEditable: DeviceTemplateHelpers.isEditable(template, sessionEntity)
    };
  }

  public static isEditable(template: DeviceTemplate, sessionEntity: Entity): boolean {
    const entityId = get(sessionEntity, 'id');
    const found = get(template, 'entityPath', []).find((entity: Entity) => entity.id === entityId);
    return get(template, ['entity', 'id']) === entityId || !!found;
  }

  public static toCurrentConfiguration(config: { [key: string]: unknown }): { [key: string]: { v: unknown } } {
    return Object.entries(config).reduce(
      (acc, [key, v]) => ({
        ...acc,
        [key]: { v }
      }),
      {}
    );
  }
}
