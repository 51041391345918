import { inject } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { DeviceConfigurationService } from '../../services/device-configuration.service';
import { DeviceConfigurationActions, DevicesActions } from '../actions';

const publishConfiguration$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceConfigurationService = inject(DeviceConfigurationService)) =>
    actions$.pipe(
      ofType(DeviceConfigurationActions.publishConfiguration),
      switchMap(({ device }) =>
        deviceConfigurationService.publishConfiguration(device).pipe(
          map(() => DeviceConfigurationActions.publishConfigurationSuccess({ response: device })),
          catchError((error) => of(DeviceConfigurationActions.publishConfigurationFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const cancelTargetConfiguration$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceConfigurationService = inject(DeviceConfigurationService)) =>
    actions$.pipe(
      ofType(DeviceConfigurationActions.cancelTargetConfiguration),
      switchMap(({ device }) =>
        deviceConfigurationService.cancelTargetConfiguration(device).pipe(
          map(() => DeviceConfigurationActions.cancelTargetConfigurationSuccess({ response: device })),
          catchError((error) => of(DeviceConfigurationActions.cancelTargetConfigurationFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateConfiguration$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceConfigurationService = inject(DeviceConfigurationService)) =>
    actions$.pipe(
      ofType(DeviceConfigurationActions.updateConfiguration),
      concatMap(({ device, configuration }) =>
        deviceConfigurationService.updateConfiguration(device, configuration).pipe(
          map(() => DeviceConfigurationActions.updateConfigurationSuccess({ response: device })),
          catchError((error) => of(DeviceConfigurationActions.updateConfigurationFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const patchConfiguration$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceConfigurationService = inject(DeviceConfigurationService)) =>
    actions$.pipe(
      ofType(DeviceConfigurationActions.patchConfiguration),
      concatMap(({ device, configuration }) =>
        deviceConfigurationService.patchConfiguration(device, configuration).pipe(
          map(() => DeviceConfigurationActions.patchConfigurationSuccess({ response: device })),
          catchError((error) => of(DeviceConfigurationActions.patchConfigurationFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const applyTemplate$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceConfigurationService = inject(DeviceConfigurationService)) =>
    actions$.pipe(
      ofType(DeviceConfigurationActions.applyTemplate),
      concatMap(({ device, deviceTemplate }) =>
        deviceConfigurationService.applyTemplate(device, deviceTemplate).pipe(
          map(() => DeviceConfigurationActions.applyTemplateSuccess({ response: device })),
          catchError((error) => of(DeviceConfigurationActions.applyTemplateFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const copyConfiguration$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceConfigurationService = inject(DeviceConfigurationService)) =>
    actions$.pipe(
      ofType(DeviceConfigurationActions.copyConfiguration),
      concatMap(({ source, target }) =>
        deviceConfigurationService.copyConfiguration(source, target).pipe(
          map(() => DeviceConfigurationActions.copyConfigurationSuccess({ response: source })),
          catchError((error) => of(DeviceConfigurationActions.copyConfigurationFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const refreshDeviceDetails$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(
        DeviceConfigurationActions.publishConfigurationSuccess,
        DeviceConfigurationActions.cancelTargetConfigurationSuccess,
        DeviceConfigurationActions.updateConfigurationSuccess,
        DeviceConfigurationActions.patchConfigurationSuccess,
        DeviceConfigurationActions.applyTemplateSuccess,
        DeviceConfigurationActions.copyConfigurationSuccess
      ),
      map(({ response: device }) => DevicesActions.loadDeviceById({ id: device.id }))
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceConfigurationActions.publishConfiguration,
        DeviceConfigurationActions.cancelTargetConfiguration,
        DeviceConfigurationActions.updateConfiguration,
        DeviceConfigurationActions.patchConfiguration,
        DeviceConfigurationActions.applyTemplate,
        DeviceConfigurationActions.copyConfiguration
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceConfigurationActions.publishConfigurationSuccess,
        DeviceConfigurationActions.publishConfigurationFailure,
        DeviceConfigurationActions.cancelTargetConfigurationSuccess,
        DeviceConfigurationActions.cancelTargetConfigurationFailure,
        DeviceConfigurationActions.updateConfigurationSuccess,
        DeviceConfigurationActions.updateConfigurationFailure,
        DeviceConfigurationActions.patchConfigurationSuccess,
        DeviceConfigurationActions.patchConfigurationFailure,
        DeviceConfigurationActions.applyTemplateSuccess,
        DeviceConfigurationActions.applyTemplateFailure,
        DeviceConfigurationActions.copyConfigurationSuccess,
        DeviceConfigurationActions.copyConfigurationFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceConfigurationActions.publishConfigurationFailure,
        DeviceConfigurationActions.cancelTargetConfigurationFailure,
        DeviceConfigurationActions.updateConfigurationFailure,
        DeviceConfigurationActions.patchConfigurationFailure,
        DeviceConfigurationActions.applyTemplateFailure,
        DeviceConfigurationActions.copyConfigurationFailure
      ),
      tap((action: Action) => notificationService.displayError(action.type))
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceConfigurationActions.publishConfigurationSuccess,
        DeviceConfigurationActions.cancelTargetConfigurationSuccess,
        DeviceConfigurationActions.updateConfigurationSuccess,
        DeviceConfigurationActions.patchConfigurationSuccess,
        DeviceConfigurationActions.applyTemplateSuccess,
        DeviceConfigurationActions.copyConfigurationSuccess
      ),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

export const DeviceConfigurationEffects = {
  publishConfiguration$,
  cancelTargetConfiguration$,
  refreshDeviceDetails$,
  updateConfiguration$,
  patchConfiguration$,
  showLoader$,
  hideLoader$,
  displayError$,
  displaySuccess$,
  applyTemplate$,
  copyConfiguration$
};
