import { inject, Injectable, Signal } from '@angular/core';
import { EnergyModes, ModbusTable, ModemRadioBands } from '@iot-platform/models/dalia';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DirectoryActions } from '../actions/directory.actions';
import { selectEnergyModes, selectModbusTable, selectModemRadioBands } from '../selectors/directory.selectors';

@Injectable({
  providedIn: 'root'
})
export class DirectoryFacade {
  protected readonly store: Store = inject(Store);

  modbusTable$: Observable<ModbusTable> = this.store.select(selectModbusTable);
  modbusTable: Signal<ModbusTable> = this.store.selectSignal(selectModbusTable);

  modemRadioBands$: Observable<ModemRadioBands> = this.store.select(selectModemRadioBands);
  modemRadioBands: Signal<ModemRadioBands> = this.store.selectSignal(selectModemRadioBands);

  energyModes$: Observable<EnergyModes> = this.store.select(selectEnergyModes);
  energyModes: Signal<EnergyModes> = this.store.selectSignal(selectEnergyModes);

  loadModbusTable(): void {
    this.store.dispatch(DirectoryActions.loadModbusTable());
  }

  loadEnergyModes(): void {
    this.store.dispatch(DirectoryActions.loadEnergyModes());
  }

  loadModemRadioBands(): void {
    this.store.dispatch(DirectoryActions.loadModemRadioBands());
  }

  loadAll(): void {
    this.loadModbusTable();
    this.loadEnergyModes();
    this.loadModemRadioBands();
  }
}
