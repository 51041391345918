<iot-platform-ui-base-card-details-widget
  [icon]="icon()"
  [loading]="loading()"
  [matIcon]="matIcon()"
  [svgIcon]="svgIcon()"
  [title]="title()"
  data-cy="iot-platform-ui-base-card-details-widget"
>
  @for (def of templateDefinition(); track $index) {
    <div data-cy="iot-platform-ui-base-card-details-widget-row-def" fxLayout="row">
      <span
        [fxFlex.lt-md]="def.fxFlex && def.fxFlex > 33 ? 33 : def.fxFlex || 50"
        [fxFlex]="def.fxFlex || 50"
        [matTooltip]="def.tooltip | translate"
        class="card-details-widget__label"
        data-cy="iot-platform-ui-base-card-details-widget-row-def-label"
        matTooltipClass="regular-tooltip"
        >{{ def.label | translate }}</span
      >

      @if (def?.isLink && !!hasParams(def)) {
        <span class="card-details-widget__value">
          <a data-cy="iot-platform-ui-base-card-details-widget-row-def-value" (click)="onDispatchEvent(TemplateDefDispatchEventType.OPEN_LINK, def, data())">
            <ng-container [ngTemplateOutlet]="valueTmpl" />
          </a>
        </span>
      } @else {
        <span data-cy="iot-platform-ui-base-card-details-widget-row-def-value" class="card-details-widget__value">
          <ng-container [ngTemplateOutlet]="valueTmpl" />
        </span>
      }

      <ng-template #valueTmpl>
        {{ def?.value$ | async | infoDisplay | translate }}
      </ng-template>
    </div>
  }
</iot-platform-ui-base-card-details-widget>
