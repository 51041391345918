import { DeviceHelpers } from '@iot-platform/dalia/util/devices';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, Duration, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { DeviceDetails } from '@iot-platform/models/dalia';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DevicesActions } from '../actions';

export interface State extends BaseState<DeviceDetails, Pagination, Filter> {
  lastCallDuration: Duration;
  lastCallStatusRefreshActivated: boolean;
}

export const adapter: EntityAdapter<DeviceDetails> = createEntityAdapter<DeviceDetails>({
  selectId: (entity: DeviceDetails) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  deviceDetails: null,
  lastCallDuration: null,
  lastCallStatusRefreshActivated: false,
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const devicesFeature = createFeature({
  name: 'devices',
  reducer: createReducer(
    initialState,

    on(
      DevicesActions.setFilters,
      (state: State, { filters }): State => ({
        ...state,
        filters: [...filters]
      })
    ),

    on(
      DevicesActions.setCurrentDevice,
      (state: State, { device }): State => ({
        ...state,
        entity: device,
        lastCallDuration: DeviceHelpers.getLastCallTime(device)
      })
    ),

    on(
      DevicesActions.setLastCallDuration,
      (state: State, { duration: lastCallDuration }): State => ({
        ...state,
        lastCallDuration
      })
    ),

    on(
      DevicesActions.setLastCallStatusRefreshActivated,
      (state: State, { value: lastCallStatusRefreshActivated }): State => ({
        ...state,
        lastCallStatusRefreshActivated
      })
    ),

    on(
      DevicesActions.loadDevices,
      (state: State, { request }): State => ({
        ...state,
        filters: GetUtils.get(request, 'filters', [])
      })
    ),
    on(
      DevicesActions.loadDevicesSuccess,
      (state: State, { response }): State =>
        adapter.setAll(GetUtils.get(response, 'data', []), {
          ...state,
          pagination: GetUtils.get(response, 'pagination')
        })
    ),

    on(
      DevicesActions.loadDeviceByIdSuccess,
      DevicesActions.updateDeviceSuccess,
      (state: State, { response }): State => ({
        ...state,
        entity: response
      })
    ),

    on(
      DevicesActions.loadDevices,
      DevicesActions.loadDeviceById,
      DevicesActions.updateDevice,
      DevicesActions.sendCommand,
      (state: State): State => ({
        ...state,
        loading: true,
        loaded: false
      })
    ),
    on(
      DevicesActions.loadDevicesSuccess,
      DevicesActions.loadDeviceByIdSuccess,
      DevicesActions.updateDeviceSuccess,
      DevicesActions.sendCommandSuccess,
      (state: State): State => ({
        ...state,
        loading: false,
        loaded: true
      })
    ),
    on(
      DevicesActions.loadDevicesFailure,
      DevicesActions.loadDeviceByIdFailure,
      DevicesActions.updateDeviceFailure,
      DevicesActions.sendCommandFailure,
      (state: State, { error }): State => ({
        ...state,
        loading: false,
        loaded: false,
        error
      })
    )
  )
});
