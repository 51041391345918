import { Component, input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'iot-platform-ui-timeline-content',
  templateUrl: './timeline-content.component.html',
  styleUrls: ['./timeline-content.component.scss']
})
export class TimelineContentComponent {
  visible = input<boolean>(false);
}
