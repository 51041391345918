<ng-template>
  <div
    [fxFlex]="'calc(' + fxFlex() + ' - 10px)'"
    [ngStyle.lt-sm]="{'border.px': 0}"
    class="iot-platform-ui-infos-display-card-property {{cssClassName()}}"
    data-cy="iot-platform-ui-infos-display-card-property"
    fxFlex.lt-sm="100%"
    fxLayout="row wrap"
    fxLayoutAlign="stretch center"
    fxLayoutAlign.lt-sm="center center"
    fxLayoutGap="6px"
  >
    @if (label()) {
      <div class="iot-platform-ui-infos-display-card-property__label"
           data-cy="iot-platform-ui-infos-display-card-property-label"
           fxFlexAlign="start center">{{
          label() | translate
        }}
      </div>
    }
    <div
      class="iot-platform-ui-infos-display-card-property__value fw-600 "
      data-cy="iot-platform-ui-infos-display-card-property-value"
      fxFlex
      fxFlexAlign="center center"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-sm="center center"
      fxLayoutGap="6px"
    >
      @if (value() !== null && value() !== undefined) {
        <span
          [matTooltip]="value()"
          matTooltipClass="regular-tooltip">{{ value() }}</span>
      }

      <ng-content />

      @if ((valueMetaInfo()?.visible !== undefined && valueMetaInfo()?.visible) || (valueMetaInfo()?.visible === undefined)) {
        <button
          fxFlexAlign="end center"
          class="light-button regular-round-button iot-platform-ui-infos-display-card-property__value_meta-info"
          data-cy="iot-platform-ui-infos-display-card-property-value-meta-info"
          [matTooltip]="valueMetaInfo()?.tooltip! | translate"
          mat-mini-fab
          matTooltipClass="regular-tooltip"
          matTooltipPosition="below"
        >
          @if (valueMetaInfo()?.icon) {
            <mat-icon>{{ valueMetaInfo()?.icon }}</mat-icon>
          } @else if (valueMetaInfo()?.svgIcon) {
            <mat-icon [svgIcon]="valueMetaInfo().svgIcon" />
          }
        </button>
      }
    </div>
  </div>
</ng-template>
