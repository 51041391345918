@defer {
  <div
    [fxLayout]="layout"
    [ngStyle]="style()"
    class="iot-platform-ui-tab-group iot-platform-ui-tab-group--{{ direction() }} iot-platform-ui-tab-group--{{ position() }}"
    data-cy="iot-platform-ui-tab-group"
    fxLayout.lt-md="column"
    fxLayoutGap="16px"
  >
    <div
      [fxFlex]="flexWidth"
      [ngStyle]="style()"
      class="iot-platform-ui-tab-group__header"
      fxFlex.lt-md="100%"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutAlign.lt-md="center center"
      fxLayoutGap="16px"
    >
      <ng-content select="[tabMetaImage]" />
      <ng-container *ngTemplateOutlet="tabGroupHeaderTmpl" />
    </div>
    <div class="iot-platform-ui-tab-group__container" data-cy="iot-platform-ui-tab-group-container" fxFlex>
      <ng-container *ngTemplateOutlet="tabGroupContentTmpl" />
    </div>
  </div>
}

<ng-template #tabGroupHeaderTmpl>
  <nav [tabPanel]="tabPanel" color="accent" mat-tab-nav-bar>
    @for (tab of tabs; track tab.id) {
      <a
        data-cy="iot-platform-ui-tab-group-link"
        class="iot-platform-ui-tab-group__link {{ tab.header?.cssClassName() }}"
        [ngClass]="{ 'iot-platform-ui-tab-group__link--disabled': tab.disabled() }"
        mat-tab-link
        (click)="selectTab(tab)"
        [active]="tab.active()"
        [disabled]="tab.disabled()"
        [matTooltip]="tab.header.tooltip()"
        matTooltipClass="regular-tooltip"
      >
        <span>
          <ng-container *ngTemplateOutlet="tab.header.headerTemplate" />
        </span>

        @if (withArrowIcon()) {
          <mat-icon>
            @if (position() === 'left') {
              chevron_right
            } @else if (position() === 'right') {
              chevron_left
            }
          </mat-icon>
        }
      </a>
    }
  </nav>
  <mat-tab-nav-panel #tabPanel />
</ng-template>

<ng-template #tabGroupContentTmpl>
  <div fxFlex>
    @for (tab of tabs; track tab.id) {
      @if (tab.active()) {
        <div data-cy="iot-platform-ui-tab-group-content" class="iot-platform-ui-tab-group__content {{ tab.content?.cssClassName() }}">
          <ng-container *ngTemplateOutlet="tab.content.contentTemplate" />
        </div>
      }
    }
  </div>
</ng-template>
