export class ListViewItem {
  id: string;
  label: string;
  description: string;
  status: string;
  cssClassName: string;
  imageUrl?: string;
  svgIcon?: string;
  icon?: string;
  canRemove: boolean;
  removeHint: string;
}
