import { inject } from '@angular/core';
import { DirectoryFacade } from '@iot-platform/dalia/data-access';
import { DeviceVariableHelpers } from '@iot-platform/dalia/util/devices';
import { DeviceVariable as DaliaDeviceVariable } from '@iot-platform/models/dalia';
import { DeviceVariable as I4bDeviceVariable, I4BBulkOperationApiResponse, I4BBulkOperationApiResponseStatuses } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action } from '@ngrx/store';
import { get } from 'lodash';
import { concatMap, filter, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DeviceVariablesService } from '../../services/device-variables.service';
import { DeviceVariablesActions } from '../actions/device-variables.actions';
import { DevicesActions } from '../actions/devices.actions';
import { DeviceTemplatesFacade } from '../facades/device-templates.facade';
import { DeviceVariablesFacade } from '../facades/device-variables.facade';
import { DevicesFacade } from '../facades/devices.facade';

const getVariables = (variables: DaliaDeviceVariable[], deactivateActions: boolean): DaliaDeviceVariable[] =>
  variables.map((v: DaliaDeviceVariable): DaliaDeviceVariable => {
    if (deactivateActions) {
      return {
        ...v,
        canConfigure: false
      };
    }
    return v;
  });

const loadDeviceVariables$ = createEffect(
  /* istanbul ignore next */
  (
    actions$ = inject(Actions),
    deviceVariablesService = inject(DeviceVariablesService),
    deviceVariablesFacade = inject(DeviceVariablesFacade),
    devicesFacade = inject(DevicesFacade)
  ) =>
    actions$.pipe(
      ofType(DeviceVariablesActions.loadDeviceVariables),
      concatLatestFrom(() => deviceVariablesFacade.daliaVariables$),
      switchMap(([{ device }, daliaVariableList]) =>
        deviceVariablesService.getVariablesByDeviceId(device.id).pipe(
          map((i4bVariableList: I4bDeviceVariable[]) => DeviceVariableHelpers.mergeVariables(i4bVariableList, daliaVariableList)),
          map((variables: DaliaDeviceVariable[]) => getVariables(variables, devicesFacade.deactivateActions())),
          map((response: DaliaDeviceVariable[]) => DeviceVariablesActions.loadDeviceVariablesSuccess({ response })),
          catchError((error) => of(DeviceVariablesActions.loadDeviceVariablesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadDeviceTemplateVariables$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), directoryFacade = inject(DirectoryFacade), deviceTemplatesFacade = inject(DeviceTemplatesFacade)) =>
    actions$.pipe(
      ofType(DeviceVariablesActions.loadDeviceTemplateVariables),
      concatLatestFrom(() => directoryFacade.modbusTable$),
      map(([{ device }, modbusTable]) => DeviceVariableHelpers.getVariables(device, modbusTable)),
      map((variables: DaliaDeviceVariable[]) => getVariables(variables, deviceTemplatesFacade.deactivateActions())),
      map((response: DaliaDeviceVariable[]) => DeviceVariablesActions.loadDeviceVariablesSuccess({ response }))
    ),
  { functional: true }
);

const setDaliaVariableList$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), directoryFacade = inject(DirectoryFacade)) =>
    actions$.pipe(
      ofType(DevicesActions.loadDeviceByIdSuccess),
      concatLatestFrom(() => directoryFacade.modbusTable$),
      map(([{ response }, modbusTable]) => DeviceVariableHelpers.getVariables(response, modbusTable)),
      map((variables: DaliaDeviceVariable[]) => DeviceVariablesActions.setDaliaVariableList({ variables }))
    ),
  { functional: true }
);

const bulkDeleteDeviceVariables$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceVariablesService = inject(DeviceVariablesService)) =>
    actions$.pipe(
      ofType(DeviceVariablesActions.bulkDeleteDeviceVariables),
      concatMap(({ variables }) =>
        deviceVariablesService.bulkDeleteDeviceVariables(variables).pipe(
          map((response: I4BBulkOperationApiResponse) => DeviceVariablesActions.bulkDeleteDeviceVariablesSuccess({ response })),
          catchError((error) => of(DeviceVariablesActions.bulkDeleteDeviceVariablesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const bulkDeleteDeviceVariablesSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesFacade = inject(DevicesFacade)) =>
    actions$.pipe(
      ofType(DeviceVariablesActions.bulkDeleteDeviceVariablesSuccess),
      concatLatestFrom(() => devicesFacade.currentEntity$),
      map(([_, device]) => get(device, 'id')),
      filter((id) => !!id),
      map((id: string) => DevicesActions.loadDeviceById({ id }))
    ),
  { functional: true }
);

const bulkDeleteDeviceVariablesSuccessDisplaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceVariablesActions.bulkDeleteDeviceVariablesSuccess),
      tap(({ type, response }) => {
        const status = get(I4BBulkOperationApiResponseStatuses, `[${response?.status}]`, '')?.toUpperCase();
        notificationService.displaySuccess(`${type} (${status})`);
      })
    ),
  { functional: true, dispatch: false }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceVariablesActions.loadDeviceVariables, DeviceVariablesActions.loadDeviceTemplateVariables, DeviceVariablesActions.bulkDeleteDeviceVariables),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceVariablesActions.loadDeviceVariablesSuccess,
        DeviceVariablesActions.loadDeviceVariablesFailure,
        DeviceVariablesActions.bulkDeleteDeviceVariablesSuccess,
        DeviceVariablesActions.bulkDeleteDeviceVariablesFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceVariablesActions.loadDeviceVariablesFailure, DeviceVariablesActions.bulkDeleteDeviceVariablesFailure),
      tap((action: Action) => notificationService.displayError(action.type))
    ),
  { functional: true, dispatch: false }
);

export const DeviceVariablesEffects = {
  loadDeviceVariables$,
  loadDeviceTemplateVariables$,
  bulkDeleteDeviceVariables$,
  bulkDeleteDeviceVariablesSuccess$,
  bulkDeleteDeviceVariablesSuccessDisplaySuccess$,
  showLoader$,
  hideLoader$,
  displayError$,
  setDaliaVariableList$
};
