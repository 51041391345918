import { CommonApiRequest, CommonApiResponse, Duration, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceDetails } from '@iot-platform/models/dalia';
import { CommandType } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';

export const DevicesActions = createActionGroup({
  source: 'Devices',
  events: {
    'Load Devices': props<{
      request: CommonApiRequest;
    }>(),
    'Load Devices Success': props<{
      response: CommonApiResponse<DeviceDetails, Pagination>;
    }>(),
    'Load Devices Failure': props<{
      error: unknown;
    }>(),

    'Set Filters': props<{
      filters: Filter[];
    }>(),

    'Load Device By Id': props<{
      id: string;
    }>(),
    'Load Device By Id Success': props<{
      response: DeviceDetails;
    }>(),
    'Load Device By Id Failure': props<{
      error: unknown;
    }>(),

    'Set Current Device': props<{
      device: DeviceDetails;
    }>(),

    'Set Last Call Duration': props<{
      duration: Duration;
    }>(),

    'Set Last Call Status Refresh Activated': props<{
      value: boolean;
    }>(),

    'Update Device': props<{
      device: DeviceDetails;
    }>(),
    'Update Device Success': props<{
      response: DeviceDetails;
    }>(),
    'Update Device Failure': props<{
      error: unknown;
    }>(),

    'Send Command': props<{
      device: DeviceDetails;
      command: CommandType;
    }>(),
    'Send Command Success': props<{
      response: DeviceDetails;
    }>(),
    'Send Command Failure': props<{
      error: unknown;
    }>()
  }
});
