import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceTemplate } from '@iot-platform/models/dalia';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import * as fromDeviceTemplates from '../reducers/device-templates.reducer';

@Injectable({
  providedIn: 'root'
})
export class DeviceTemplatesSelectors extends ApiFeatureSelector<fromDeviceTemplates.State, DeviceTemplate, Pagination, Filter> {
  selectDeletionInProgress: MemoizedSelector<fromDeviceTemplates.State, boolean> = createSelector(
    this.selectState,
    (state: fromDeviceTemplates.State) => state.deletionInProgress
  );

  constructor() {
    super(fromDeviceTemplates.deviceTemplatesFeature.selectDeviceTemplatesState, fromDeviceTemplates.adapter);
  }
}
