import { BaseState, CommonIndexedPagination, Filter, INITIAL_BASE_STATE, TagCategory } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { TagCategoriesActions } from '../actions/tag-categories.actions';

export type TagCategoryState = BaseState<TagCategory, CommonIndexedPagination, Filter>;

export const tagCategoriesAdapter: EntityAdapter<TagCategory> = createEntityAdapter<TagCategory>({
  selectId: (entity: TagCategory) => entity.id,
  sortComparer: false
});

export const initialState: TagCategoryState = tagCategoriesAdapter.getInitialState({
  ...INITIAL_BASE_STATE
});

export const tagCategoriesFeature = createFeature({
  name: 'tagCategories',
  reducer: createReducer(
    initialState,
    on(
      TagCategoriesActions.loadTagCategoriesSuccess,
      (state: TagCategoryState, { response }): TagCategoryState =>
        tagCategoriesAdapter.setAll(response, {
          ...state
        })
    )
  )
});
