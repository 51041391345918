<div
  [ngClass]="cssClassName()"
  class="timeline-list__item"
  data-cy="iot-platform-ui-timeline-list-item"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="10px"
>
  <div fxFlex="30" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
    <mat-icon [svgIcon]="icon()" class="icon"></mat-icon>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start" fxLayoutGap="10px">
      <span class="timeline-list__item_timestamp" data-cy="iot-platform-ui-timeline-list-item-timestamp">{{
        timestamp() | date: 'MMM.dd - yyyy, HH:mm:ss'
      }}</span>
      <span class="timeline-list__item_label" data-cy="iot-platform-ui-timeline-list-item-label">{{ label() | translate }}</span>
    </div>
  </div>
  <div class="separator" fxFlex="70" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    @if (description()) {
      <span data-cy="iot-platform-ui-timeline-list-item-description" class="timeline-list__item_description">{{ description() | translate }}</span>
    } @else {
      <ng-content></ng-content>
    }
  </div>
</div>
