import { computed, inject, Injectable, signal, Signal } from '@angular/core';
import { DirectoryFacade } from '@iot-platform/dalia/data-access';
import { DeviceVariableHelpers } from '@iot-platform/dalia/util/devices';
import { BaseFacade, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceDetails, DeviceVariable, DeviceVariableState } from '@iot-platform/models/dalia';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { DeviceVariablesActions } from '../actions';
import { DeviceVariablesSelectors } from '../selectors/device-variables.selectors';

@Injectable({
  providedIn: 'root'
})
export class DeviceTemplateVariablesFacade extends BaseFacade<DeviceVariable, Pagination, Filter> {
  daliaVariables: Signal<DeviceVariable[]> = this.store.selectSignal(this.selector.selectDaliaVariables);
  variablesToBeDeleted: Signal<DeviceVariable[]> = signal([]);
  canDisplayCleanupDeletedVariablesAction: Signal<boolean> = signal(false);
  protected readonly directoryFacade: DirectoryFacade = inject(DirectoryFacade);
  variableState: Signal<DeviceVariableState> = computed(() => {
    const variable = this.currentEntity();
    const modbusTable = this.directoryFacade.modbusTable();
    return DeviceVariableHelpers.getVariableState(variable, modbusTable);
  });

  constructor(
    protected override store: Store,
    protected override selector: DeviceVariablesSelectors
  ) {
    super(store, selector);
  }

  getAll() {
    noop();
  }

  setFilters() {
    noop();
  }

  loadDeviceVariables(device: DeviceDetails) {
    this.store.dispatch(DeviceVariablesActions.loadDeviceTemplateVariables({ device }));
  }

  setCurrentVariable(variable: DeviceVariable) {
    this.store.dispatch(DeviceVariablesActions.setCurrentVariable({ variable }));
  }
}
