import { Pipe, PipeTransform } from '@angular/core';
import { Entity } from '@iot-platform/models/common';

@Pipe({
  name: 'entityDisplay',
  standalone: true
})
export class EntityDisplayPipe implements PipeTransform {
  transform(entity: Entity, entities: Entity[] | null): string {
    if (entity && entities) {
      const fEntity = entities.find((e) => e.id === entity.id);
      const parentEntity = entities.find((e) => e.id === fEntity?.parentId);
      return parentEntity ? parentEntity.name + ' | ' + entity.name : entity.name ?? '-';
    }
    return '-';
  }
}
