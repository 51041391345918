import { DeviceDetails, DeviceTemplate } from '@iot-platform/models/dalia';
import { createActionGroup, props } from '@ngrx/store';

export const DeviceConfigurationActions = createActionGroup({
  source: 'Device Configuration',
  events: {
    'Publish Configuration': props<{
      device: DeviceDetails;
    }>(),
    'Publish Configuration Success': props<{
      response: DeviceDetails;
    }>(),
    'Publish Configuration Failure': props<{
      error: unknown;
    }>(),

    'Cancel Target Configuration': props<{
      device: DeviceDetails;
    }>(),
    'Cancel Target Configuration Success': props<{
      response: DeviceDetails;
    }>(),
    'Cancel Target Configuration Failure': props<{
      error: unknown;
    }>(),

    'Update Configuration': props<{
      device: DeviceDetails;
      configuration: { [key: string]: unknown };
    }>(),
    'Update Configuration Success': props<{
      response: DeviceDetails;
    }>(),
    'Update Configuration Failure': props<{
      error: unknown;
    }>(),

    'Patch Configuration': props<{
      device: DeviceDetails;
      configuration: { [key: string]: unknown };
    }>(),
    'Patch Configuration Success': props<{
      response: DeviceDetails;
    }>(),
    'Patch Configuration Failure': props<{
      error: unknown;
    }>(),

    'Apply template': props<{
      device: DeviceDetails;
      deviceTemplate: DeviceTemplate;
    }>(),
    'Apply template Success': props<{
      response: DeviceDetails;
    }>(),
    'Apply template Failure': props<{
      error: unknown;
    }>(),

    'Copy Configuration': props<{
      source: DeviceDetails;
      target: DeviceDetails;
    }>(),
    'Copy Configuration Success': props<{
      response: DeviceDetails;
    }>(),
    'Copy Configuration Failure': props<{
      error: unknown;
    }>()
  }
});
