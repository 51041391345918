<iot-platform-ui-panel (clicked)="manageTags.emit()" [buttonTooltip]="tooltip() | translate" [hasButton]="canCreateOrUpdateTag()" [title]="title() | translate">
  <mat-chip-set panelContent>
    @if (!loading()) {
      @for (tag of tags(); track tag) {
        <iot-platform-ui-chip [chipButton]="{ display: false }" [chip]="tag" [isTag]="true" [ngClass]="{ invisible: loading(), visible: !loading() }">
        </iot-platform-ui-chip>
      }
    }
  </mat-chip-set>
  @if (loading()) {
    <iot-platform-ui-card-loader [backgroundClass]="'tag-loader info-page'" panelGrid></iot-platform-ui-card-loader>
  }
</iot-platform-ui-panel>
