@if (data()) {
  <iot-platform-ui-base-card-details-widget [title]="title()" cssClass="no-background">
    @for (row of templateDefinition(); track $index) {
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="15px">
        @for (def of row; track def.id) {
          <div fxLayout="row" fxLayout.lt-md="column" fxFlex.lt-md="100%" fxLayoutGap="10px" [fxFlex]="def?.fxFlex || 33">
            @switch (def.type) {
              @case (TemplateDefType.LIST) {
                <ng-container [ngTemplateOutlet]="listTmpl" [ngTemplateOutletContext]="{ def: def }"></ng-container>
              }
              @case (TemplateDefType.BUTTON) {
                <ng-container [ngTemplateOutlet]="buttonTmpl" [ngTemplateOutletContext]="{ def: def }"></ng-container>
              }
              @default {
                <ng-container [ngTemplateOutlet]="defaultTmpl" [ngTemplateOutletContext]="{ def: def }"></ng-container>
              }
            }
          </div>
        }
      </div>
    }
  </iot-platform-ui-base-card-details-widget>
}

<ng-template #defaultTmpl let-def="def">
  <div fxLayout="row" fxLayoutGap="10px">
    @if (!def?.attrKey) {
      <span class="card-details-widget__label fw-600">
        @if (def?.isLink) {
          <a (click)="onDispatchEvent(TemplateDefDispatchEventType.OPEN_LINK, def, data())">{{ def.label | translate }}</a>
        } @else {
          {{ def.label | translate }}
        }
      </span>
    } @else {
      <span class="card-details-widget__label">
        {{ def.label | translate }}
      </span>

      @if (def?.isLink) {
        <span class="card-details-widget__value">
          <a (click)="onDispatchEvent(TemplateDefDispatchEventType.OPEN_LINK, def, data())"
            ><ng-container [ngTemplateOutletContext]="{ def: def }" [ngTemplateOutlet]="valueTmpl"></ng-container
          ></a>
        </span>
      } @else {
        <span class="card-details-widget__value"><ng-container [ngTemplateOutletContext]="{ def: def }" [ngTemplateOutlet]="valueTmpl"></ng-container></span>
      }
    }
  </div>
</ng-template>

<ng-template #listTmpl let-def="def">
  @if (def?.value$?.length > 0) {
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <span class="card-details-widget__label"> {{ def.label | translate }} </span>
      <ul class="card-details-widget__list-items" fxLayout="row wrap" fxLayoutGap="10px">
        @for (item$ of def?.value$; track def.id) {
          <li>
            {{ item$ | async | infoDisplay | translate }}
          </li>
        }
      </ul>
    </div>
  }
</ng-template>

<ng-template #buttonTmpl let-def="def">
  <button
    (click)="onDispatchEvent(TemplateDefDispatchEventType.BUTTON_CLICK, def, data())"
    [matTooltip]="def.tooltip | translate"
    class="card-details-widget__button"
    color="accent"
    mat-raised-button
  >
    {{ def.label | translate }}
  </button>
</ng-template>

<ng-template #valueTmpl let-def="def">
  {{ def?.value$ | async | infoDisplay | translate }}
</ng-template>
