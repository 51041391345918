import { inject } from '@angular/core';
import { AuthApiActions } from '@iot-platform/auth';
import { TagCategory } from '@iot-platform/models/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { TagCategoriesService } from '../../services/tag-categories.service';
import { TagCategoriesActions } from '../actions/tag-categories.actions';

const loadTagCategories$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), tagCategoriesService = inject(TagCategoriesService)) =>
    actions$.pipe(
      ofType(AuthApiActions.loadAccountSuccess, TagCategoriesActions.loadTagCategories),
      switchMap(() => tagCategoriesService.getTagCategories()),
      map((response: TagCategory[]) => TagCategoriesActions.loadTagCategoriesSuccess({ response }))
    ),
  { functional: true }
);

export const TagCategoriesEffects = {
  loadTagCategories$
};
