import { ModbusTableHelpers } from '@iot-platform/dalia/util';
import { ArrayUtils, StringUtils } from '@iot-platform/iot-platform-utils';
import {
  DeviceConfiguration,
  DeviceConfigurationStatus,
  DeviceDetails,
  DeviceVariable as DaliaDeviceVariable,
  DeviceVariableState,
  ModbusListItem,
  ModbusListName,
  ModbusTable,
  ModbusTablePropertyState
} from '@iot-platform/models/dalia';
import { DeviceVariable as I4bDeviceVariable } from '@iot-platform/models/i4b';
import { cloneDeep, get, uniq, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DeviceTankHelpers } from './device-tank.helpers';
import { DeviceHelpers } from './device.helpers';

export class DeviceVariableHelpers {
  // uid is a generated attribute used in variables comparators
  // sometimes for some dalia variables we find variables with no names
  // so in this case we generate a uid so as not to have conflict with unnamed iot4bos variables
  public static readonly DALIA_DEVICE_VAR_UID_KEY = 'uid';

  public static MAX_ALLOWED_VARIABLE_INDEX = 50;

  public static readonly ATTRIBUTE_MAPPER = {
    kind: 'Kind',
    name: 'Variable_name',
    comment: 'Comment',
    unitCode: 'Unit_code',
    path: 'Path',
    channel: 'Channel',
    slaveModbusAddress: 'Multi.Modbus.slave@/CALC.index_var1/ADC.type',
    dataModbusAddress: 'Multi.Modbus.data@/CALC.index_var2_or_derive_period',
    inputVariable1: 'Multi.Modbus.slave@/CALC.index_var1/ADC.type',
    drasticChangeAsset: 'Multi.Modbus.slave@/CALC.index_var1/ADC.type',
    inputVariable2: 'Multi.Modbus.data@/CALC.index_var2_or_derive_period',
    alarm: 'Alarm.type',
    relay: 'Alarm.relay',
    lowerThreshold: 'Alarm.lower_threshold',
    upperThreshold: 'Alarm.upper_threshold',
    lowerThresholdSeverity: 'Alarm.severity_lower_threshold',
    upperThresholdSeverity: 'Alarm.severity_upper_threshold',
    lowerThresholdClass: 'Alarm.class_lower_threshold',
    upperThresholdClass: 'Alarm.class_upper_threshold',
    type: 'Data_type',
    warmup: 'Warmup_time',
    slope: 'Slope',
    offset: 'Offset',
    decimalPrecision: 'Decimal_precision',
    modbusParity: 'Modbus.parity',
    modbusBaudrate: 'Modbus.baudrate',
    modbusStopBits: 'Modbus.stop_bits',
    modbusFunction: 'Modbus.function',
    modbusType: 'Modbus.tpe',
    recordMode: 'Record_mode',
    alarmHysteresis: 'Alarm.hysteresis',
    alarmReserved: 'Alarm.reserved',
    modbusBitmask: 'Modbus.bitmask',
    transmissionScaler: 'Transmission_scaler',
    acquisitionScaler: 'Acquisition_scaler',
    modbusVariableToCopy: 'Modbus.variable_to_copy'
  };

  public static readonly CONFIGURATION_STATE_ATTRIBUTE_MAPPER = {
    generalInformation: {
      kind: true,
      name: true,
      comment: true,
      unitCode: true,
      path: true,
      channel: true,
      slaveModbusAddress: true,
      dataModbusAddress: true,
      inputVariable1: true,
      inputVariable2: true,
      drasticChangeAsset: true,
      modbusVariableToCopy: true
    },
    deviceEvent: {
      alarm: true,
      relay: true,
      lowerThreshold: true,
      upperThreshold: true,
      lowerThresholdSeverity: true,
      upperThresholdSeverity: true,
      lowerThresholdClass: true,
      upperThresholdClass: true,
      alarmHysteresis: true
    },
    advancedInformation: {
      type: true,
      decimalPrecision: true,
      slope: true,
      offset: true,
      warmup: true,
      slaveModbusAddress: true,
      dataModbusAddress: true,
      modbusParity: true,
      modbusBaudrate: true,
      modbusStopBits: true,
      modbusFunction: true,
      modbusType: true
    },
    otherInformation: {
      recordMode: true,
      modbusBitmask: true,
      transmissionScaler: true,
      acquisitionScaler: true,
      alarmReserved: true
    }
  };

  public static readonly COMPUTED_VARIABLE_ATTRIBUTES = ['inputVariable1', 'inputVariable2', 'modbusVariableToCopy'];
  public static readonly COMPUTED_TANK_ATTRIBUTES = ['drasticChangeAsset'];
  public static readonly COMPUTED_ATTRIBUTES = [...DeviceVariableHelpers.COMPUTED_VARIABLE_ATTRIBUTES, ...DeviceVariableHelpers.COMPUTED_TANK_ATTRIBUTES];

  public static readonly MAPPED_PROPERTIES = {
    type: ModbusListName.LstTypeEnum,
    kind: ModbusListName.LstKindType,
    unitCode: ModbusListName.LstUnitEnum,
    path: ModbusListName.LstPathEnum,
    channel: ModbusListName.LstChannelInternal, // By default internal list
    alarm: ModbusListName.LstAlarmType,
    relay: ModbusListName.LstRelayAction,
    modbusParity: ModbusListName.LstParity,
    modbusStopBits: ModbusListName.LstMbStopbits,
    modbusFunction: ModbusListName.LstFctMB,
    modbusType: ModbusListName.LstMbType,
    recordMode: ModbusListName.LstRecMode
  };

  // Return all variable names from device configurations
  // Exclude variables to be deleted
  static getAllVariableNames(
    configuration: DeviceConfiguration,
    skipRemovedVariables: boolean
  ): {
    key: string;
    index: number;
    value: string;
  }[] {
    const getNamesFrom = (targetConfiguration: string) => {
      const config = get(configuration, [targetConfiguration], {});
      const nameValueGetter = (key: string) => {
        const path = [`${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.name}`];
        if (targetConfiguration === 'current') {
          path.push('v');
        }
        return path;
      };
      return DeviceVariableHelpers.getPropertiesFromConfig(config, nameValueGetter);
    };
    const currentNames: { key: string; index: number; value: string }[] = getNamesFrom('current');
    const pendingNames: { key: string; index: number; value: string }[] = getNamesFrom('pending');
    const targetNames: { key: string; index: number; value: string }[] = getNamesFrom('target');
    // Remove variables to be deleted
    return uniqBy([...currentNames, ...pendingNames, ...targetNames], 'key').filter(({ key }) => {
      // Check if the variable will be deleted
      // Kind === 0
      const kind = get(
        configuration,
        ['pending', `${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.kind}`],
        get(configuration, ['target', `${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.kind}`])
      );
      if (skipRemovedVariables) {
        return kind !== 0;
      }
      return true;
    });
  }

  public static readonly hasCleanConfiguration = (variable: DaliaDeviceVariable, modbusTable: ModbusTable) => {
    const state = DeviceVariableHelpers.getVariableState(variable, modbusTable);
    const hasRemovedAttribute = () =>
      Object.entries(state).some(([k1]) => {
        const attributes = get(state, [k1, 'attributes'], {});
        return Object.entries(attributes).some(([k2, v2]) => {
          if (DeviceVariableHelpers.COMPUTED_ATTRIBUTES.includes(k2)) {
            const hasTankForInputVariable1 = k2 === 'inputVariable1' && ModbusTableHelpers.hasTank(variable.kind?.value);
            const hasTankForInputVariable2 = k2 === 'inputVariable2' && ModbusTableHelpers.hasTank(variable.kind?.value);
            const hasDrasticChangeForDrasticChangeAsset = k2 === 'drasticChangeAsset' && ModbusTableHelpers.hasDrasticChange(variable.kind?.value);
            const hasInputVar1 = k2 === 'inputVariable1' && ModbusTableHelpers.hasInputVar1(variable.kind?.value);
            const hasInputVar2 = k2 === 'inputVariable2' && ModbusTableHelpers.hasInputVar2(variable.kind?.value);
            const hasVariableToCopy =
              k2 === 'modbusVariableToCopy' && ModbusTableHelpers.hasVariableToCopy(variable.kind?.value, variable.modbusFunction?.value);

            if (
              hasTankForInputVariable1 ||
              hasTankForInputVariable2 ||
              hasDrasticChangeForDrasticChangeAsset ||
              hasInputVar1 ||
              hasInputVar2 ||
              hasVariableToCopy
            ) {
              return get(v2, ['status']) === DeviceConfigurationStatus.DELETED;
            }
            return false;
          }
          return get(v2, ['status']) === DeviceConfigurationStatus.DELETED;
        });
      }, false);
    return !hasRemovedAttribute();
  };

  // Generate processed dalia variable list
  public static readonly getVariables = (device: DeviceDetails, modbusTable: ModbusTable): DaliaDeviceVariable[] => {
    const currentKeys: string[] = DeviceVariableHelpers.getVariableKeysFromConfig(get(device, ['configuration', 'current'], {}));
    const pendingKeys: string[] = DeviceVariableHelpers.getVariableKeysFromConfig(get(device, ['configuration', 'pending'], {}));
    const targetKeys: string[] = DeviceVariableHelpers.getVariableKeysFromConfig(get(device, ['configuration', 'target'], {}));

    // Created new variables does not exist in current configuration, they exist in pending configuration instead
    const createdNewVariableKeys = pendingKeys.filter((k) => !currentKeys.includes(k));
    // Updated variables exist in both of current and pending configurations
    const updatedVariableKeys = currentKeys.filter((k) => pendingKeys.includes(k));
    // Unmodified variables should not exist in created and updated lists
    const currentVariableKeys = currentKeys.filter((k) => !createdNewVariableKeys.includes(k) && !updatedVariableKeys.includes(k));

    const currentVariables = DeviceVariableHelpers.getVariablesFromConfiguration(
      currentVariableKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const updatedVariables = DeviceVariableHelpers.getVariablesFromConfiguration(
      updatedVariableKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const createdVariables = DeviceVariableHelpers.getVariablesFromConfiguration(
      createdNewVariableKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['pending', key], // should retrieve properties from pending configuration for created variables
      true
    );

    const items = [...currentVariables, ...updatedVariables, ...createdVariables]
      .map((v) => ({
        ...v,
        // This is used to identify there is a deleted variable or tank
        hasCleanConfiguration: DeviceVariableHelpers.hasCleanConfiguration(v, modbusTable)
      }))
      .sort((a, b) => a.index - b.index);
    return items.slice(0, DeviceVariableHelpers.MAX_ALLOWED_VARIABLE_INDEX);
  };

  // Merge I4B and dalia variables
  public static readonly mergeVariables = (variables: I4bDeviceVariable[], daliaVariableList: DaliaDeviceVariable[]): DaliaDeviceVariable[] => {
    const i4bVariableList: DaliaDeviceVariable[] = variables.map((i4bDeviceVariable: I4bDeviceVariable) => {
      // Search variable by uid
      let daliaDeviceVariable = daliaVariableList.find((v) => i4bDeviceVariable.name === v[this.DALIA_DEVICE_VAR_UID_KEY]);

      // For variables that have been removed, they will be duplicated in current and pending configurations
      // Cause technically, to remove a variable configuration
      // We just keep the current configuration and we make a copy of it in pending configuration with empty values
      // So in this case, we should search variable by name and kind 0 in current config to get its status (pending or target)
      // Then we set it as not configurable to prevent configure and delete actions on it
      if (!daliaDeviceVariable) {
        const found = daliaVariableList.find((v) => {
          const key = DeviceVariableHelpers.getVariableIndexKey(v);
          return (
            get(v, ['kind', 'value']) === 0 &&
            i4bDeviceVariable.name === get(v, ['device', 'configuration', 'current', `${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.name}`, 'v'])
          );
        });
        if (found)
          daliaDeviceVariable = {
            ...found,
            canConfigure: false
          };
      }

      // For i4b variables that does not have a group or their group is "Configured"
      // These variables should be marked as deleted
      let status = !i4bDeviceVariable.group || i4bDeviceVariable.group === 'Configured' ? DeviceConfigurationStatus.DELETED : DeviceConfigurationStatus.CURRENT;
      if (daliaDeviceVariable) {
        status = daliaDeviceVariable.status;
      }

      return {
        ...i4bDeviceVariable,
        ...daliaDeviceVariable,
        id: i4bDeviceVariable.id,
        name: i4bDeviceVariable.name,
        unit: i4bDeviceVariable.unit,
        linked: !!i4bDeviceVariable.linked,
        status
      };
    });
    // Return a diff array and remove variables with kind == 0
    const diffVariableList: DaliaDeviceVariable[] = ArrayUtils.diffByKeys(daliaVariableList, i4bVariableList, this.DALIA_DEVICE_VAR_UID_KEY, 'name').filter(
      (v) => get(v, ['kind', 'value']) !== 0
    );
    return [...i4bVariableList, ...diffVariableList];
  };

  public static readonly getPropertiesFromConfig = (
    configuration: {
      [key: string]: unknown;
    },
    valueGetter: (key: string) => string[]
  ): {
    key: string;
    index: number;
    value: string;
  }[] => {
    const keys = DeviceVariableHelpers.getVariableKeysFromConfig(configuration);
    return keys.map((key) => ({
      key,
      index: DeviceHelpers.parseIndex(key),
      value: get(configuration, valueGetter(key))
    }));
  };

  public static readonly getVariableKeysFromConfig = (configuration: { [key: string]: unknown }): string[] => {
    const keys = Object.keys(configuration)
      .filter((key: string) => key.match(/(^Var\d+(\.Variable_name.)*)./gi))
      .map((key) => key.split('.')[0]);
    return uniq(keys);
  };

  public static readonly getAvailableNextIndex = (configuration: DeviceConfiguration): number => {
    const indexes = DeviceVariableHelpers.getAllVariableNames(configuration, false).map((item) => item.index);
    return DeviceHelpers.getAvailableNextIndex(indexes, DeviceVariableHelpers.MAX_ALLOWED_VARIABLE_INDEX);
  };

  public static readonly getAvailableIndexes = (configuration: DeviceConfiguration, currentIndex?: number): ModbusListItem[] => {
    const indexes = DeviceVariableHelpers.getAllVariableNames(configuration, false).map((item) => item.index);
    return DeviceHelpers.getAvailableIndexes(indexes, DeviceVariableHelpers.MAX_ALLOWED_VARIABLE_INDEX, currentIndex);
  };

  public static readonly getVariablesFromConfiguration = (
    keys: string[],
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ): DaliaDeviceVariable[] =>
    uniq(keys).reduce((acc, key) => {
      const variable = DeviceVariableHelpers.getVariableInstance(key, device, modbusTable, pendingKeys, targetKeys, valueGetter, isCreated);
      return [...acc, variable];
    }, []);

  // Return configuration state by attribute name
  public static readonly getPropertyState = (attrName: string, variable: DaliaDeviceVariable, modbusTable: ModbusTable): ModbusTablePropertyState => {
    const key = DeviceVariableHelpers.getVariableIndexKey(variable);
    let status = DeviceConfigurationStatus.CURRENT;
    const attrPath = `${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER[attrName]}`;
    let newCollectionName = 'pending';
    if (variable?.status === DeviceConfigurationStatus.PUBLISHED) {
      newCollectionName = 'target';
    }

    const mappedProperties = {
      ...this.MAPPED_PROPERTIES
    };

    const valueGetter = (k: string) => (variable?.isCreated ? [newCollectionName, k] : ['current', k, 'v']);

    const configuration = get(variable, ['device', 'configuration'], {}) as DeviceConfiguration;

    let kind = parseInt(get(configuration, valueGetter(`${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.kind}`)) as string);
    // For created variables we don't have an old kind
    if (variable?.isCreated) {
      kind = parseInt(get(configuration, [newCollectionName, `${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.kind}`]) as string);
    }

    const hasTank = ModbusTableHelpers.hasTank(kind);
    const hasDrasticChange = ModbusTableHelpers.hasDrasticChange(kind);

    if (attrName === 'channel') {
      // Set channel list name
      mappedProperties.channel = ModbusTableHelpers.getChannelListName(kind);
    }

    let oldValue = get(configuration, valueGetter(attrPath), null);
    const newValue = get(configuration, [newCollectionName, attrPath], null);

    if (DeviceVariableHelpers.COMPUTED_ATTRIBUTES.includes(attrName)) {
      if (!(hasTank && attrName === 'inputVariable2')) {
        const currentIndexes = !(hasTank || hasDrasticChange)
          ? DeviceVariableHelpers.getAllVariableNames(configuration, true).map((item) => item.index)
          : DeviceTankHelpers.getAllTankIndexes(configuration, true).map((item) => item.index);

        // In case computed values are impacted by removed variables or tanks
        // We change their status to DELETED
        // oldValue === Old index
        // newValue === new index
        const hasOldIndexAndNewIndexRemoved = oldValue === null && newValue !== null && !currentIndexes.includes(newValue);
        const hasNotOldIndexAndNewIndexRemoved = oldValue !== null && newValue !== null && !currentIndexes.includes(newValue);
        const hasNewIndexAndOldIndexRemoved = oldValue !== null && newValue === null && !currentIndexes.includes(oldValue);

        if (hasOldIndexAndNewIndexRemoved || hasNotOldIndexAndNewIndexRemoved || hasNewIndexAndOldIndexRemoved) {
          status = DeviceConfigurationStatus.DELETED;
        }
      }

      // Set old computed value
      oldValue = DeviceVariableHelpers.getComputedValue(key, attrName, configuration, valueGetter);
    }

    if (status !== DeviceConfigurationStatus.DELETED && newValue !== null && (variable?.isCreated || oldValue !== newValue)) {
      status = variable.status;
    }
    if (variable?.isCreated && attrName === 'alarm' && newValue === 0 && oldValue === 0) {
      status = DeviceConfigurationStatus.CURRENT;
    }
    let displayValue = oldValue;
    if (Object.keys(mappedProperties).includes(attrName)) {
      displayValue = ModbusTableHelpers.getListItemByListName(modbusTable, get(mappedProperties, [attrName], 'listName'), oldValue)?.label;
    }
    if (hasTank) {
      if (attrName === 'inputVariable1') {
        displayValue = DeviceVariableHelpers.computeSlaveModbusAddressOfTypeTank(key, configuration, valueGetter);
      } else if (attrName === 'inputVariable2') {
        displayValue = DeviceVariableHelpers.computeDataModbusAddressOfTypeTank(key, configuration, valueGetter);
      }
    } else if (hasDrasticChange) {
      if (attrName === 'drasticChangeAsset') {
        displayValue = DeviceVariableHelpers.computeSlaveModbusAddressOfTypeTank(key, configuration, valueGetter);
      }
    }

    if (attrName === 'lowerThresholdSeverity' || attrName === 'upperThresholdSeverity') {
      displayValue = ModbusTableHelpers.getSeverities().find((item) => item.value === oldValue)?.label;
    }

    return {
      oldValue,
      newValue,
      displayValue,
      status,
      attrName,
      key: attrPath
    };
  };

  public static getVariableState = (variable: DaliaDeviceVariable, modbusTable: ModbusTable): DeviceVariableState => {
    const status = get(variable, 'status', DeviceConfigurationStatus.CURRENT);
    return Object.keys(DeviceVariableHelpers.CONFIGURATION_STATE_ATTRIBUTE_MAPPER).reduce(
      (acc, key) => {
        const attributes = Object.keys(DeviceVariableHelpers.CONFIGURATION_STATE_ATTRIBUTE_MAPPER[key]);
        const obj = attributes.reduce(
          (acc1, attr) => ({
            ...acc1,
            [attr]: DeviceVariableHelpers.getPropertyState(attr, variable, modbusTable)
          }),
          {}
        );
        const isPendingOrPublished = attributes.some(
          (attr) => obj[attr].status === DeviceConfigurationStatus.PENDING || obj[attr].status === DeviceConfigurationStatus.PUBLISHED
        );
        return {
          ...acc,
          [key]: {
            status: isPendingOrPublished ? status : DeviceConfigurationStatus.CURRENT,
            attributes: obj
          }
        };
      },
      {
        status
      } as DeviceVariableState
    );
  };

  // Return variable prefix key by index
  public static readonly getVariableIndexKey = (variable: DaliaDeviceVariable): string => `Var${StringUtils.padWith(variable?.index, 2, '0')}`;

  // Return a new configuration copy without properties that match variable index
  public static readonly removePropertiesFrom = (
    variable: DaliaDeviceVariable,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(variable, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get variable key
    const key = DeviceVariableHelpers.getVariableIndexKey(variable);
    return DeviceHelpers.reduceConfiguration(configuration, key, targetConfiguration, false);
  };

  // Return all configuration properties that match variable index
  public static readonly getPropertiesFrom = (
    variable: DaliaDeviceVariable,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(variable, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get variable key
    const key = DeviceVariableHelpers.getVariableIndexKey(variable);
    return DeviceHelpers.reduceConfiguration(configuration, key, targetConfiguration, true);
  };

  // Variable that does not have an index or their kind is 0 are not configurable
  public static readonly canConfigureVariable = (variable: DaliaDeviceVariable) =>
    variable.index !== null &&
    variable.index !== undefined &&
    variable.kind?.value !== 0 &&
    !Object.keys(get(variable.device, ['configuration', 'target'], {})).length;

  public static readonly computeDataModbusAddressOfTypeTank = (key: string, configuration: DeviceConfiguration, valueGetter: (key: string) => string[]) => {
    const dataModbusAddress = get(configuration, valueGetter(`${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.dataModbusAddress}`));
    return ModbusTableHelpers.getVariableTypes().find((v) => v.value === dataModbusAddress)?.label;
  };

  public static readonly computeSlaveModbusAddressOfTypeTank = (key: string, configuration: DeviceConfiguration, valueGetter: (key: string) => string[]) => {
    const slaveModbusAddress = get(configuration, valueGetter(`${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.slaveModbusAddress}`));
    return slaveModbusAddress !== null && slaveModbusAddress !== undefined ? `#${slaveModbusAddress}` : slaveModbusAddress;
  };

  public static readonly getComputedValue = (key: string, attrName: string, configuration: DeviceConfiguration, valueGetter: (k: string) => string[]) => {
    const value = get(configuration, valueGetter(`${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER[attrName]}`));
    let computedKey = key;
    if (DeviceVariableHelpers.COMPUTED_VARIABLE_ATTRIBUTES.includes(attrName)) {
      computedKey = `Var${StringUtils.padWith(value, 2, '0')}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.name}`;
    }
    // In case computed value found and empty return initial value
    // Otherwise return calculated value
    const result = get(configuration, valueGetter(computedKey));
    if (result !== undefined) {
      if (result === '') {
        return value;
      }
      return result;
    }
    // In case computed value is not found
    // Look for related index in all configuration
    return get(configuration, ['target', computedKey], get(configuration, ['pending', computedKey], get(configuration, ['current', computedKey, 'v'], value)));
  };

  public static readonly deleteConfigurationFrom = (variable: DaliaDeviceVariable, targetConfiguration: 'current' | 'pending' | 'target') => {
    const configuration = get(variable, ['device', 'configuration'], {});
    const currentKeys: string[] = DeviceVariableHelpers.getVariableKeysFromConfig(get(configuration, 'current', {}));
    const indexKey = DeviceVariableHelpers.getVariableIndexKey(variable);
    if (uniq(currentKeys).includes(indexKey)) {
      // Set kind to 0
      return {
        ...get(configuration, [targetConfiguration], {}),
        [`${indexKey}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.kind}`]: 0
      };
    }
    return DeviceVariableHelpers.removePropertiesFrom(variable, targetConfiguration);
  };

  public static readonly cleanUpConfiguration = (
    indexKey: string,
    deviceConfiguration: DeviceConfiguration,
    configuration: { [key: string]: unknown },
    targetConfiguration: 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Override pending configuration
    const config = {
      ...get(deviceConfiguration, [targetConfiguration], {}),
      ...configuration
    };

    const kind = get(configuration, [`${indexKey}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.kind}`]) as number;
    const alarmKey = `${indexKey}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.alarm}`;
    const alarm = get(configuration, [alarmKey], 0);

    // Check for variable to copy rule
    const modbusFunction = get(configuration, [`${indexKey}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.modbusFunction}`]) as number;
    const hasVariableToCopy = ModbusTableHelpers.hasVariableToCopy(kind, modbusFunction);

    // Clean up target configuration
    return Object.keys(config).reduce((acc, key) => {
      const currentValue = get(deviceConfiguration, ['current', key, 'v']);
      const canRemoveAlarmField = alarm === 0 && key !== alarmKey && key.indexOf(`${indexKey}.Alarm.`) !== -1;
      const canRemoveVariableToCopyField =
        !hasVariableToCopy && key.indexOf(`${indexKey}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.modbusVariableToCopy}`) !== -1;
      if (!(config[key] === currentValue || canRemoveAlarmField || canRemoveVariableToCopyField)) {
        acc[key] = config[key];
      }
      return { ...acc };
    }, {});
  };

  // Generate variable model instance
  private static getVariableInstance(
    key: string,
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ) {
    const status = DeviceHelpers.getStatusByKey(key, device, pendingKeys, targetKeys);
    const index = DeviceHelpers.parseIndex(key);

    // Compute and merge configuration objects
    const configuration = cloneDeep(get(device, 'configuration'));
    if (status === DeviceConfigurationStatus.PENDING) {
      configuration.current = DeviceHelpers.mergeConfigValues(device, 'pending');
    } else if (status === DeviceConfigurationStatus.PUBLISHED) {
      configuration.current = DeviceHelpers.mergeConfigValues(device, 'target');
    }

    const keys = Object.keys(DeviceVariableHelpers.ATTRIBUTE_MAPPER);

    // Get key values list
    const array = keys.map((attrName) => {
      const val = get(configuration, valueGetter(`${key}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER[attrName]}`));
      return {
        attrName,
        value: val
      };
    });

    // Get Channel list name by kind
    const kind = array.find(({ attrName }) => attrName === 'kind');
    const channelListName: ModbusListName = ModbusTableHelpers.getChannelListName(parseInt(kind.value));
    const mappedProperties = {
      ...this.MAPPED_PROPERTIES,
      channel: channelListName
    };

    // Calculate computed properties
    const computedProperties = DeviceVariableHelpers.COMPUTED_ATTRIBUTES.reduce((acc, attr) => {
      let value = DeviceVariableHelpers.getComputedValue(key, attr, configuration, valueGetter);
      if (ModbusTableHelpers.hasTank(kind.value)) {
        if (attr === 'inputVariable1') {
          value = DeviceVariableHelpers.computeSlaveModbusAddressOfTypeTank(key, configuration, valueGetter);
        } else if (attr === 'inputVariable2') {
          value = DeviceVariableHelpers.computeDataModbusAddressOfTypeTank(key, configuration, valueGetter);
        }
      } else if (ModbusTableHelpers.hasDrasticChange(kind.value)) {
        if (attr === 'drasticChangeAsset') {
          value = DeviceVariableHelpers.computeSlaveModbusAddressOfTypeTank(key, configuration, valueGetter);
        }
      }
      return {
        ...acc,
        [attr]: value
      };
    }, {});

    // Update mapped values { label, value }
    const variable = array.reduce((acc, item) => {
      let value = item.value;
      if (Object.keys(mappedProperties).includes(item.attrName)) {
        value = ModbusTableHelpers.getListItemByListName(modbusTable, get(mappedProperties, [item.attrName], 'listName'), item.value);
      }
      return {
        ...acc,
        [item.attrName]: value
      };
    }, {}) as DaliaDeviceVariable;

    // This is used as business rule to enable/disable variable configuration
    const canConfigure = DeviceVariableHelpers.canConfigureVariable({
      ...variable,
      index,
      device
    } as DaliaDeviceVariable);

    const uid = uuidv4();
    const result = {
      ...variable,
      id: uid,
      // In case the variable name is empty we generate a uid to be used later in comparators
      uid: variable.name && !!variable.name.length ? variable.name : uid,
      index,
      status,
      device,
      isCreated,
      linked: false,
      canConfigure,
      hasCleanConfiguration: true,
      ...computedProperties
    };
    return result;
  }
}
