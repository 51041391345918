import { ModbusTableHelpers } from '@iot-platform/dalia/util';
import { StringUtils } from '@iot-platform/iot-platform-utils';
import {
  DeviceConfiguration,
  DeviceConfigurationStatus,
  DeviceDetails,
  DeviceTank,
  DeviceTankState,
  ModbusListItem,
  ModbusListName,
  ModbusTable,
  ModbusTablePropertyState
} from '@iot-platform/models/dalia';
import { cloneDeep, get, uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DeviceVariableHelpers } from './device-variable.helpers';
import { DeviceHelpers } from './device.helpers';

export class DeviceTankHelpers {
  public static MAX_ALLOWED_TANK_INDEX = 10;

  /*
    *) LstTankType
    Vertical (1)
    Horizontal (2)
    Vertical for autonomy (3)
    Horizontal for autonomy (4)
    Vertical backup (5)
    Horizontal backup (6)
  */
  public static readonly IMAGE_URL_MAPPER = {
    // LstGasType: N2
    1: {
      1: 'bulk_LIN.png',
      2: 'bulk_HorizontalLIN.png',
      3: 'bulk_LIN.png',
      4: 'bulk_HorizontalLIN.png',
      5: 'bulk_LIN.png',
      6: 'bulk_HorizontalLIN.png'
    },
    // LstGasType: CO2
    2: {
      1: 'bulk_LCO2.png',
      2: 'bulk_HorizontalLCO2.png',
      3: 'bulk_LCO2.png',
      4: 'bulk_HorizontalLCO2.png',
      5: 'bulk_LCO2.png',
      6: 'bulk_HorizontalLCO2.png'
    },
    // LstGasType: O2
    3: {
      1: 'bulk_LOX.png',
      2: 'bulk_HorizontalLOX.png',
      3: 'bulk_LOX.png',
      4: 'bulk_HorizontalLOX.png',
      5: 'bulk_LOX.png',
      6: 'bulk_HorizontalLOX.png'
    },
    // LstGasType: Ar
    4: {
      1: 'bulk_LAR.png',
      2: 'bulk_HorizontalLAR.png',
      3: 'bulk_LAR.png',
      4: 'bulk_HorizontalLAR.png',
      5: 'bulk_LAR.png',
      6: 'bulk_HorizontalLAR.png'
    },
    // LstGasType: He
    5: {
      1: 'bulk_general.png',
      2: 'bulk_Horizontal.png',
      3: 'bulk_general.png',
      4: 'bulk_Horizontal.png',
      5: 'bulk_general.png',
      6: 'bulk_Horizontal.png'
    },
    // LstGasType: H2
    6: {
      1: 'bulk_LH2.png',
      2: 'bulk_HorizontalLH2.png',
      3: 'bulk_LH2.png',
      4: 'bulk_HorizontalLH2.png',
      5: 'bulk_LH2.png',
      6: 'bulk_HorizontalLH2.png'
    },
    // LstGasType: N2O
    7: {
      1: 'bulk_LN20.png',
      2: 'bulk_Horizontal.png',
      3: 'bulk_LN20.png',
      4: 'bulk_Horizontal.png',
      5: 'bulk_LN20.png',
      6: 'bulk_Horizontal.png'
    },
    // LstGasType: C2H4
    8: {
      1: 'bulk_general.png',
      2: 'bulk_Horizontal.png',
      3: 'bulk_general.png',
      4: 'bulk_Horizontal.png',
      5: 'bulk_general.png',
      6: 'bulk_Horizontal.png'
    },
    // LstGasType: C3H6
    9: {
      1: 'bulk_LC3H6.png',
      2: 'bulk_Horizontal.png',
      3: 'bulk_LC3H6.png',
      4: 'bulk_Horizontal.png',
      5: 'bulk_LC3H6.png',
      6: 'bulk_Horizontal.png'
    }
  };

  public static readonly ATTRIBUTE_MAPPER = {
    type: 'Type',
    gasType: 'Gas_type',
    serialNumber: 'Serial_number',

    maxLiquidHeight: 'Max_liquid_height',
    headHeight: 'Head_height',
    innerDiameter: 'Inner_diameter',
    maximumVolume: 'Maximum_volume',
    referencePressure: 'Reference_pressure',
    referencePressureUnit: 'Reference_pressure_unit',
    cylinderLength: 'Cylinder_length',

    variableDp: 'Index_variable_DP',
    variableDpIndex: 'Index_variable_DP',
    variableDpUnit: 'Unit_displayed_for_DP',
    variableDpDecimal: 'decimal_for_DP_display',

    variableP: 'Index_variable_P',
    variablePIndex: 'Index_variable_P',
    variablePUnit: 'Unit_displayed_for_P',
    variablePDecimal: 'decimal_for_P_display'
  };

  public static readonly CONFIGURATION_STATE_ATTRIBUTE_MAPPER = {
    generalInformation: {
      type: true,
      gasType: true,
      serialNumber: true
    },
    physicalCharacteristics: {
      maxLiquidHeight: true,
      headHeight: true,
      innerDiameter: true,
      maximumVolume: true,
      referencePressure: true,
      referencePressureUnit: true,
      cylinderLength: true
    },
    variables: {
      variableDp: true,
      variableDpUnit: true,
      variableDpDecimal: true,
      variableP: true,
      variablePUnit: true,
      variablePDecimal: true
    }
  };

  public static readonly OPTIONAL_COMPUTED_ATTRIBUTES = ['variableP'];
  public static readonly COMPUTED_ATTRIBUTES = [...DeviceTankHelpers.OPTIONAL_COMPUTED_ATTRIBUTES, 'variableDp'];

  public static readonly MAPPED_PROPERTIES = {
    type: ModbusListName.LstTankType,
    gasType: ModbusListName.LstGasType,
    referencePressureUnit: ModbusListName.LstUnitEnum,
    variableDpUnit: ModbusListName.LstUnitEnum,
    variablePUnit: ModbusListName.LstUnitEnum
  };

  // Return all tanks from device configurations
  // Exclude deleted tanks
  static getAllTankIndexes(
    configuration: DeviceConfiguration,
    skipRemovedTanks: boolean
  ): {
    key: string;
    index: number;
  }[] {
    const currentKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(configuration, 'current', {}));
    const pendingKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(configuration, 'pending', {}));
    const targetKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(configuration, 'target', {}));

    // Remove tanks to be deleted
    return uniq([...currentKeys, ...pendingKeys, ...targetKeys])
      .filter((key) => {
        // Check if the tank will be deleted
        // type === 0
        const type = get(
          configuration,
          ['pending', `${key}.${DeviceTankHelpers.ATTRIBUTE_MAPPER.type}`],
          get(
            configuration,
            ['target', `${key}.${DeviceTankHelpers.ATTRIBUTE_MAPPER.type}`],
            get(configuration, ['current', `${key}.${DeviceTankHelpers.ATTRIBUTE_MAPPER.type}`])
          )
        );
        if (skipRemovedTanks) {
          return type !== 0;
        }
        return true;
      })
      .map((key: string) => ({ key, index: DeviceHelpers.parseIndex(key) }));
  }

  public static readonly hasCleanConfiguration = (tank: DeviceTank, modbusTable: ModbusTable) => {
    const state = DeviceTankHelpers.getTankState(tank, modbusTable);
    const hasRemovedAttribute = () =>
      Object.entries(state).some(([k1]) => {
        const attributes = get(state, [k1, 'attributes'], {});
        return Object.entries(attributes).some(([_, v2]) => get(v2, ['status']) === DeviceConfigurationStatus.DELETED);
      }, false);
    return !hasRemovedAttribute();
  };

  // Generate processed dalia tank list
  public static readonly getTanks = (device: DeviceDetails, modbusTable: ModbusTable): DeviceTank[] => {
    const currentKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(device, ['configuration', 'current'], {}));
    const pendingKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(device, ['configuration', 'pending'], {}));
    const targetKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(device, ['configuration', 'target'], {}));

    // Created new tanks does not exist in current configuration, they exist in pending configuration instead
    const createdNewTankKeys = pendingKeys.filter((k) => !currentKeys.includes(k));
    // Updated tanks exist in both of current and pending configurations
    const updatedTankKeys = currentKeys.filter((k) => pendingKeys.includes(k));
    // Unmodified tanks should not exist in created and updated lists
    const currentTankKeys = currentKeys.filter((k) => !createdNewTankKeys.includes(k) && !updatedTankKeys.includes(k));

    const currentTanks = DeviceTankHelpers.getTanksFromConfiguration(
      currentTankKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const updatedTanks = DeviceTankHelpers.getTanksFromConfiguration(
      updatedTankKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const createdTanks = DeviceTankHelpers.getTanksFromConfiguration(
      createdNewTankKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['pending', key], // should retrieve properties from pending configuration for created tanks
      true
    );

    const items = [...currentTanks, ...updatedTanks, ...createdTanks]
      // Should filter on tank.status !== DeviceConfigurationStatus.CURRENT to keep removed items in the list
      .filter((tank) => tank.status !== DeviceConfigurationStatus.CURRENT || tank?.type?.value !== 0)
      .map((v) => ({
        ...v,
        // This is used to identify if there is a deleted variable or tank
        hasCleanConfiguration: DeviceTankHelpers.hasCleanConfiguration(v, modbusTable)
      }))
      .sort((a, b) => a.index - b.index);
    return items.slice(0, DeviceTankHelpers.MAX_ALLOWED_TANK_INDEX);
  };

  public static readonly getAvailableNextIndex = (configuration: DeviceConfiguration): number => {
    const indexes = DeviceTankHelpers.getAllTankIndexes(configuration, false).map((item) => item.index);
    return DeviceHelpers.getAvailableNextIndex(indexes, DeviceTankHelpers.MAX_ALLOWED_TANK_INDEX);
  };

  public static readonly getAvailableIndexes = (configuration: DeviceConfiguration, currentIndex?: number): ModbusListItem[] => {
    const indexes = DeviceTankHelpers.getAllTankIndexes(configuration, false).map((item) => item.index);
    return DeviceHelpers.getAvailableIndexes(indexes, DeviceTankHelpers.MAX_ALLOWED_TANK_INDEX, currentIndex);
  };

  public static readonly getTankKeysFromConfig = (configuration: { [key: string]: unknown }): string[] => {
    const keys = Object.keys(configuration)
      .filter((key: string) => key.match(/(^Tank\d+(\.)*)./gi))
      .map((key) => key.split('.')[0]);
    return uniq(keys);
  };

  public static readonly getTanksFromConfiguration = (
    keys: string[],
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ): DeviceTank[] =>
    uniq(keys).reduce((acc, key) => {
      const tank = DeviceTankHelpers.getTankInstance(key, device, modbusTable, pendingKeys, targetKeys, valueGetter, isCreated);
      return [...acc, tank];
    }, []);

  // Return configuration state by attribute name
  public static readonly getPropertyState = (attrName: string, tank: DeviceTank, modbusTable: ModbusTable): ModbusTablePropertyState => {
    const key = DeviceTankHelpers.getTankIndexKey(tank);
    let status = DeviceConfigurationStatus.CURRENT;
    const attrPath = `${key}.${DeviceTankHelpers.ATTRIBUTE_MAPPER[attrName]}`;
    let newCollectionName = 'pending';
    if (tank?.status === DeviceConfigurationStatus.PUBLISHED) {
      newCollectionName = 'target';
    }

    const mappedProperties = {
      ...this.MAPPED_PROPERTIES
    };

    const valueGetter = (k: string) => (tank?.isCreated ? [newCollectionName, k] : ['current', k, 'v']);

    const configuration = get(tank, ['device', 'configuration'], {}) as DeviceConfiguration;

    let oldValue = get(configuration, valueGetter(attrPath), null);
    const newValue = get(configuration, [newCollectionName, attrPath], null);

    if (DeviceTankHelpers.COMPUTED_ATTRIBUTES.includes(attrName)) {
      // In case computed values are impacted by removed variables
      // We change their status to DELETED
      // oldValue === Old index
      // newValue === new index

      const currentIndexes = DeviceVariableHelpers.getAllVariableNames(configuration, true).map((item) => item.index);

      if (oldValue === 0 && newValue === null) {
        oldValue = null;
      }

      const noOldValueAndHasNewValue = oldValue === null && newValue !== null;
      const noNewValueAndHasOldValue = newValue === null && oldValue !== null;
      const hasBoth = oldValue !== null && newValue !== null;

      const noOldIndexAndNewIndexRemoved = noOldValueAndHasNewValue && !currentIndexes.includes(newValue);
      const hasBothAndNewIndexRemoved = hasBoth && !currentIndexes.includes(newValue);
      const noNewIndexAndOldIndexRemoved = noNewValueAndHasOldValue && !currentIndexes.includes(oldValue);

      // Optional variables could be unused
      // In this case variable index will be 0
      const isVariablePUnused = DeviceTankHelpers.OPTIONAL_COMPUTED_ATTRIBUTES.includes(attrName) && ((oldValue === 0 && newValue === null) || newValue === 0);

      // Unused variables should not be considered as removed
      if (!isVariablePUnused && (noOldIndexAndNewIndexRemoved || noNewIndexAndOldIndexRemoved || hasBothAndNewIndexRemoved)) {
        status = DeviceConfigurationStatus.DELETED;
      }

      // Set old computed value
      oldValue = DeviceTankHelpers.getComputedValue(key, attrName, configuration, valueGetter);
    }

    if (status !== DeviceConfigurationStatus.DELETED && newValue !== null && (tank?.isCreated || oldValue !== newValue)) {
      status = tank.status;
    }

    let displayValue = oldValue;
    if (Object.keys(mappedProperties).includes(attrName)) {
      displayValue = ModbusTableHelpers.getListItemByListName(modbusTable, get(mappedProperties, [attrName], 'listName'), oldValue)?.label;
    }

    return {
      oldValue,
      newValue,
      displayValue,
      status,
      attrName,
      key: attrPath
    };
  };

  public static readonly getComputedValue = (key: string, attrName: string, configuration: DeviceConfiguration, valueGetter: (k: string) => string[]) => {
    const value = get(configuration, valueGetter(`${key}.${DeviceTankHelpers.ATTRIBUTE_MAPPER[attrName]}`));
    const computedKey = `Var${StringUtils.padWith(value, 2, '0')}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.name}`;
    // In case computed value found and empty return initial value
    // Otherwise return calculated value
    const result = get(configuration, valueGetter(computedKey));
    if (result !== undefined) {
      if (result === '') {
        return value;
      }
      return result;
    }
    // In case computed value is not found
    // Look for related index in all configuration
    return get(configuration, ['target', computedKey], get(configuration, ['pending', computedKey], get(configuration, ['current', computedKey, 'v'], value)));
  };

  public static getTankState = (tank: DeviceTank, modbusTable: ModbusTable): DeviceTankState => {
    const status = get(tank, 'status', DeviceConfigurationStatus.CURRENT);
    return Object.keys(DeviceTankHelpers.CONFIGURATION_STATE_ATTRIBUTE_MAPPER).reduce(
      (acc, key) => {
        const attributes = Object.keys(DeviceTankHelpers.CONFIGURATION_STATE_ATTRIBUTE_MAPPER[key]);
        const obj = attributes.reduce(
          (acc1, attr) => ({
            ...acc1,
            [attr]: DeviceTankHelpers.getPropertyState(attr, tank, modbusTable)
          }),
          {}
        );
        const isPendingOrPublished = attributes.some(
          (attr) => obj[attr].status === DeviceConfigurationStatus.PENDING || obj[attr].status === DeviceConfigurationStatus.PUBLISHED
        );
        return {
          ...acc,
          [key]: {
            status: isPendingOrPublished ? status : DeviceConfigurationStatus.CURRENT,
            attributes: obj
          }
        };
      },
      {
        status
      } as DeviceTankState
    );
  };

  // Return tank prefix key by index
  public static readonly getTankIndexKey = (tank: DeviceTank): string => `Tank${StringUtils.padWith(tank?.index, 2, '0')}`;

  // Return a new configuration copy without properties that match tank index
  public static readonly removePropertiesFrom = (
    tank: DeviceTank,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(tank, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get tank key
    const key = DeviceTankHelpers.getTankIndexKey(tank);
    return DeviceHelpers.reduceConfiguration(configuration, key, targetConfiguration, false);
  };

  // Return all configuration properties that match tank index
  public static readonly getPropertiesFrom = (
    tank: DeviceTank,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(tank, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get tank key
    const key = DeviceTankHelpers.getTankIndexKey(tank);
    return DeviceHelpers.reduceConfiguration(configuration, key, targetConfiguration, true);
  };

  // Tank that does not have an index or it's type is 0 are not configurable
  public static readonly canConfigureTank = (tank: DeviceTank) =>
    tank.index !== null && tank.index !== undefined && tank.type?.value !== 0 && !Object.keys(get(tank.device, ['configuration', 'target'], {})).length;

  public static readonly deleteConfigurationFrom = (tank: DeviceTank, targetConfiguration: 'current' | 'pending' | 'target') => {
    const configuration = get(tank, ['device', 'configuration'], {});
    const currentKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(configuration, 'current', {}));
    const indexKey = DeviceTankHelpers.getTankIndexKey(tank);
    if (uniq(currentKeys).includes(indexKey)) {
      // Set type to 0
      return {
        ...get(configuration, [targetConfiguration], {}),
        [`${indexKey}.${DeviceTankHelpers.ATTRIBUTE_MAPPER.type}`]: 0
      };
    }
    return DeviceTankHelpers.removePropertiesFrom(tank, targetConfiguration);
  };

  public static hasCylinder = (type: number) => [2, 4, 6].includes(type);

  public static readonly cleanUpConfiguration = (
    indexKey: string,
    deviceConfiguration: DeviceConfiguration,
    configuration: { [key: string]: unknown },
    targetConfiguration: 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Override pending configuration
    const config = {
      ...get(deviceConfiguration, [targetConfiguration], {}),
      ...configuration
    };

    const type = get(configuration, [`${indexKey}.${DeviceTankHelpers.ATTRIBUTE_MAPPER.type}`]) as number;
    const hasCylinder = DeviceTankHelpers.hasCylinder(type);
    const cylinderKey = `${indexKey}.${DeviceTankHelpers.ATTRIBUTE_MAPPER.cylinderLength}`;

    // Clean up target configuration
    return Object.keys(config).reduce((acc, key) => {
      const currentValue = get(deviceConfiguration, ['current', key, 'v']);
      if (!(config[key] === currentValue)) {
        // Set cylinder value to 0 nin case tank does not allow cylinder
        if (key === cylinderKey && !hasCylinder) {
          acc[key] = undefined;
        } else {
          acc[key] = config[key];
        }
      }
      return { ...acc };
    }, {});
  };

  public static readonly getImageUrlByTank = (tank: DeviceTank) => {
    const imageUrl = get(DeviceTankHelpers.IMAGE_URL_MAPPER, [tank?.gasType?.value, tank?.type?.value]);
    return imageUrl ? `assets/images/asset/${imageUrl}` : null;
  };

  public static readonly hasVariableP = (tank: DeviceTank): boolean =>
    tank?.variablePIndex !== undefined && tank?.variablePIndex !== null && tank?.variablePIndex !== 0;

  // Generate tank model instance
  private static getTankInstance(
    key: string,
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ): DeviceTank {
    const status = DeviceHelpers.getStatusByKey(key, device, pendingKeys, targetKeys);
    const index = DeviceHelpers.parseIndex(key);

    // Compute and merge configuration objects
    const configuration = cloneDeep(get(device, 'configuration'));
    if (status === DeviceConfigurationStatus.PENDING) {
      configuration.current = DeviceHelpers.mergeConfigValues(device, 'pending');
    } else if (status === DeviceConfigurationStatus.PUBLISHED) {
      configuration.current = DeviceHelpers.mergeConfigValues(device, 'target');
    }

    const keys = Object.keys(DeviceTankHelpers.ATTRIBUTE_MAPPER);

    // Get key values list
    const array = keys.map((attrName) => {
      const val = get(configuration, valueGetter(`${key}.${DeviceTankHelpers.ATTRIBUTE_MAPPER[attrName]}`));
      return {
        attrName,
        value: val
      };
    });

    // Calculate computed properties
    const computedProperties = DeviceTankHelpers.COMPUTED_ATTRIBUTES.reduce((acc, attr) => {
      const value = DeviceTankHelpers.getComputedValue(key, attr, configuration, valueGetter);
      return {
        ...acc,
        [attr]: value
      };
    }, {});

    // Update mapped values { label, value }
    const tank = array.reduce((acc, item) => {
      let value = item.value;
      if (Object.keys(DeviceTankHelpers.MAPPED_PROPERTIES).includes(item.attrName)) {
        value = ModbusTableHelpers.getListItemByListName(modbusTable, get(DeviceTankHelpers.MAPPED_PROPERTIES, [item.attrName], 'listName'), item.value);
      }
      return {
        ...acc,
        [item.attrName]: value
      };
    }, {}) as DeviceTank;

    // This is used as business rule to enable/disable tank configuration
    const canConfigure = DeviceTankHelpers.canConfigureTank({
      ...tank,
      index,
      device
    } as DeviceTank);
    const imageUrl = DeviceTankHelpers.getImageUrlByTank(tank);
    const id = uuidv4();
    return {
      ...tank,
      id,
      index,
      status,
      device,
      isCreated,
      canConfigure,
      hasCleanConfiguration: true,
      imageUrl,
      ...computedProperties
    };
  }
}
