import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceTank } from '@iot-platform/models/dalia';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import * as fromDeviceTanks from '../reducers/device-tanks.reducer';

@Injectable({
  providedIn: 'root'
})
export class DeviceTanksSelectors extends ApiFeatureSelector<fromDeviceTanks.State, DeviceTank, Pagination, Filter> {
  selectCurrentTankIndex: MemoizedSelector<fromDeviceTanks.State, number> = createSelector(
    this.selectState,
    (state: fromDeviceTanks.State): number => state.currentTankIndex
  );

  constructor() {
    super(fromDeviceTanks.deviceTanksFeature.selectDeviceTanksState, fromDeviceTanks.adapter);
  }
}
