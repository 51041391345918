import { DeviceModbusRequestTable } from '@iot-platform/models/dalia';
import { createActionGroup, props } from '@ngrx/store';

export const DeviceModbusRequestTablesActions = createActionGroup({
  source: 'Device Device Modbus Request Tables',
  events: {
    'Set Dalia Device Modbus Request Tables': props<{
      deviceModbusRequestTables: DeviceModbusRequestTable[];
    }>(),
    'Set Current Device Modbus Request Table': props<{
      deviceModbusRequestTable: DeviceModbusRequestTable;
    }>(),
    'Set Current Device Modbus Request Table Index': props<{
      index: number;
    }>()
  }
});
