import { TagCategory } from '@iot-platform/models/common';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TagCategoriesActions = createActionGroup({
  source: 'Tag Categories',
  events: {
    'Load Tag Categories': emptyProps(),
    'Load Tag Categories Success': props<{
      response: TagCategory[];
    }>(),
    'Load Tag Categories Failure': props<{
      error: unknown;
    }>()
  }
});
