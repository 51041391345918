import { RowClassRules } from '@ag-grid-community/core';
import { Filter } from '../../../common';
import { ExportParams } from '../../export-params';
import { I4BButtonColumn, I4BSelectionColumn } from '../definitions';
import { I4BGridSort } from './i4b-grid.sort';

export interface I4BGridOptions {
  renderer?: string;
  autoRefresh: {
    enabled: boolean;
    delay?: number;
  };
  pageSize: number;
  multipleSelection: boolean;
  filters?: Filter[];
  variableNames?: string[];
  tagIds?: string[];
  exportParams?: ExportParams;
  buttonColumn?: { enabled: boolean; className?: I4BButtonColumn };
  selectionColumn?: { enabled: boolean; className?: I4BSelectionColumn };
  gridSort?: I4BGridSort[];
  endPoint?: string;
  rowClassRules?: RowClassRules;
}
