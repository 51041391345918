import { Injectable } from '@angular/core';
import { DeviceHelpers } from '@iot-platform/dalia/util/devices';
import { ApiFeatureSelector, Duration, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceConfigurationStatus, DeviceConfigurationTargetStatus, DeviceDetails } from '@iot-platform/models/dalia';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import * as fromDevices from '../reducers/devices.reducer';

@Injectable({
  providedIn: 'root'
})
export class DevicesSelectors extends ApiFeatureSelector<fromDevices.State, DeviceDetails, Pagination, Filter> {
  selectTotal: MemoizedSelector<fromDevices.State, number> = createSelector(this.selectState, (state: fromDevices.State): number => state.pagination.total);

  selectConfigurationStatus: MemoizedSelector<fromDevices.State, DeviceConfigurationStatus> = createSelector(this.selectState, (state: fromDevices.State) =>
    DeviceHelpers.getConfigurationStatus(state.entity)
  );
  selectConfigurationTargetStatus: MemoizedSelector<fromDevices.State, DeviceConfigurationTargetStatus> = createSelector(
    this.selectState,
    (state: fromDevices.State) => DeviceHelpers.getConfigurationTargetStatus(state.entity)
  );
  selectDeactivateActions: MemoizedSelector<fromDevices.State, boolean> = createSelector(
    this.selectState,
    this.selectConfigurationTargetStatus,
    (state: fromDevices.State, status: DeviceConfigurationTargetStatus) => state.loading || status === DeviceConfigurationTargetStatus.DOWNLOADED
  );
  selectLastCallDuration: MemoizedSelector<fromDevices.State, Duration> = createSelector(
    this.selectState,
    (state: fromDevices.State) => state.lastCallDuration
  );
  selectLastCall: MemoizedSelector<fromDevices.State, number> = createSelector(this.selectLastCallDuration, (duration: Duration) => {
    if (!duration) {
      return 0;
    }
    return duration.seconds + duration.minutes * 60 + duration.hours * 60 * 60 + duration.days * 24 * 60 * 60;
  });

  selectLastCallStatusRefreshActivated: MemoizedSelector<fromDevices.State, boolean> = createSelector(
    this.selectState,
    (state: fromDevices.State): boolean => state.lastCallStatusRefreshActivated
  );

  selectCanSendRefreshCommand: MemoizedSelector<fromDevices.State, boolean> = createSelector(this.selectCurrentEntity, (device: DeviceDetails): boolean =>
    DeviceHelpers.canSendRefreshCommand(device)
  );

  constructor() {
    super(fromDevices.devicesFeature.selectDevicesState, fromDevices.adapter);
  }
}
