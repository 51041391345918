<div class="download-card" fxLayout="row" fxLayoutAlign="center center">
  <div class="download-card_box" fxFlex="40%" fxFlex.lg="50%" fxFlex.md="70%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayout="column" fxLayoutAlign="center center">
    <mat-icon class="download-card_image">{{ isVisible() ? 'file_download' : 'search' }}</mat-icon>
    @if (loading()) {
      <mat-progress-spinner mode="indeterminate" [diameter]="20" color="accent"></mat-progress-spinner>
    } @else if (isVisible()) {
      <button mat-button (click)="download.emit()">{{ buttonText() | translate }}</button>
    } @else {
      <label class="download-card_label">
        <span>{{ (isVisible() ? 'REPORTS.EXPORT_DATA' : cardText()) | translate }}</span>
      </label>
    }
  </div>
</div>
