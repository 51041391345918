import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';

export class FormsHelpers {
  static isFormValid(form: UntypedFormGroup): boolean {
    form.markAllAsTouched();
    return Object.values(form.controls).every((f: AbstractControl | FormGroup) => {
      if ((f as FormGroup).controls) {
        return FormsHelpers.isFormValid(f as FormGroup);
      }
      const disabled = f.disabled;
      if (disabled) {
        f.enable();
      }
      f.markAsTouched();
      const valid = f.valid;
      if (disabled) {
        f.disable();
      }
      return valid;
    });
  }
}
