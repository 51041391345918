import { inject } from '@angular/core';
import { AuthFacade } from '@iot-platform/auth';
import { DeviceTemplateHelpers, NavigationsService } from '@iot-platform/dalia/util/devices';
import { DeviceTemplate, NavigationRoutes } from '@iot-platform/models/dalia';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action } from '@ngrx/store';
import { concatMap, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DeviceTemplatesService } from '../../services/device-templates.service';
import { DeviceTanksActions, DeviceTemplatesActions, DeviceVariablesActions } from '../actions';
import { DeviceTemplatesFacade } from '../facades/device-templates.facade';

const loadTemplates$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceTemplatesService = inject(DeviceTemplatesService), authFacade = inject(AuthFacade)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.loadTemplates),
      switchMap(({ request }) =>
        deviceTemplatesService.getAll(request).pipe(
          concatLatestFrom(() => authFacade.selectedEntityForSession$),
          map(([response, entity]) =>
            DeviceTemplatesActions.loadTemplatesSuccess({
              response: {
                ...response,
                data: response.data.map((item: DeviceTemplate) => DeviceTemplateHelpers.getTemplateInstance(item, entity))
              }
            })
          ),
          catchError((error) => of(DeviceTemplatesActions.loadTemplatesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadTemplateById$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceTemplatesService = inject(DeviceTemplatesService), authFacade = inject(AuthFacade)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.loadTemplateById),
      switchMap(({ id }) =>
        deviceTemplatesService.getOneById(id).pipe(
          concatLatestFrom(() => authFacade.selectedEntityForSession$),
          map(([response, entity]) =>
            DeviceTemplatesActions.loadTemplateByIdSuccess({ response: DeviceTemplateHelpers.getTemplateInstance(response, entity) })
          ),
          catchError((error) => of(DeviceTemplatesActions.loadTemplateByIdFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const refreshTemplate$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.editTemplateSuccess),
      map(({ response }) => DeviceTemplatesActions.loadTemplateById({ id: response.id }))
    ),
  { functional: true }
);

const createTemplate$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceTemplatesService = inject(DeviceTemplatesService)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.createTemplate),
      concatMap(({ template }) =>
        deviceTemplatesService.addOne(template).pipe(
          map((response: DeviceTemplate) => DeviceTemplatesActions.createTemplateSuccess({ response })),
          catchError((error) => of(DeviceTemplatesActions.createTemplateFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const createTemplateSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceTemplatesFacade = inject(DeviceTemplatesFacade), navigationsService = inject(NavigationsService)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.createTemplateSuccess),
      tap(({ response }) => {
        deviceTemplatesFacade.addDeviceTemplateToCurrentGrid(response);
        navigationsService.gotToDeviceTemplateOverviewPage(response, NavigationRoutes.DEVICE_TEMPLATES);
      }),
      concatMap(({ response }) => [
        DeviceTanksActions.setDaliaTanksList({ tanks: [] }),
        DeviceVariablesActions.loadDeviceVariablesSuccess({ response: [] }),
        DeviceTemplatesActions.loadTemplateById({ id: response.id })
      ])
    ),
  { functional: true }
);

const editTemplate$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceTemplatesService = inject(DeviceTemplatesService)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.editTemplate),
      concatMap(({ template }) =>
        deviceTemplatesService.updateOne(template).pipe(
          map((response: DeviceTemplate) => DeviceTemplatesActions.editTemplateSuccess({ response })),
          catchError((error) => of(DeviceTemplatesActions.editTemplateFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const editTemplateSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceTemplatesFacade = inject(DeviceTemplatesFacade)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.editTemplateSuccess),
      tap(({ response }) => deviceTemplatesFacade.updateDeviceTemplateInCurrentGrid(response))
    ),
  { functional: true, dispatch: false }
);

const deleteTemplate$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceTemplatesService = inject(DeviceTemplatesService)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.deleteTemplate),
      concatMap(({ template }) =>
        deviceTemplatesService.deleteOne(template).pipe(
          map(() => DeviceTemplatesActions.deleteTemplateSuccess({ response: template })),
          catchError((error) => of(DeviceTemplatesActions.deleteTemplateFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteTemplateSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceTemplatesFacade = inject(DeviceTemplatesFacade), navigationsService = inject(NavigationsService)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.deleteTemplateSuccess),
      tap(({ response }) => {
        navigationsService.returnToOrigin();
        deviceTemplatesFacade.removeDeviceTemplateFromCurrentGrid(response);
      })
    ),
  { functional: true, dispatch: false }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceTemplatesActions.loadTemplates,
        DeviceTemplatesActions.loadTemplateById,
        DeviceTemplatesActions.deleteTemplate,
        DeviceTemplatesActions.createTemplate,
        DeviceTemplatesActions.editTemplate
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceTemplatesActions.loadTemplatesSuccess,
        DeviceTemplatesActions.loadTemplatesFailure,
        DeviceTemplatesActions.loadTemplateByIdSuccess,
        DeviceTemplatesActions.loadTemplateByIdFailure,
        DeviceTemplatesActions.deleteTemplateSuccess,
        DeviceTemplatesActions.deleteTemplateFailure,
        DeviceTemplatesActions.createTemplateSuccess,
        DeviceTemplatesActions.createTemplateFailure,
        DeviceTemplatesActions.editTemplateSuccess,
        DeviceTemplatesActions.editTemplateFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceTemplatesActions.loadTemplatesFailure,
        DeviceTemplatesActions.loadTemplateByIdFailure,
        DeviceTemplatesActions.deleteTemplateFailure,
        DeviceTemplatesActions.createTemplateFailure,
        DeviceTemplatesActions.editTemplateFailure
      ),
      tap((action: Action) => notificationService.displayError(action.type))
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.deleteTemplateSuccess, DeviceTemplatesActions.createTemplateSuccess, DeviceTemplatesActions.editTemplateSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

export const DeviceTemplatesEffects = {
  loadTemplates$,
  loadTemplateById$,
  showLoader$,
  hideLoader$,
  displayError$,
  createTemplate$,
  editTemplate$,
  deleteTemplate$,
  displaySuccess$,
  createTemplateSuccess$,
  editTemplateSuccess$,
  deleteTemplateSuccess$,
  refreshTemplate$
};
