import { ModbusTableHelpers } from '@iot-platform/dalia/util';
import { StringUtils } from '@iot-platform/iot-platform-utils';
import {
  DeviceConfiguration,
  DeviceConfigurationStatus,
  DeviceDetails,
  DeviceModbusRequestTable,
  DeviceModbusRequestTableState,
  ModbusListItem,
  ModbusListName,
  ModbusTable,
  ModbusTablePropertyState
} from '@iot-platform/models/dalia';
import { cloneDeep, get, uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DeviceVariableHelpers } from './device-variable.helpers';
import { DeviceHelpers } from './device.helpers';

export class DeviceModbusRequestTableHelpers {
  public static MAX_ALLOWED_INDEX = 5;

  public static readonly ATTRIBUTE_MAPPER = {
    channel: 'Channel',
    modbusSlaveAddress: 'Slave_address',
    modbusDataAddress: 'Data_address',
    modbusFunction: 'Code_function',
    modbusType: 'Modbus_type',
    warmupTime: 'Warm_up_time',
    baudrate: 'Baudrate',
    parity: 'Parity',
    stopBits: 'Stop_bits',
    modbusDataValue: 'Data_value',
    requestExecutionStatus: 'Request_execution_status',
    timeStamp: 'Timestamp',
    resultCode: 'Result_code'
  };

  public static readonly CONFIGURATION_STATE_ATTRIBUTE_MAPPER = {
    modbusParameters: {
      channel: true,
      modbusSlaveAddress: true,
      modbusDataAddress: true,
      modbusFunction: true,
      modbusType: true
    },
    connectionParameters: {
      warmupTime: true,
      baudrate: true,
      parity: true,
      stopBits: true
    },
    requestValue: {
      modbusDataValue: true,
      requestExecutionStatus: true,
      timeStamp: true,
      resultCode: true
    }
  };

  public static OPTIONAL_COMPUTED_ATTRIBUTES = [];
  public static COMPUTED_ATTRIBUTES = [];

  public static readonly MAPPED_PROPERTIES = {
    channel: ModbusListName.LstChannelSensor,
    modbusFunction: ModbusListName.LstFctMB,
    modbusType: ModbusListName.LstMbType,
    parity: ModbusListName.LstParity,
    requestExecutionStatus: ModbusListName.LstMbReqStatus,
    stopBits: ModbusListName.LstMbStopbits,
    resultCode: ModbusListName.LstModbusError
  };

  // Return all MRTs from device configurations
  // Exclude deleted MRTs
  static getAllIndexes(configuration: DeviceConfiguration): {
    key: string;
    index: number;
  }[] {
    const currentKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(configuration, 'current', {}));
    const pendingKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(configuration, 'pending', {}));
    const targetKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(configuration, 'target', {}));

    // Remove MRTs to be deleted
    return uniq([...currentKeys, ...pendingKeys, ...targetKeys]).map((key: string) => ({
      key,
      index: DeviceHelpers.parseIndex(key)
    }));
  }

  public static readonly hasCleanConfiguration = (deviceModbusRequestTable: DeviceModbusRequestTable, modbusTable: ModbusTable) => {
    const state = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableState(deviceModbusRequestTable, modbusTable);
    const hasRemovedAttribute = () =>
      Object.entries(state).some(([k1]) => {
        const attributes = get(state, [k1, 'attributes'], {});
        return Object.entries(attributes).some(([_, v2]) => get(v2, ['status']) === DeviceConfigurationStatus.DELETED);
      }, false);
    return !hasRemovedAttribute();
  };

  // Generate processed dalia device modbus request table list
  public static readonly getDeviceModbusRequestTables = (device: DeviceDetails, modbusTable: ModbusTable): DeviceModbusRequestTable[] => {
    const currentKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(device, ['configuration', 'current'], {}));
    const pendingKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(device, ['configuration', 'pending'], {}));
    const targetKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(device, ['configuration', 'target'], {}));

    // Created new MRTs does not exist in current configuration, they exist in pending configuration instead
    const createdNewMRTKeys = pendingKeys.filter((k) => !currentKeys.includes(k));
    // Updated MRTs exist in both of current and pending configurations
    const updatedMRTKeys = currentKeys.filter((k) => pendingKeys.includes(k));
    // Unmodified MRTs should not exist in created and updated lists
    const currentMRTKeys = currentKeys.filter((k) => !createdNewMRTKeys.includes(k) && !updatedMRTKeys.includes(k));

    const currentMRTs = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableFromConfiguration(
      currentMRTKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const updatedMRTs = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableFromConfiguration(
      updatedMRTKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const createdMRTs = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableFromConfiguration(
      createdNewMRTKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['pending', key], // should retrieve properties from pending configuration for created MRTs
      true
    );

    const items = [...currentMRTs, ...updatedMRTs, ...createdMRTs]
      .map((v) => ({
        ...v,
        // This is used to identify if there is a deleted variable or MRT
        hasCleanConfiguration: DeviceModbusRequestTableHelpers.hasCleanConfiguration(v, modbusTable)
      }))
      .sort((a, b) => a.index - b.index);

    return items.slice(0, DeviceModbusRequestTableHelpers.MAX_ALLOWED_INDEX);
  };

  public static readonly getAvailableNextIndex = (configuration: DeviceConfiguration): number => {
    const indexes = DeviceModbusRequestTableHelpers.getAllIndexes(configuration).map((item) => item.index);
    return DeviceHelpers.getAvailableNextIndex(indexes, DeviceModbusRequestTableHelpers.MAX_ALLOWED_INDEX);
  };

  public static readonly getAvailableIndexes = (configuration: DeviceConfiguration, currentIndex?: number): ModbusListItem[] => {
    const indexes = DeviceModbusRequestTableHelpers.getAllIndexes(configuration).map((item) => item.index);
    return DeviceHelpers.getAvailableIndexes(indexes, DeviceModbusRequestTableHelpers.MAX_ALLOWED_INDEX, currentIndex);
  };

  public static readonly getDeviceModbusRequestTableKeysFromConfig = (configuration: { [key: string]: unknown }): string[] => {
    const keys = Object.keys(configuration)
      .filter((key: string) => key.match(/(^MRT\d+(\.)*)./gi))
      .map((key) => key.split('.')[0]);
    return uniq(keys);
  };

  public static readonly getDeviceModbusRequestTableFromConfiguration = (
    keys: string[],
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ): DeviceModbusRequestTable[] =>
    uniq(keys).reduce((acc, key) => {
      const deviceModbusRequestTable = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableInstance(
        key,
        device,
        modbusTable,
        pendingKeys,
        targetKeys,
        valueGetter,
        isCreated
      );
      return [...acc, deviceModbusRequestTable];
    }, []);

  // Return configuration state by attribute name
  public static readonly getPropertyState = (
    attrName: string,
    deviceModbusRequestTable: DeviceModbusRequestTable,
    modbusTable: ModbusTable
  ): ModbusTablePropertyState => {
    const key = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableIndexKey(deviceModbusRequestTable);
    let status = DeviceConfigurationStatus.CURRENT;
    const attrPath = `${key}.${DeviceModbusRequestTableHelpers.ATTRIBUTE_MAPPER[attrName]}`;
    let newCollectionName = 'pending';
    if (deviceModbusRequestTable?.status === DeviceConfigurationStatus.PUBLISHED) {
      newCollectionName = 'target';
    }

    const mappedProperties = {
      ...this.MAPPED_PROPERTIES
    };

    const valueGetter = (k: string) => (deviceModbusRequestTable?.isCreated ? [newCollectionName, k] : ['current', k, 'v']);

    const configuration = get(deviceModbusRequestTable, ['device', 'configuration'], {}) as DeviceConfiguration;

    let oldValue = get(configuration, valueGetter(attrPath), null);
    const newValue = get(configuration, [newCollectionName, attrPath], null);

    if (DeviceModbusRequestTableHelpers.COMPUTED_ATTRIBUTES.includes(attrName)) {
      // In case computed values are impacted by removed variables
      // We change their status to DELETED
      // oldValue === Old index
      // newValue === new index

      const currentIndexes = DeviceVariableHelpers.getAllVariableNames(configuration, true).map((item) => item.index);

      if (oldValue === 0 && newValue === null) {
        oldValue = null;
      }

      const noOldValueAndHasNewValue = oldValue === null && newValue !== null;
      const noNewValueAndHasOldValue = newValue === null && oldValue !== null;
      const hasBoth = oldValue !== null && newValue !== null;

      const noOldIndexAndNewIndexRemoved = noOldValueAndHasNewValue && !currentIndexes.includes(newValue);
      const hasBothAndNewIndexRemoved = hasBoth && !currentIndexes.includes(newValue);
      const noNewIndexAndOldIndexRemoved = noNewValueAndHasOldValue && !currentIndexes.includes(oldValue);

      // Optional variables could be unused
      // In this case variable index will be 0
      const isVariablePUnused =
        DeviceModbusRequestTableHelpers.OPTIONAL_COMPUTED_ATTRIBUTES.includes(attrName) && ((oldValue === 0 && newValue === null) || newValue === 0);

      // Unused variables should not be considered as removed
      if (!isVariablePUnused && (noOldIndexAndNewIndexRemoved || noNewIndexAndOldIndexRemoved || hasBothAndNewIndexRemoved)) {
        status = DeviceConfigurationStatus.DELETED;
      }

      // Set old computed value
      oldValue = DeviceModbusRequestTableHelpers.getComputedValue(key, attrName, configuration, valueGetter);
    }

    if (status !== DeviceConfigurationStatus.DELETED && newValue !== null && (deviceModbusRequestTable?.isCreated || oldValue !== newValue)) {
      status = deviceModbusRequestTable.status;
    }

    let displayValue = oldValue;
    if (Object.keys(mappedProperties).includes(attrName)) {
      displayValue = ModbusTableHelpers.getListItemByListName(modbusTable, get(mappedProperties, [attrName], 'listName'), oldValue)?.label;
    }

    if (attrName === 'baudrate') {
      displayValue = DeviceModbusRequestTableHelpers.getBaudrateByValue(key, configuration, valueGetter)?.label;
    }

    return {
      oldValue,
      newValue,
      displayValue,
      status,
      attrName,
      key: attrPath
    };
  };

  public static readonly getComputedValue = (key: string, attrName: string, configuration: DeviceConfiguration, valueGetter: (k: string) => string[]) => {
    const value = get(configuration, valueGetter(`${key}.${DeviceModbusRequestTableHelpers.ATTRIBUTE_MAPPER[attrName]}`));
    const computedKey = `Var${StringUtils.padWith(value, 2, '0')}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.name}`;
    // In case computed value found and empty return initial value
    // Otherwise return calculated value
    const result = get(configuration, valueGetter(computedKey));
    if (result !== undefined) {
      if (result === '') {
        return value;
      }
      return result;
    }
    // In case computed value is not found
    // Look for related index in all configuration
    return get(configuration, ['target', computedKey], get(configuration, ['pending', computedKey], get(configuration, ['current', computedKey, 'v'], value)));
  };

  public static getDeviceModbusRequestTableState = (
    deviceModbusRequestTable: DeviceModbusRequestTable,
    modbusTable: ModbusTable
  ): DeviceModbusRequestTableState => {
    const status = get(deviceModbusRequestTable, 'status', DeviceConfigurationStatus.CURRENT);
    return Object.keys(DeviceModbusRequestTableHelpers.CONFIGURATION_STATE_ATTRIBUTE_MAPPER).reduce(
      (acc, key) => {
        const attributes = Object.keys(DeviceModbusRequestTableHelpers.CONFIGURATION_STATE_ATTRIBUTE_MAPPER[key]);
        const obj = attributes.reduce(
          (acc1, attr) => ({
            ...acc1,
            [attr]: DeviceModbusRequestTableHelpers.getPropertyState(attr, deviceModbusRequestTable, modbusTable)
          }),
          {}
        );
        const isPendingOrPublished = attributes.some(
          (attr) => obj[attr].status === DeviceConfigurationStatus.PENDING || obj[attr].status === DeviceConfigurationStatus.PUBLISHED
        );
        return {
          ...acc,
          [key]: {
            status: isPendingOrPublished ? status : DeviceConfigurationStatus.CURRENT,
            attributes: obj
          }
        };
      },
      {
        status
      } as DeviceModbusRequestTableState
    );
  };

  // Return MRT prefix key by index
  public static readonly getDeviceModbusRequestTableIndexKey = (deviceModbusRequestTable: DeviceModbusRequestTable): string =>
    `MRT${StringUtils.padWith(deviceModbusRequestTable?.index, 2, '0')}`;

  // Return a new configuration copy without properties that match MRT index
  public static readonly removePropertiesFrom = (
    deviceModbusRequestTable: DeviceModbusRequestTable,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(deviceModbusRequestTable, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get MRT key
    const key = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableIndexKey(deviceModbusRequestTable);
    return DeviceHelpers.reduceConfiguration(configuration, key, targetConfiguration, false);
  };

  // Return all configuration properties that match MRT index
  public static readonly getPropertiesFrom = (
    deviceModbusRequestTable: DeviceModbusRequestTable,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(deviceModbusRequestTable, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get MRT key
    const key = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableIndexKey(deviceModbusRequestTable);
    return DeviceHelpers.reduceConfiguration(configuration, key, targetConfiguration, true);
  };

  // MRT that does not have an index are not configurable
  public static readonly canConfigureDeviceModbusRequestTable = (deviceModbusRequestTable: DeviceModbusRequestTable) =>
    deviceModbusRequestTable.index !== null &&
    deviceModbusRequestTable.index !== undefined &&
    !Object.keys(get(deviceModbusRequestTable.device, ['configuration', 'target'], {})).length;

  public static readonly cleanUpConfiguration = (
    indexKey: string,
    deviceConfiguration: DeviceConfiguration,
    configuration: { [key: string]: unknown },
    targetConfiguration: 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Override pending configuration
    const config = {
      ...get(deviceConfiguration, [targetConfiguration], {}),
      ...configuration
    };

    // Clean up target configuration
    return Object.keys(config).reduce((acc, key) => {
      const currentValue = get(deviceConfiguration, ['current', key, 'v']);
      if (!(config[key] === currentValue)) {
        acc[key] = config[key];
      }
      return { ...acc };
    }, {});
  };

  public static readonly getBaudrateByValue = (key: string, configuration: DeviceConfiguration, valueGetter: (key: string) => string[]) => {
    const baudrate = get(configuration, valueGetter(`${key}.${DeviceModbusRequestTableHelpers.ATTRIBUTE_MAPPER.baudrate}`));
    return ModbusTableHelpers.getBaudrates().find(({ value }) => value === baudrate);
  };

  // Generate MRT model instance
  private static getDeviceModbusRequestTableInstance(
    key: string,
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ): DeviceModbusRequestTable {
    const status = DeviceHelpers.getStatusByKey(key, device, pendingKeys, targetKeys);
    const index = DeviceHelpers.parseIndex(key);

    // Compute and merge configuration objects
    const configuration = cloneDeep(get(device, 'configuration'));
    if (status === DeviceConfigurationStatus.PENDING) {
      configuration.current = DeviceHelpers.mergeConfigValues(device, 'pending');
    } else if (status === DeviceConfigurationStatus.PUBLISHED) {
      configuration.current = DeviceHelpers.mergeConfigValues(device, 'target');
    }

    const keys = Object.keys(DeviceModbusRequestTableHelpers.ATTRIBUTE_MAPPER);

    // Get key values list
    const array = keys.map((attrName) => {
      let val = get(configuration, valueGetter(`${key}.${DeviceModbusRequestTableHelpers.ATTRIBUTE_MAPPER[attrName]}`));
      if (attrName === 'baudrate') {
        val = DeviceModbusRequestTableHelpers.getBaudrateByValue(key, configuration, valueGetter);
      }
      return {
        attrName,
        value: val
      };
    });

    // Calculate computed properties
    const computedProperties = DeviceModbusRequestTableHelpers.COMPUTED_ATTRIBUTES.reduce((acc, attr) => {
      const value = DeviceModbusRequestTableHelpers.getComputedValue(key, attr, configuration, valueGetter);
      return {
        ...acc,
        [attr]: value
      };
    }, {});

    // Update mapped values { label, value }
    const deviceModbusRequestTable = array.reduce((acc, item) => {
      let value = item.value;
      if (Object.keys(DeviceModbusRequestTableHelpers.MAPPED_PROPERTIES).includes(item.attrName)) {
        value = ModbusTableHelpers.getListItemByListName(
          modbusTable,
          get(DeviceModbusRequestTableHelpers.MAPPED_PROPERTIES, [item.attrName], 'listName'),
          item.value
        );
      }
      return {
        ...acc,
        [item.attrName]: value
      };
    }, {}) as DeviceModbusRequestTable;

    // This is used as business rule to enable/disable MRT configuration
    const canConfigure = DeviceModbusRequestTableHelpers.canConfigureDeviceModbusRequestTable({
      ...deviceModbusRequestTable,
      index,
      device
    } as DeviceModbusRequestTable);
    const id = uuidv4();
    return {
      ...deviceModbusRequestTable,
      id,
      index,
      status,
      device,
      isCreated,
      canConfigure,
      hasCleanConfiguration: true,
      ...computedProperties
    };
  }
}
