import { NgModule } from '@angular/core';
import { TimelineContentComponent } from './timeline-content/timeline-content.component';
import { TimelineDotComponent } from './timeline-dot/timeline-dot.component';
import { TimelineEntryComponent } from './timeline-entry/timeline-entry.component';
import { TimelineHeaderComponent } from './timeline-header/timeline-header.component';
import { TimelineComponent } from './timeline.component';

const COMPONENTS = [TimelineComponent, TimelineHeaderComponent, TimelineContentComponent, TimelineEntryComponent, TimelineDotComponent];

@NgModule({
  imports: [COMPONENTS],
  exports: [COMPONENTS]
})
export class TimelineModule {}
