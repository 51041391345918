import { EventEmitter } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import { BehaviorSubject, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface AutoRefreshTimerOptions {
  delay: number;
  enabled: boolean;
  loading$: Observable<boolean>;
}

export class AutoRefreshTimer {
  delay: number;
  enabled: boolean;
  counter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  timeLeft$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  loading$: Observable<boolean>;
  onTimerValueChanged: EventEmitter<number> = new EventEmitter<number>();
  onRefresh: EventEmitter<void> = new EventEmitter<void>();
  refreshActivated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  timer$: Observable<number> = of(0);
  startTimer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly destroy$: Subject<void> = new Subject<void>();
  private subscriptions: Subscription[] = [];

  constructor(options: Partial<AutoRefreshTimerOptions>) {
    this.delay = options.delay ?? 30;
    this.enabled = options.enabled ?? true;
    this.loading$ = options.loading$ ?? of(false);
    this.timer$ = this.getTimer();

    this.subscriptions.push(
      this.timer$.subscribe((current: number) => {
        this.counter$.next(100 - (current / this.delay) * 100);
        this.timeLeft$.next(this.delay - current);
        this.onTimerValueChanged.emit(current);
        if (current >= this.delay) {
          this.onRefresh.emit();
          this.startTimer$.next(true);
        }
      })
    );
  }

  start(): void {
    if (this.enabled) {
      this.startTimer$.next(true);
    }
  }

  activate(): void {
    this.refreshActivated$.next(true);
  }

  deactivate(): void {
    this.refreshActivated$.next(false);
  }

  destroy(): void {
    this.startTimer$.next(false);
    this.destroyTimer();
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  private getTimer(): Observable<number> {
    return this.startTimer$.pipe(
      tap(() => {
        this.destroyTimer();
      }),
      filter((enabled: boolean) => enabled && this.delay > 0),
      switchMap(() => timer(0, 1 * 1000).pipe(takeUntil(this.destroy$))),
      concatLatestFrom(() => this.refreshActivated$),
      filter(([_, refreshActivated]) => refreshActivated && this.enabled),
      map(([current]) => current)
    );
  }

  private destroyTimer(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
