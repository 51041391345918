import { fromAuth } from '@iot-platform/auth';
import { BusinessProfile } from '@iot-platform/models/common';
import { createSelector } from '@ngrx/store';
import { get } from 'lodash';

export const selectCurrentBusinessProfile = createSelector(
  fromAuth.selectCurrentUser,
  fromAuth.selectSelectedBusinessProfileId,
  (user, id) => get(user, 'businessProfiles', []).find((_bp: BusinessProfile) => _bp.id === id) ?? { id }
);
