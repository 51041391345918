import { EnergyModes, ModbusTable, ModemRadioBands } from '@iot-platform/models/dalia';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DirectoryActions } from '../actions/directory.actions';

export interface DirectoryState {
  modbusTable: ModbusTable;
  modemRadioBands: ModemRadioBands;
  energyModes: EnergyModes;
}

export const directoryInitialState: DirectoryState = {
  modbusTable: { mappings: {}, lists: {} },
  modemRadioBands: {},
  energyModes: {}
};

export const directoryFeature = createFeature({
  name: 'directory',
  reducer: createReducer(
    directoryInitialState,
    on(
      DirectoryActions.loadModbusTableSuccess,
      (state: DirectoryState, { response }): DirectoryState => ({
        ...state,
        modbusTable: {
          lists: response.lists,
          mappings: response.mappings
        }
      })
    ),
    on(
      DirectoryActions.loadModemRadioBandsSuccess,
      (state: DirectoryState, { response: modemRadioBands }): DirectoryState => ({
        ...state,
        modemRadioBands
      })
    ),
    on(
      DirectoryActions.loadEnergyModesSuccess,
      (state: DirectoryState, { response: energyModes }): DirectoryState => ({
        ...state,
        energyModes
      })
    )
  )
});
