import { Component, input, output } from '@angular/core';
import { TagCategory } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { NgClass } from '@angular/common';
import { PanelComponent } from '../../panel';
import { CardLoaderModule } from '../../card-loader/card-loader.module';
import { ChipModule } from '../../chip/chip.module';

@Component({
  standalone: true,
  imports: [MatChipsModule, TranslateModule, PanelComponent, CardLoaderModule, ChipModule, NgClass],
  selector: 'iot-platform-ui-tags-list',
  templateUrl: './tags-list.component.html'
})
export class TagsListComponent {
  title = input<string>('MANAGE_TAGS_FORM.TAGS');
  tooltip = input<string>('MANAGE_TAGS_FORM.MANAGE_TAGS');
  loading = input<boolean>(false);
  tags = input<TagCategory[]>([]);
  canCreateOrUpdateTag = input<boolean>(true);

  manageTags = output<void>();
}
