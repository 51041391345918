import { NgModule } from '@angular/core';
import { InfosDisplayCardLabelComponent } from './infos-display-card-label/infos-display-card-label.component';
import { InfosDisplayCardPropertyComponent } from './infos-display-card-property/infos-display-card-property.component';
import { InfosDisplayCardComponent } from './infos-display-card.component';

const COMPONENTS = [InfosDisplayCardComponent, InfosDisplayCardPropertyComponent, InfosDisplayCardLabelComponent];

@NgModule({
  exports: [...COMPONENTS],
  imports: [...COMPONENTS]
})
export class InfosDisplayCardModule {}
