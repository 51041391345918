import { CommonApiRequest, CommonApiResponse, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceTemplate } from '@iot-platform/models/dalia';
import { createActionGroup, props } from '@ngrx/store';

export const DeviceTemplatesActions = createActionGroup({
  source: 'Device Templates',
  events: {
    'Load Templates': props<{
      request?: CommonApiRequest;
    }>(),
    'Load Templates Success': props<{
      response: CommonApiResponse<DeviceTemplate, Pagination>;
    }>(),
    'Load Templates Failure': props<{
      error: unknown;
    }>(),

    'Load Template By Id': props<{
      id: string;
    }>(),
    'Load Template By Id Success': props<{
      response: DeviceTemplate;
    }>(),
    'Load Template By Id Failure': props<{
      error: unknown;
    }>(),

    'Set Filters': props<{
      filters: Filter[];
    }>(),

    'Set Current Template': props<{
      template: DeviceTemplate;
    }>(),

    'Create Template': props<{
      template: DeviceTemplate;
    }>(),
    'Create Template Success': props<{
      response: DeviceTemplate;
    }>(),
    'Create Template Failure': props<{
      error: unknown;
    }>(),

    'Edit Template': props<{
      template: DeviceTemplate;
    }>(),
    'Edit Template Success': props<{
      response: DeviceTemplate;
    }>(),
    'Edit Template Failure': props<{
      error: unknown;
    }>(),

    'Delete Template': props<{
      template: DeviceTemplate;
    }>(),
    'Delete Template Success': props<{
      response: DeviceTemplate;
    }>(),
    'Delete Template Failure': props<{
      error: unknown;
    }>()
  }
});
