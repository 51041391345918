import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment, TagCategory } from '@iot-platform/models/common';
import { Concept } from '@iot-platform/models/qlixbi';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TagCategoriesService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly http: HttpClient = inject(HttpClient);

  getTagCategories(): Observable<TagCategory[]> {
    const params: HttpParams = new HttpParams().set('concept', Concept.EVENT);
    return this.http
      .get<{
        content: TagCategory[];
      }>(`${this.environment.api.url}${this.environment.api.endpoints.tags}`, { params })
      .pipe(
        map(({ content }) => content),
        catchError(() => of([]))
      );
  }
}
