import { inject, Injectable, Signal } from '@angular/core';
import { BusinessProfile } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCurrentBusinessProfile } from '../selectors/business-profiles.selectors';

@Injectable({
  providedIn: 'root'
})
export class BusinessProfilesFacade {
  protected readonly store: Store = inject(Store);

  currentBusinessProfile$: Observable<BusinessProfile> = this.store.select(selectCurrentBusinessProfile);
  currentBusinessProfile: Signal<BusinessProfile> = this.store.selectSignal(selectCurrentBusinessProfile);
}
