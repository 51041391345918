import { computed, inject, Injectable, Signal } from '@angular/core';
import { DirectoryFacade } from '@iot-platform/dalia/data-access';
import { DeviceModbusRequestTableHelpers } from '@iot-platform/dalia/util/devices';
import { BaseFacade, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceModbusRequestTable, DeviceModbusRequestTableState } from '@iot-platform/models/dalia';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { DeviceModbusRequestTablesActions } from '../actions';
import { DeviceModbusRequestTableSelectors } from '../selectors/device-modbus-request-table.selectors';

@Injectable({
  providedIn: 'root'
})
export class DeviceTemplateModbusRequestTableFacade extends BaseFacade<DeviceModbusRequestTable, Pagination, Filter> {
  currentDeviceModbusRequestTableIndex: Signal<number> = this.store.selectSignal(this.selector.selectCurrentDeviceModbusRequestTableIndex);
  protected readonly directoryFacade: DirectoryFacade = inject(DirectoryFacade);
  deviceModbusRequestTableState: Signal<DeviceModbusRequestTableState> = computed(() => {
    const deviceModbusRequestTable = this.currentEntity();
    const modbusTable = this.directoryFacade.modbusTable();
    return DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableState(deviceModbusRequestTable, modbusTable);
  });

  constructor(
    protected override store: Store,
    protected override selector: DeviceModbusRequestTableSelectors
  ) {
    super(store, selector);
  }

  getAll() {
    noop();
  }

  setFilters(): void {
    noop();
  }

  setCurrentDeviceModbusRequestTable(deviceModbusRequestTable: DeviceModbusRequestTable) {
    this.store.dispatch(DeviceModbusRequestTablesActions.setCurrentDeviceModbusRequestTable({ deviceModbusRequestTable }));
  }

  setCurrentDeviceModbusRequestTableIndex(index: number) {
    this.store.dispatch(DeviceModbusRequestTablesActions.setCurrentDeviceModbusRequestTableIndex({ index }));
  }
}
