<div [ngClass]='cssClass()' class='card-details-widget' fxFlex fxLayout='column'>

  <div class='card-details-widget__header' fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='5px'>
    @if (!!icon()) {
      <em class='card-details-widget__icon fw-600 {{ icon() }}'></em>
    }
    @if (!!svgIcon()) {
      <mat-icon class='card-details-widget__icon icon {{svgIcon()}}' [svgIcon]='svgIcon()' />
    }
    @if (!!matIcon()) {
      <mat-icon class='card-details-widget__icon icon {{matIcon()}}'>{{ matIcon() }}</mat-icon>
    }
    @if (!!title()) {
      <span class='card-details-widget__label fw-600'> {{ title() | translate }} </span>
    }

    <div class='card-details-widget__separator' fxFlex></div>
  </div>

  <div class='card-details-widget__content' fxLayout='column' fxLayoutGap='10px'>
    @if (!!loading()) {
      <iot-platform-ui-card-loader fxFlex [backgroundClass]="'mv-detail-card h-140'" />
    } @else {
      <ng-content />
    }
  </div>
</div>

