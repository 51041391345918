export enum EventType {
  ACTION_NOT_TAKEN = 'action_not_taken',
  DEVICE_UNINITIALIZED = 'device_uninitialized',
  DEVICE_NOT_PROVISIONED_LORA = 'device_not_provisioned_lora',
  DEVICE_NOT_PROVISIONED_SIGFOX = 'device_not_provisioned_sigfox',
  BATTERY_ALERT = 'battery_alert',
  LOW_COVERAGE = 'low_coverage',
  MISS_MATCH_ASSET_DEVICE_PRESSURE = 'miss_match_asset_device_pressure',
  NO_COMMUNICATION = 'no_communication',
  PROVISIONING_DUPLICATE_LORA = 'provisioning_duplicate_lora',
  PROVISIONING_DUPLICATE_SIGFOX = 'provisioning_duplicate_sigfox',
  ASSET_UNREFERENCED = 'asset_unreferenced',
  AUTO_ORDER = 'auto_order',
  CUSTOMER_REQUEST = 'customer_request',
  LOW_STOCK = 'low_stock',
  CALL_FOR_MAINTENANCE = 'call_for_maintenance',
  DECOMMISSIONED_IN_USE = 'decommissioned_in_use'
}
