import { Component, input, output } from '@angular/core';
import { CardAction, CardEventType } from '@iot-platform/models/common';

@Component({
  template: ''
})
export abstract class BaseCardComponent<T> {
  element = input<T>();
  icon = input<string>();
  image = input<string>();
  withMenu = input<boolean>(false);
  cssClasses = input<string>('');
  actions = input<CardAction[]>([]);

  dispatchEvent = output<any>();

  onDispatchEvent(event: any): void {
    this.dispatchEvent.emit(event);
  }

  onElementClicked(): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.ELEMENT_CLICKED,
      data: this.element()
    });
  }
}
