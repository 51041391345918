import { NgClass, NgStyle, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, input, output, QueryList } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'iot-platform-ui-tab-group',
  standalone: true,
  imports: [FlexLayoutModule, MatTabsModule, NgTemplateOutlet, TranslateModule, UpperCasePipe, NgStyle, MatIconModule, NgClass, MatTooltipModule],
  templateUrl: './tab-group.component.html'
})
export class TabGroupComponent implements AfterContentInit {
  direction = input<'horizontal' | 'vertical'>('horizontal');
  position = input<'top' | 'bottom' | 'left' | 'right'>('top');
  textAlign = input<'center' | 'left' | 'right'>('center');
  width = input<string>();
  withArrowIcon = input<boolean>(false);
  selectedIndex = input<number>(0);
  style = input<any>();

  selectedTabChange = output<{ index: number; tab: { textLabel: string; id: string } }>();

  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  get flexWidth() {
    if (this.width()) {
      return this.width();
    } else if (this.position() === 'left' || this.position() === 'right') {
      return '40%';
    }
    return '100%';
  }

  get layout() {
    if (this.position() === 'bottom') {
      return 'column-reverse';
    } else if (this.position() === 'left') {
      return 'row';
    } else if (this.position() === 'right') {
      return 'row-reverse';
    }
    return 'column';
  }

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active());
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.toArray()[this.selectedIndex()]);
    }
  }

  selectTab(tab: TabComponent) {
    if (tab && !tab.disabled()) {
      const tabs = this.tabs.toArray();
      tabs.forEach((t: TabComponent) => t.active.set(false));
      tab.active.set(true);
      this.selectedTabChange.emit({
        index: tabs.findIndex((t) => t.id() === tab.id()),
        tab: {
          id: tab.id(),
          textLabel: tab.header.textLabel()
        }
      });
    }
  }
}
