import { Injectable } from '@angular/core';
import { AbstractTrackingMapperService, HotjarAnalyticsTrackingData, HotjarAnalyticsTrackingTag, TrackingProviders } from '@iot-platform/core';

@Injectable()
export class HotjarAnalyticsMapperService implements AbstractTrackingMapperService<HotjarAnalyticsTrackingTag, HotjarAnalyticsTrackingData> {
  getTrackingProviderName(): TrackingProviders {
    return TrackingProviders.HotjarAnalytics;
  }

  getTrackingDataForProvider(trackingTag: HotjarAnalyticsTrackingTag /* trackingData: TrackingData */): HotjarAnalyticsTrackingData {
    const params: Partial<HotjarAnalyticsTrackingData> = {};
    params.event_name = trackingTag?.pageName;
    return params as HotjarAnalyticsTrackingData;
  }
}
