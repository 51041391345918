import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Environment, UserAccount } from '@iot-platform/models/common';

@Component({
  selector: 'iot-platform-users-user-profile-api-keys',
  templateUrl: './user-profile-api-keys.component.html',
  styleUrls: ['./user-profile-api-keys.component.scss']
})
export class UserProfileApiKeysComponent {
  @Input() apiKeys: any[] = [];
  @Input() maxApiKeysReached: boolean;
  @Input() currentUser: UserAccount;

  @Output() addApiKey: EventEmitter<void> = new EventEmitter<void>();
  @Output() getApiKey: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteApiKey: EventEmitter<string> = new EventEmitter<string>();

  constructor(@Inject('environment') private readonly environment: Environment) {}

  get synapseApiCatalogUrl() {
    return this.environment.synapseApiCatalog;
  }

  onAddApiKey() {
    this.addApiKey.emit();
  }

  onGetApikey(apiKeyIdToGet: string) {
    this.getApiKey.emit(apiKeyIdToGet);
  }

  onDeleteApiKey(apiKeyIdToDelete: string) {
    this.deleteApiKey.emit(apiKeyIdToDelete);
  }
}
