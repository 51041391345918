import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, CommonApiRequest, CommonApiResponse, Environment, Pagination } from '@iot-platform/models/common';
import { DeviceTemplate } from '@iot-platform/models/dalia';

import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceTemplatesService {
  protected readonly environment: Environment = inject(ENVIRONMENT);
  protected readonly http: HttpClient = inject(HttpClient);

  getAll(request?: CommonApiRequest): Observable<CommonApiResponse<DeviceTemplate, Pagination>> {
    const params: HttpParams = ApiHelpers.getHttpParams(request);
    return this.http
      .get<CommonApiListResponse<DeviceTemplate>>(`${this.environment.api.url}${this.environment.api.endpoints.deviceTemplates}`, { params })
      .pipe(map((response: CommonApiListResponse<DeviceTemplate>) => ApiHelpers.getHttpResponse<DeviceTemplate>(response)));
  }

  getOneById(id: string): Observable<DeviceTemplate> {
    return this.http.get<DeviceTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.deviceTemplates}/${id}`);
  }

  deleteOne(template: DeviceTemplate): Observable<boolean> {
    return this.http.delete<boolean>(`${this.environment.api.url}${this.environment.api.endpoints.deviceTemplates}/${template.id}`);
  }

  addOne(template: DeviceTemplate): Observable<DeviceTemplate> {
    return this.http.post<DeviceTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.deviceTemplates}`, template);
  }

  updateOne(template: DeviceTemplate): Observable<DeviceTemplate> {
    return this.http.put<DeviceTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.deviceTemplates}/${template.id}`, template);
  }
}
