import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';

export class I4BSelectionColumnHeader implements I4BColumnHeader {
  type = HeaderType.EMPTY;
  displayName = '';
  icon = '';

  constructor(customHeader?: Partial<I4BColumnHeader>) {
    if (customHeader) {
      this.displayName = customHeader.displayName ?? this.displayName;
      this.icon = customHeader.icon ?? '';
      this.type = customHeader.type ?? HeaderType.EMPTY;
    }
  }
}
