<div [fxLayoutAlign]="items()?.length ? 'start stretch' : 'center center'" class="iot-platform-ui-list-view"
     data-cy="iot-platform-ui-list-view"
     fxLayout="row"
     fxLayout.lt-md="column" fxLayoutGap="10px">
  <div [fxLayoutAlign]="items()?.length ? 'start stretch' : 'center center'" fxFlex="360px" fxFlex.lt-md="100%"
       fxLayout="column"
       fxLayoutGap="10px">
    @if (items()?.length) {
      <iot-platform-ui-list-view-items (selectionChange)="onSelectionChange($event)"
                                       [selectedItem]="selectedItem()"
                                       [items]="items()"
      />
    }
    <ng-content />
  </div>
  @if (items()?.length) {
    <iot-platform-ui-list-view-content data-cy="iot-platform-ui-list-view-content" [loading]="loading()" [card]="card()"
                                       (dispatchEvent)="dispatchEvent.emit($event)"
                                       fxFlex />
  }
</div>
