import { DeviceDetails, DeviceVariable } from '@iot-platform/models/dalia';
import { I4BBulkOperationApiResponse } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';

export const DeviceVariablesActions = createActionGroup({
  source: 'Device Variables',
  events: {
    'Load Device Variables': props<{
      device: DeviceDetails;
    }>(),
    'Load Device Template Variables': props<{
      device: DeviceDetails;
    }>(),
    'Load Device Variables Success': props<{
      response: DeviceVariable[];
    }>(),
    'Load Device Variables Failure': props<{
      error: unknown;
    }>(),
    'Set Dalia Variable List': props<{
      variables: DeviceVariable[];
    }>(),
    'Set Current Variable': props<{
      variable: DeviceVariable;
    }>(),

    'Bulk Delete Device Variables': props<{
      variables: DeviceVariable[];
    }>(),
    'Bulk Delete Device Variables Success': props<{
      response: I4BBulkOperationApiResponse;
    }>(),
    'Bulk Delete Device Variables Failure': props<{
      error: unknown;
    }>()
  }
});
