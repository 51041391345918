import { WidgetAction } from './widget-action.enum';
import { WidgetSize } from './widget-size.enum';

export interface BaseWidgetOptions {
  size?: WidgetSize;
  menu?: {
    enabled?: boolean;
    actions: {
      name: WidgetAction;
      label: string;
      enabled?: boolean;
    }[];
  };
  header?: {
    enabled?: boolean;
    title: string;
  };
  footer?: {
    enabled?: boolean;
    legend: string;
  };
}
