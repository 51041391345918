import { I4BGridSort } from '../models/i4b-grid.sort';

export interface I4BColumnOptions {
  order: number;
  customId?: string;
  sortable?: boolean;
  isLink?: boolean;
  overrideHeaderName?: string;
  sort?: I4BGridSort;
  width?: number;
  isDisplayed?: boolean;
  pinned?: boolean | 'left' | 'right' | null;
  diff?: {
    translateKey?: string;
    clickEvent?: {
      type: string;
      options?: string | { followedNumber: number } | { targetType: string; targetId: string; siteId: string };
    };
    statusCellOptions?: {
      withStatusPadding: boolean;
      class: {
        running: { width: string; color: string; 'background-color': string; padding: string };
        standby: { width: string; color: string; 'background-color': string; padding: string };
        test_mode: { width: string; color: string; 'background-color': string; padding: string };
        decommissioned: { width: string; color: string; 'background-color': string; padding: string };
      };
    };
    assetStatusCellOptions?: {
      withStatusPadding: boolean;
      class: {
        production: { width: string; color: string; 'background-color': string; padding: string };
        maintenance: { width: string; color: string; 'background-color': string; padding: string };
        decommissioned: { width: string; color: string; 'background-color': string; padding: string };
      };
    };
    booleanCellOptions?: {
      mode: string;
      dataToDisplayIfTrue: string;
      dataToDisplayIfFalse: string;
      class: {
        disabled: { width: string; color: string; 'background-color': string; padding: string };
        active: { width: string; color: string; 'background-color': string; padding: string };
        unconfirmed: { width: string; color: string; 'background-color': string; padding: string };
        pending: { width: string; color: string; 'background-color': string; padding: string };
      };
      withStatusPadding: true;
    };
    topicsStatusCellOptions?: {
      withStatusPadding: true;
      class: {
        inactive: { width: string; color: string; 'background-color': string; padding: string };
        active: { width: string; color: string; 'background-color': string; padding: string };
      };
    };
    dynamicIconCellOptions?: {
      icon: string;
      class: string;
      tooltip: {
        key: string;
      };
    };
    assetVariableCellOptions?: {
      dataType: string;
    };
    richVariableValueCellOptions?: {
      dataType: string;
      unit: string;
      value?: string;
      tooltip?: string;
    };
  };
}
