import { inject } from '@angular/core';
import { DirectoryFacade } from '@iot-platform/dalia/data-access';
import { DeviceTankHelpers } from '@iot-platform/dalia/util/devices';
import { DeviceTank } from '@iot-platform/models/dalia';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { map } from 'rxjs/operators';
import { DeviceTanksActions, DeviceTemplatesActions } from '../actions';
import { DevicesActions } from '../actions/devices.actions';
import { DeviceTemplatesFacade } from '../facades/device-templates.facade';
import { DevicesFacade } from '../facades/devices.facade';

const getTanks = (tanks: DeviceTank[], deactivateActions: boolean): DeviceTank[] =>
  tanks.map((t: DeviceTank) => {
    if (deactivateActions) {
      return {
        ...t,
        canConfigure: false
      };
    }
    return t;
  });

const loadDeviceByIdSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), directoryFacade = inject(DirectoryFacade), devicesFacade = inject(DevicesFacade)) =>
    actions$.pipe(
      ofType(DevicesActions.loadDeviceByIdSuccess),
      concatLatestFrom(() => directoryFacade.modbusTable$),
      map(([{ response }, modbusTable]) => DeviceTankHelpers.getTanks(response, modbusTable)),
      map((tanks: DeviceTank[]) => getTanks(tanks, devicesFacade.deactivateActions())),
      map((tanks: DeviceTank[]) => DeviceTanksActions.setDaliaTanksList({ tanks }))
    ),
  { functional: true }
);

const loadTemplateByIdSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), directoryFacade = inject(DirectoryFacade), deviceTemplatesFacade = inject(DeviceTemplatesFacade)) =>
    actions$.pipe(
      ofType(DeviceTemplatesActions.loadTemplateByIdSuccess),
      concatLatestFrom(() => directoryFacade.modbusTable$),
      map(([_, modbusTable]) => ({
        device: deviceTemplatesFacade.currentDevice(),
        modbusTable
      })),
      map(({ device, modbusTable }) => DeviceTankHelpers.getTanks(device, modbusTable)),
      map((tanks: DeviceTank[]) => getTanks(tanks, deviceTemplatesFacade.deactivateActions())),
      map((tanks: DeviceTank[]) => DeviceTanksActions.setDaliaTanksList({ tanks }))
    ),
  { functional: true }
);

export const DeviceTanksEffects = {
  loadDeviceByIdSuccess$,
  loadTemplateByIdSuccess$
};
