import { Injectable } from '@angular/core';
import { BaseFacade, CommonIndexedPagination, Filter, TagCategory } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { TagCategoriesActions } from '../actions/tag-categories.actions';
import { TagCategoriesSelectors } from '../selectors/tag-categories.selectors';

@Injectable({
  providedIn: 'root'
})
export class TagCategoriesFacade extends BaseFacade<TagCategory, CommonIndexedPagination, Filter> {
  constructor(
    protected override store: Store,
    protected override selector: TagCategoriesSelectors
  ) {
    super(store, selector);
  }

  getAll() {
    this.store.dispatch(TagCategoriesActions.loadTagCategories());
  }

  setFilters() {
    noop();
  }
}
