import { CommonGenericModel } from '../common';

export enum Operation {
  /** OLD operations **/
  STICK_LABEL = 'stick_label',
  PRINT_LABEL = 'print_label',
  VERIFY_LEVER_CLIP = 'verify_lever_clip',
  CHANGE_PLASTIC_CASING = 'change_plastic_casing',
  VERIFY_PRESSURE = 'verify_pressure',
  VERIFY_LED = 'verify_led',
  DIGITAL_RESET = 'digital_reset',
  PRESSURE_GAUGE = 'pressure_gauge',
  CHANGE_BATTERY = 'change_battery',
  /** NEW operations **/
  TAMPER_PROOF = 'tamper_proof',
  FIVE_SCREWS = 'five_screws',
  INLET_CONNECTION = 'inlet_connection',
  OUTLET_CONNECTION = 'outlet_connection',
  LEVER = 'lever',
  SHELLS_AND_COVER = 'shells_and_cover',
  CHANGE_SHELL = 'change_shell',
  CHANGE_COVER = 'change_cover',
  CHANGE_ORING = 'change_oring',
  HP_PORT_CONNECTION = 'hp_port_connection',
  LEVER_ACTIVATION = 'lever_activation',
  LEVER_ON_DISCONNECT = 'lever_on_disconnect',
  HP_PORT_DISCONNECT = 'hp_port_disconnect',
  GAS_FLOW = 'gas_flow',
  LEAKAGE = 'leakage',
  PURGE = 'purge',
  DISCONNECTION = 'disconnection',
  LED_BLINK = 'led_blink',
  UPDATE_FIRMWARE = 'update_firmware',
  ELECTRONIC_BOX = 'electronic_box'
}

export enum OperationType {
  /** OLD operation types **/
  PACKAGING = 'packaging',
  VERIFICATION = 'verification',
  ACTION = 'action',
  /** NEW operation types **/
  VISUAL_INSPECTION = 'visual_inspection',
  APPEARANCE_INSPECTION = 'appearance_inspection',
  MAINTENANCE = 'maintenance',
  TESTS_WITHOUT_PRESSURE = 'tests_without_pressure',
  PRESSURE_TESTS = 'pressure_tests',
  COMPLEMENTARY_INFOS = 'complementary_info'
}

export interface DeviceOperation extends CommonGenericModel {
  operation: Operation;
  type: OperationType;
  model: string;
  family: string;
}
