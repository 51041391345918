import { EnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { combineReducers, createFeature, provideState } from '@ngrx/store';
import { AuthBusinessProfilesEffects } from '../effects/business-profiles.effects';
import { DirectoryEffects } from '../effects/directory.effects';
import { TagCategoriesEffects } from '../effects/tag-categories.effects';
import { directoryFeature, DirectoryState } from './directory.reducer';
import { tagCategoriesFeature, TagCategoryState } from './tag-categories.reducer';

export interface ApplicationState {
  [directoryFeature.name]: DirectoryState;
  [tagCategoriesFeature.name]: TagCategoryState;
}

export const applicationFeatures = createFeature<string, ApplicationState>({
  name: 'application',
  reducer: combineReducers({
    [directoryFeature.name]: directoryFeature.reducer,
    [tagCategoriesFeature.name]: tagCategoriesFeature.reducer
  })
});

export function provideDaliaApplicationState(): EnvironmentProviders[] {
  return [
    provideState(applicationFeatures.name, applicationFeatures.reducer),
    provideEffects(AuthBusinessProfilesEffects, DirectoryEffects, TagCategoriesEffects)
  ];
}
