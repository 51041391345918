import { Type } from '@angular/core';
import { FilterDefaultOptions } from '@iot-platform/models/common';
import { AbstractFavoriteFilterEngineService } from '../services/abstract-favorite-filter-engine.service';

export type FilterEngineOptions = {
  [concept: string]: { [category: string]: { [key: string]: FilterDefaultOptions } };
};

export class FilterEngineSettings {
  favoriteFilterEngineService!: Type<AbstractFavoriteFilterEngineService>;
  options!: FilterEngineOptions;
}
