import { inject } from '@angular/core';
import {
  AuthApiActions,
  AuthorizationConcept,
  AuthorizationService,
  AuthorizationType,
  AuthPageActions
} from '@iot-platform/auth';
import { BusinessProfile } from '@iot-platform/models/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, map, switchMap, tap } from 'rxjs';

// Return only business profiles that have the rule CREATE on device concept
const getBusinessProfilesWhenCreateRuleOnDeviceConcept$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), authService = inject(AuthorizationService), store = inject(Store)) =>
    actions$.pipe(
      ofType(AuthApiActions.loadAccountSuccess),
      tap(() => {
        store.dispatch(AuthPageActions.setPending({ pending: true }));
      }),
      map(({ account }) => account.businessProfiles),
      map((businessProfiles: BusinessProfile[]) =>
        businessProfiles.map((businessProfile: BusinessProfile) =>
          authService.getAuthorizationsByBusinessProfile(businessProfile.id).pipe(map((privileges) => ({ privileges, businessProfile })))
        )
      ),
      switchMap((streams$) =>
        forkJoin(streams$).pipe(
          map((result: { privileges: { [key: string]: string[] }[]; businessProfile: BusinessProfile }[]) =>
            result
              .filter(({ privileges }) => {
                const privilege = privileges ? privileges[AuthorizationConcept.DEVICE.toString().toUpperCase()] : null;
                return privilege && privilege.some((rule: string) => rule.toLowerCase() === AuthorizationType.CREATE.toString().toLowerCase());
              })
              .map(({ businessProfile }) => businessProfile)
          )
        )
      ),
      tap(() => {
        store.dispatch(AuthPageActions.setPending({ pending: false }));
      }),
      map((businessProfiles: BusinessProfile[]) => AuthApiActions.setBusinessProfiles({ businessProfiles }))
    ),
  { functional: true }
);

export const AuthBusinessProfilesEffects = {
  getBusinessProfilesWhenCreateRuleOnDeviceConcept$
};
