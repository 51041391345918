import { ModbusTableHelpers } from '@iot-platform/dalia/util';
import { ArrayUtils, DateIntervalUtils, DecimalUtils, StringUtils } from '@iot-platform/iot-platform-utils';
import { Duration } from '@iot-platform/models/common';
import {
  AlarmSettings,
  CommunicationList,
  CommunicationRadioBand,
  CommunicationRadioBandListItem,
  CommunicationSettings,
  DeviceAutonomySettings,
  DeviceCallingHourSettings,
  DeviceConfiguration,
  DeviceConfigurationState,
  DeviceConfigurationStatus,
  DeviceConfigurationTargetStatus,
  DeviceDetails,
  DeviceGeneralInformationSettings,
  DeviceSettings,
  EnergyMode,
  EnergyModes,
  EnergyModeSettings,
  EnergySettings,
  GpsSettings,
  HardwareSettings,
  ModbusListItem,
  ModbusListName,
  ModbusSettings,
  ModbusTable,
  ModbusTablePropertyState,
  ModemRadioBands,
  ModemType,
  TankSettings
} from '@iot-platform/models/dalia';
import { cloneDeep, get, uniq } from 'lodash';
import * as moment from 'moment';
import { DeviceVariableHelpers } from './device-variable.helpers';

export class DeviceHelpers {
  public static readonly REFRESH_COMMAND_ELIGIBLE_OPERATORS = ['Orange France', 'Vodafone WW'];

  public static readonly ENERGY_MODES = [
    {
      value: 0,
      label: EnergyMode.FPM
    },
    {
      value: 1,
      label: EnergyMode.ECO1
    },
    {
      value: 2,
      label: EnergyMode.ECO2
    },
    {
      value: 3,
      label: EnergyMode.PSM
    },
    {
      value: 4,
      label: 'N/A'
    }
  ];

  public static readonly MODEM_TYPES = [
    {
      label: ModemType.UNKNOWN,
      value: 0
    },
    {
      label: ModemType.PLS62,
      value: 1
    },
    {
      label: ModemType.EXS82,
      value: 2
    },
    {
      label: ModemType.PLS63,
      value: 3
    },
    {
      label: ModemType.EHS8,
      value: 4
    }
  ];

  public static readonly ENERGY_FUNCTIONAL_ATTRIBUTE_MAPPER = {
    acquisitionPeriod: (k: string): string => `Energy.${k}_acq_period`,
    transmissionPeriod: (k: string): string => `Energy.${k}_tx_period`,
    recordPeriod: (k: string): string => `Energy.${k}_rec_period`,
    diagnosticPeriod: (k: string): string => `Energy.${k}_diag_period`,
    listeningTime: (k: string): string => `Energy.${k}_listening_time`
  };

  public static readonly SEVERITY_LEVEL_ATTRIBUTE_MAPPER = {
    defaultSensorLevel: 'GenParam.Default_sensor_level',
    fillingAlarmLevel: 'GenParam.Filling_alarm_level',
    maintenanceAlarmLevel: 'GenParam.Maintenance_alarm_level',
    transportAlarmLevel: 'GenParam.Transport_alarm_Level',
    energyAlarmLevel: 'GenParam.Energy_alarm_level',
    powerAlarmLevel: 'GenParam.Power_alarm_level'
  };

  public static readonly COMMUNICATION_ATTRIBUTE_MAPPER = {
    [ModemType.PLS62]: {
      radioBand_2G: 'COM.Radio_band_2G',
      radioBand_3G: 'COM.Radio_band_3G',
      radioBand_4G: 'COM.Radio_band_4G'
    },
    [ModemType.PLS63]: {
      radioBand_PLS63_2G: 'COM.Radio_band_PLS63_2G',
      radioBand_PLS63_3G: 'COM.Radio_band_PLS63_3G',
      radioBand_PLS63_4G_L: 'COM.Radio_band_PLS63_4G_L',
      radioBand_PLS63_4G_H_L: 'COM.Radio_band_PLS63_4G_H_L',
      radioBand_PLS63_4G_H_H: 'COM.Radio_band_PLS63_4G_H_H'
    },
    [ModemType.EXS82]: {
      radioBand_EXS_2G: 'COM.Radio_band_EXS_2G',
      radioBand_EXS_LTEM: 'COM.Radio_band_EXS_LTEM',
      radioBand_EXS_LTEM_H: 'COM.Radio_band_EXS_LTEM_H',
      radioBand_EXS_NBIOT: 'COM.Radio_band_EXS_NBIOT',
      radioBand_EXS_NBIOT_H: 'COM.Radio_band_EXS_NBIOT_H'
    },
    [ModemType.EHS8]: {
      radioBand_2G: 'COM.Radio_band_2G'
    }
  };

  public static readonly ATTRIBUTE_MAPPER = {
    generalInformation: {
      name: 'GenParam.Device_name'
    },
    callingHour: {
      startingDate: 'GenParam.Starting_date'
    },
    autonomy: {
      maxAutonomy: 'Autonomy.maxAutonomy',
      nbDaysWindow: 'Autonomy.nbDaysWindow',
      fillLevelFilter: 'Autonomy.fillLevelFilter',
      backupPercentage: 'Autonomy.Backup_percentage',
      recordPeriod: 'Autonomy.Record_period',
      indexBackupStatus: 'Autonomy.Index_backup_status',
      indexCylinderP: 'Autonomy.Index_cylinder_P',
      indexCylinderT: 'Autonomy.Index_cylinder_T',
      nbCylinderB50: 'Autonomy.Nb_cylinder_B50',
      nbV9packs: 'Autonomy.Nb_V9packs'
    },
    energy: {
      ...DeviceHelpers.ENERGY_FUNCTIONAL_ATTRIBUTE_MAPPER,
      fpmToEco1Threshold: 'Energy.FPM_to_ECO1_threshold',
      eco1ToEco2Threshold: 'Energy.ECO1_to_ECO2_threshold',
      eco2ToPsmThreshold: 'Energy.ECO2_to_PSM_threshold',
      energyModeHysteresis: 'Energy.Energy_mode_hysteresis',
      psmHysteresis: 'Energy.PSM_Hysteresis'
    },
    modbus: {
      role: 'GenParam.Modbus_role',
      slaveAddress: 'GenParam.Device_modbus_address'
    },
    communication: {
      modem: 'COM.modemModel',
      ...DeviceHelpers.COMMUNICATION_ATTRIBUTE_MAPPER
    },
    alarm: {
      ...DeviceHelpers.SEVERITY_LEVEL_ATTRIBUTE_MAPPER,
      defaultSensorClass: 'GenParam.Default_sensor_Class',
      fillingAlarmClass: 'GenParam.Filling_alarm_Class',
      maintenanceAlarmClass: 'GenParam.Maintenance_alarm_Class',
      transportAlarmClass: 'GenParam.Transport_alarm_Class',
      energyAlarmClass: 'GenParam.Energy_alarm_Class',
      powerSupplyAlarm: 'GenParam.Power_supply_alarm',
      powerAlarmClass: 'GenParam.Power_alarm_Class'
    },
    gps: {
      longitude: 'GenInfo.Dyn.GPS_longitude',
      latitude: 'GenInfo.Dyn.GPS_latitude',
      cardinalLongitude: 'GenInfo.Dyn.GPS_cardinal_longitude',
      cardinalLatitude: 'GenInfo.Dyn.GPS_cardinal_latitude'
    },
    hardware: {
      bleChipMacAddress: 'GenInfo.BLE_mac_address_string',
      bleSoftwareVersion: 'GenInfo.Soft_version',
      modemRevision: 'GenInfo.Modem_Revision',
      modemARevision: 'GenInfo.Modem_A_Revision',
      hardwareVersion: 'GenInfo.HardwareVersion'
    },
    tank: {
      rollingTankEnable: 'GenParam.RollingTankEnable',
      rollingTankPeriod: 'GenParam.RollingTankPeriod'
    }
  };

  public static readonly MAPPED_PROPERTIES = {
    modbus: {
      role: ModbusListName.LstModbusRole
    },
    communication: {
      modem: ModbusListName.LstModem
    },
    gps: {
      cardinalLongitude: ModbusListName.LstLong,
      cardinalLatitude: ModbusListName.LstLat
    },
    hardware: {
      hardwareVersion: ModbusListName.LstHardVersion
    }
  };

  public static readonly OPTIONAL_COMPUTED_ATTRIBUTES = ['indexBackupStatus', 'indexCylinderP', 'indexCylinderT'];
  public static readonly COMPUTED_ATTRIBUTES = [...DeviceHelpers.OPTIONAL_COMPUTED_ATTRIBUTES];

  public static readonly getConfigCount = (device: DeviceDetails, configKey: 'pending' | 'target') => {
    const currentConf = get(device, ['configuration', 'current'], {});
    const config = get(device, ['configuration', configKey], {});
    return Object.entries(config).reduce((acc, [id, value]) => {
      if (currentConf[id]?.v !== value) {
        acc++;
      }
      return acc;
    }, 0);
  };

  public static readonly getConfigurationStatus = (device: DeviceDetails): DeviceConfigurationStatus => {
    const pendingCount = DeviceHelpers.getConfigCount(device, 'pending');
    const targetCount = DeviceHelpers.getConfigCount(device, 'target');
    const isPending = !!pendingCount;
    const isTarget = !!targetCount;
    const targetStatus = DeviceHelpers.getConfigurationTargetStatus(device);
    const isNotPendingOrNotRetrieved = !isPending || targetStatus === DeviceConfigurationTargetStatus.RETRIEVED;
    const hasError = targetStatus === DeviceConfigurationTargetStatus.ERROR;
    const isPublished = isTarget && (!isNotPendingOrNotRetrieved || hasError);
    let status = DeviceConfigurationStatus.CURRENT;
    if (isPublished) {
      status = DeviceConfigurationStatus.PUBLISHED;
    } else if (isPending) {
      status = DeviceConfigurationStatus.PENDING;
    }
    return status;
  };

  public static readonly getConfigurationTargetStatus = (device: DeviceDetails): DeviceConfigurationTargetStatus => get(device, 'configuration.targetStatus');

  public static getStatusByKey(key: string, device: DeviceDetails, pendingKeys: string[], targetKeys: string[]) {
    let status = DeviceConfigurationStatus.CURRENT;
    const configurationStatus = DeviceHelpers.getConfigurationStatus(device);
    if (configurationStatus === DeviceConfigurationStatus.PENDING && pendingKeys.includes(key)) {
      status = DeviceConfigurationStatus.PENDING;
    } else if (configurationStatus === DeviceConfigurationStatus.PUBLISHED && targetKeys.includes(key)) {
      status = DeviceConfigurationStatus.PUBLISHED;
    }
    return status;
  }

  public static readonly getLastCallTime = (device: DeviceDetails): Duration | null => {
    const energy: EnergySettings = DeviceHelpers.getEnergySettings(device);
    const currentEnergy = get(energy, [energy.mode?.label], {}) as EnergyModeSettings;
    const lastCallStatusTimeStr = get(device, 'lastCallStatus.datetime');
    const currentDateTime = moment();
    const baseLastCallStatusTime = moment(lastCallStatusTimeStr);
    const isValidDate = !!lastCallStatusTimeStr && baseLastCallStatusTime.isValid();
    const hasTransmissionPeriod = currentEnergy.transmissionPeriod !== undefined && currentEnergy.transmissionPeriod !== null;
    if (isValidDate && hasTransmissionPeriod) {
      const lastCallStatusDateTime = baseLastCallStatusTime.clone().add(currentEnergy.transmissionPeriod, 'seconds');
      const lastCallTimeStamp = lastCallStatusDateTime.toDate().getTime();
      const currentTimestamp = currentDateTime.toDate().getTime();
      // Calculate diff only if the last call date time is greater than actual time
      if (currentTimestamp < lastCallTimeStamp) {
        return DateIntervalUtils.diffDates(currentDateTime, lastCallStatusDateTime);
      }
    }
    return null;
  };

  public static readonly canSendRefreshCommand = (device: DeviceDetails): boolean => {
    const operator = get(device, ['communication', 'operator']);
    return DeviceHelpers.REFRESH_COMMAND_ELIGIBLE_OPERATORS.includes(operator);
  };

  public static readonly mergeConfigValues = (device: DeviceDetails, configKey: 'pending' | 'target') => {
    const currentConf = cloneDeep(get(device, ['configuration', 'current'], {}));
    const config = get(device, ['configuration', configKey], {});
    Object.entries(config).forEach(([key, value]) => {
      if (!currentConf[key]) {
        currentConf[key] = {
          v: value
        };
      } else if (currentConf[key].v !== value) {
        currentConf[key].v = value;
      }
    });
    return currentConf;
  };

  public static readonly mergeDeviceConfiguration = (device: DeviceDetails): DeviceDetails => {
    const configuration = cloneDeep(get(device, 'configuration'));
    const status = DeviceHelpers.getConfigurationStatus(device);
    if (status === DeviceConfigurationStatus.PENDING) {
      configuration.current = DeviceHelpers.mergeConfigValues(device, 'pending');
    } else if (status === DeviceConfigurationStatus.PUBLISHED) {
      configuration.current = DeviceHelpers.mergeConfigValues(device, 'target');
    }
    return { ...cloneDeep(device), configuration };
  };

  public static readonly getDeviceSettings = (device: DeviceDetails, modbusTable: ModbusTable, modemRadioBands: ModemRadioBands): DeviceSettings => {
    const updatedDevice = DeviceHelpers.mergeDeviceConfiguration(device);
    const generalInformation: DeviceGeneralInformationSettings = DeviceHelpers.getGeneralInformationSettings(updatedDevice);
    const callingHour: DeviceCallingHourSettings = DeviceHelpers.getCallingHourSettings(updatedDevice, modbusTable);
    const autonomy: DeviceAutonomySettings = DeviceHelpers.getAutonomySettings(updatedDevice);
    const energy: EnergySettings = DeviceHelpers.getEnergySettings(updatedDevice);
    const modbus: ModbusSettings = DeviceHelpers.getModbusSettings(updatedDevice, modbusTable);
    const communication: CommunicationSettings = DeviceHelpers.getCommunicationSettings(updatedDevice, modbusTable, modemRadioBands);
    const alarm: AlarmSettings = DeviceHelpers.getAlarmSettings(updatedDevice);
    const gps: GpsSettings = DeviceHelpers.getGpsSettings(updatedDevice, modbusTable);
    const hardware: HardwareSettings = DeviceHelpers.getHardwareSettings(updatedDevice, modbusTable);
    const tank: TankSettings = DeviceHelpers.getTankSettings(updatedDevice);
    return {
      generalInformation,
      callingHour,
      autonomy,
      energy,
      modbus,
      communication,
      alarm,
      gps,
      hardware,
      tank
    };
  };

  public static readonly getAutonomySettings = (device: DeviceDetails): DeviceAutonomySettings => {
    const valueGetter = (key: string) => ['current', key, 'v'];

    const maxAutonomy = get(device.configuration, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.autonomy.maxAutonomy));
    const nbDaysWindow = get(device.configuration, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.autonomy.nbDaysWindow));
    const fillLevelFilter = get(device.configuration, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.autonomy.fillLevelFilter));
    const backupPercentage = get(device.configuration, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.autonomy.backupPercentage));
    const recordPeriod = get(device.configuration, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.autonomy.recordPeriod));
    const nbCylinderB50 = get(device.configuration, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.autonomy.nbCylinderB50));
    const nbV9packs = get(device.configuration, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.autonomy.nbV9packs));
    const indexBackupStatus = DeviceHelpers.getComputedValue(['autonomy'], 'indexBackupStatus', device.configuration, valueGetter);
    const indexCylinderP = DeviceHelpers.getComputedValue(['autonomy'], 'indexCylinderP', device.configuration, valueGetter);
    const indexCylinderT = DeviceHelpers.getComputedValue(['autonomy'], 'indexCylinderT', device.configuration, valueGetter);

    return {
      maxAutonomy,
      nbDaysWindow,
      fillLevelFilter,
      backupPercentage,
      recordPeriod,
      indexBackupStatus,
      indexCylinderP,
      indexCylinderT,
      nbCylinderB50,
      nbV9packs
    };
  };

  public static readonly getGeneralInformationSettings = (device: DeviceDetails): DeviceGeneralInformationSettings => {
    const valueGetter = (key: string) => ['configuration', 'current', key, 'v'];
    const name = get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.generalInformation.name));
    return {
      name
    };
  };

  public static readonly getCallingHourSettings = (device: DeviceDetails, modbusTable: ModbusTable): DeviceCallingHourSettings => {
    const valueGetter = (key: string) => ['configuration', 'current', key, 'v'];
    const startingDate = get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.callingHour.startingDate));
    const startingHour = DeviceHelpers.getCallingHour(startingDate, modbusTable);
    return {
      startingDate,
      startingHour
    };
  };

  public static readonly getCallingHour = (startingDate: string, modbusTable: ModbusTable): ModbusListItem => {
    const callingHours = ModbusTableHelpers.getCallingHours(modbusTable);
    const h = moment(startingDate).utc().hours();
    return (
      callingHours?.find(({ value }) => value === h) ?? {
        label: `${h}h UTC`,
        value: h
      }
    );
  };

  public static readonly getHardwareSettings = (device: DeviceDetails, modbusTable: ModbusTable): HardwareSettings => {
    const valueGetter = (key: string) => ['configuration', 'current', key, 'v'];
    const hardwareVersion = get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.hardware.hardwareVersion));
    return {
      bleChipMacAddress: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.hardware.bleChipMacAddress)),
      bleSoftwareVersion: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.hardware.bleSoftwareVersion)),
      modemRevision: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.hardware.modemRevision)),
      modemARevision: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.hardware.modemARevision)),
      hardwareVersion: ModbusTableHelpers.getListItemByListName(modbusTable, DeviceHelpers.MAPPED_PROPERTIES.hardware.hardwareVersion, hardwareVersion)
    };
  };

  public static readonly getTankSettings = (device: DeviceDetails): TankSettings => {
    const valueGetter = (key: string) => ['configuration', 'current', key, 'v'];
    return {
      rollingTankEnable: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.tank.rollingTankEnable)),
      rollingTankPeriod: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.tank.rollingTankPeriod))
    };
  };

  public static readonly getGpsSettings = (device: DeviceDetails, modbusTable: ModbusTable): GpsSettings => {
    const valueGetter = (key: string) => ['configuration', 'current', key, 'v'];
    const cardinalLongitude = get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.gps.cardinalLongitude));
    const cardinalLatitude = get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.gps.cardinalLatitude));
    return {
      longitude: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.gps.longitude)),
      latitude: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.gps.latitude)),
      cardinalLongitude: ModbusTableHelpers.getListItemByListName(modbusTable, DeviceHelpers.MAPPED_PROPERTIES.gps.cardinalLongitude, cardinalLongitude),
      cardinalLatitude: ModbusTableHelpers.getListItemByListName(modbusTable, DeviceHelpers.MAPPED_PROPERTIES.gps.cardinalLatitude, cardinalLatitude)
    };
  };

  public static readonly getAlarmSettings = (device: DeviceDetails): AlarmSettings => {
    const valueGetter = (key: string) => ['configuration', 'current', key, 'v'];
    return {
      defaultSensorClass: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.defaultSensorClass)),
      defaultSensorLevel: ModbusTableHelpers.getSeverityByValue(get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.defaultSensorLevel))),
      fillingAlarmClass: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.fillingAlarmClass)),
      fillingAlarmLevel: ModbusTableHelpers.getSeverityByValue(get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.fillingAlarmLevel))),
      maintenanceAlarmClass: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.maintenanceAlarmClass)),
      maintenanceAlarmLevel: ModbusTableHelpers.getSeverityByValue(get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.maintenanceAlarmLevel))),
      transportAlarmClass: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.transportAlarmClass)),
      transportAlarmLevel: ModbusTableHelpers.getSeverityByValue(get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.transportAlarmLevel))),
      energyAlarmClass: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.energyAlarmClass)),
      energyAlarmLevel: ModbusTableHelpers.getSeverityByValue(get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.energyAlarmLevel))),
      powerSupplyAlarm: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.powerSupplyAlarm)),
      powerAlarmClass: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.powerAlarmClass)),
      powerAlarmLevel: ModbusTableHelpers.getSeverityByValue(get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.alarm.powerAlarmLevel)))
    };
  };

  public static readonly getEnergySettings = (device: DeviceDetails, energyMode?: EnergyMode): EnergySettings => {
    const updatedDevice = DeviceHelpers.mergeDeviceConfiguration(device);
    const powerModeValue = DeviceHelpers.getPowerModeLastValue(updatedDevice);
    let mode = DeviceHelpers.ENERGY_MODES.find((v) => v.value === powerModeValue);
    if (energyMode) {
      mode = DeviceHelpers.ENERGY_MODES.find((v) => v.label === energyMode.toString());
    }
    const valueGetter = (key: string) => ['configuration', 'current', key, 'v'];
    return DeviceHelpers.ENERGY_MODES.reduce(
      (acc, item) => ({
        ...acc,
        mode,
        [item.label]: {
          mode: item,
          acquisitionPeriod: get(updatedDevice, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.energy.acquisitionPeriod(item?.label))),
          transmissionPeriod: get(updatedDevice, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.energy.transmissionPeriod(item?.label))),
          recordPeriod: get(updatedDevice, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.energy.recordPeriod(item?.label))),
          diagnosticPeriod: get(updatedDevice, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.energy.diagnosticPeriod(item?.label))),
          listeningTime: get(updatedDevice, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.energy.listeningTime(item?.label))),
          fpmToEco1Threshold: get(updatedDevice, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.energy.fpmToEco1Threshold)),
          eco1ToEco2Threshold: get(updatedDevice, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.energy.eco1ToEco2Threshold)),
          eco2ToPsmThreshold: get(updatedDevice, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.energy.eco2ToPsmThreshold)),
          energyModeHysteresis: get(updatedDevice, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.energy.energyModeHysteresis)),
          psmHysteresis: get(updatedDevice, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.energy.psmHysteresis))
        }
      }),
      {}
    ) as EnergySettings;
  };

  public static readonly toEnergyModes = (energyModes: EnergyModes): ModbusListItem[] =>
    Object.keys(energyModes).map((value) => DeviceHelpers.ENERGY_MODES.find(({ label }) => label === value));

  public static readonly toEnergySettings = (
    energyModes: EnergyModes
  ): {
    [key: string]: {
      key: string;
      value: Partial<EnergySettings>;
    }[];
  } =>
    DeviceHelpers.toEnergyModes(energyModes).reduce((acc, mode) => {
      const element = energyModes[mode.label];
      const values = Object.keys(element).map((key) => {
        const item = get(element, [key]);
        return {
          key,
          value: {
            mode,
            acquisitionPeriod: get(item, DeviceHelpers.ATTRIBUTE_MAPPER.energy.acquisitionPeriod(mode.label)),
            transmissionPeriod: get(item, DeviceHelpers.ATTRIBUTE_MAPPER.energy.transmissionPeriod(mode.label)),
            recordPeriod: get(item, DeviceHelpers.ATTRIBUTE_MAPPER.energy.recordPeriod(mode.label)),
            diagnosticPeriod: get(item, DeviceHelpers.ATTRIBUTE_MAPPER.energy.diagnosticPeriod(mode.label)),
            listeningTime: get(item, DeviceHelpers.ATTRIBUTE_MAPPER.energy.listeningTime(mode.label))
          }
        };
      });
      return { ...acc, [mode.label]: values };
    }, {});

  public static readonly getModbusSettings = (device: DeviceDetails, modbusTable: ModbusTable): ModbusSettings => {
    const valueGetter = (key: string) => ['configuration', 'current', key, 'v'];
    const role = get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.modbus.role));
    return {
      role: ModbusTableHelpers.getListItemByListName(modbusTable, DeviceHelpers.MAPPED_PROPERTIES.modbus.role, role),
      slaveAddress: get(device, valueGetter(DeviceHelpers.ATTRIBUTE_MAPPER.modbus.slaveAddress)) as number
    };
  };

  public static getCommunicationModemByType = (modemType: string) =>
    DeviceHelpers.MODEM_TYPES.find(({ label }) => label?.toLowerCase()?.trim() === modemType?.toLowerCase()?.trim());

  public static readonly getCommunicationSettings = (
    device: DeviceDetails,
    modbusTable: ModbusTable,
    modemRadioBands: ModemRadioBands
  ): CommunicationSettings => {
    const value = get(device, ['configuration', 'current', DeviceHelpers.ATTRIBUTE_MAPPER.communication.modem, 'v']);
    const modemItem = ModbusTableHelpers.getListItemByListName(modbusTable, DeviceHelpers.MAPPED_PROPERTIES.communication.modem, value);
    const modem: ModbusListItem = modemItem ?? { value, label: ModemType.EHS8 };
    const valueGetter = (k: string) => ['configuration', 'current', k, 'v'];
    const values = Object.entries(DeviceHelpers.COMMUNICATION_ATTRIBUTE_MAPPER).reduce(
      (acc, [modemType, v1]) => ({
        ...acc,
        [modemType]: Object.entries(v1).reduce((acc1, [attr, key]) => {
          const decimal = get(device, valueGetter(key));
          const m = DeviceHelpers.getCommunicationModemByType(modemType);
          return {
            ...acc1,
            [attr]: DeviceHelpers.getCommunicationRadioBand(key, decimal, m, modemRadioBands)
          };
        }, {})
      }),
      {}
    );
    return {
      modem,
      ...values
    } as CommunicationSettings;
  };

  public static readonly getCommunicationRadioBandsList = (
    settings: DeviceSettings,
    configurationState: DeviceConfigurationState,
    modemRadioBands: ModemRadioBands
  ): CommunicationList => {
    const getRadioBandsByKey = (modem: ModbusListItem, key: string): CommunicationRadioBandListItem[] => {
      const item = get(settings, ['communication', modem?.label, key]);
      const state = get(configurationState, ['communication', 'attributes', modem?.label, key]);
      const oldItem = DeviceHelpers.getCommunicationRadioBand(
        get(DeviceHelpers.COMMUNICATION_ATTRIBUTE_MAPPER, [modem?.label, key]),
        state?.oldValue,
        modem,
        modemRadioBands
      );
      return item?.radioBands?.reduce((acc, value, index) => {
        if (value !== 'unused' && value !== null && value !== undefined) {
          const oldBinary = oldItem?.binaryDigits[index];
          const newBinary = item?.binaryDigits[index];
          return [
            ...acc,
            {
              value,
              status: oldBinary === newBinary ? DeviceConfigurationStatus.CURRENT : state?.status,
              checked: newBinary === 1
            }
          ];
        }
        return [...acc];
      }, []);
    };
    return Object.entries(DeviceHelpers.COMMUNICATION_ATTRIBUTE_MAPPER).reduce(
      (acc, [modemType, v1]) => ({
        ...acc,
        [modemType]: Object.entries(v1).reduce((acc1, [key]) => {
          const m = DeviceHelpers.getCommunicationModemByType(modemType);
          const radioBands = getRadioBandsByKey(m, key);
          const obj = {
            key,
            radioBands
          };
          return [...acc1, obj];
        }, [])
      }),
      {}
    );
  };

  public static readonly getCommunicationRadioBand = (
    key: string,
    decimal: number,
    modem: ModbusListItem,
    modemRadioBands: ModemRadioBands
  ): CommunicationRadioBand => {
    const radioBands = get(modemRadioBands, [modem?.label, key, 'radioBands'], []);
    const length = radioBands.length;
    let binaryDigits = Array.from({ length }, () => 0);
    let binary = '';
    if (decimal !== null && decimal !== undefined) {
      binary = DecimalUtils.toBinary(Number(decimal));
      const arr = Array.from(binary)
        .map((v: string) => parseInt(v))
        .reverse();
      binaryDigits = ArrayUtils.padEnd(arr, length, 0);
    }
    return {
      decimal,
      binary,
      binaryDigits,
      radioBands
    };
  };

  public static readonly getEnergyState = (
    device: DeviceDetails,
    modbusTable: ModbusTable
  ): {
    [key: string]: ModbusTablePropertyState;
  } => {
    const energyModes = DeviceHelpers.ENERGY_MODES.filter((v) => v.value !== 3 && v.value !== 4).map(({ label }) => label as EnergyMode);
    return energyModes.reduce((acc, energyMode) => {
      const state = Object.keys(DeviceHelpers.ATTRIBUTE_MAPPER.energy).reduce(
        (acc1, attrName) => ({
          ...acc1,
          [attrName]: this.getPropertyState('energy', attrName, device, modbusTable, energyMode)
        }),
        {}
      );
      return { ...acc, [energyMode]: state };
    }, {});
  };

  public static readonly getCommunicationState = (
    device: DeviceDetails,
    modbusTable: ModbusTable
  ): {
    [key: string]: ModbusTablePropertyState;
  } => {
    const modemTypes = DeviceHelpers.MODEM_TYPES.filter((v) => v.value !== 0).map(({ label }) => label.toString());
    return Object.entries(DeviceHelpers.ATTRIBUTE_MAPPER.communication).reduce((acc, [key, v]) => {
      if (modemTypes.includes(key)) {
        const state = Object.entries(v).reduce(
          (acc1, [attrName]) => ({
            ...acc1,
            [attrName]: this.getPropertyState(['communication', key], attrName, device, modbusTable)
          }),
          {}
        );
        return { ...acc, [key]: state };
      }
      return {
        ...acc,
        [key]: DeviceHelpers.getPropertyState('communication', key, device, modbusTable)
      };
    }, {});
  };

  public static readonly getPropertyState = (
    category: string | string[],
    attrName: string,
    device: DeviceDetails,
    modbusTable: ModbusTable,
    energyMode?: EnergyMode
  ): ModbusTablePropertyState => {
    let status = DeviceConfigurationStatus.CURRENT;
    const categoryPath = [].concat(category);
    const attrPath = get(DeviceHelpers.ATTRIBUTE_MAPPER, categoryPath.concat([attrName]));

    let newCollectionName = 'pending';
    const deviceStatus = DeviceHelpers.getConfigurationStatus(device);
    if (deviceStatus === DeviceConfigurationStatus.PUBLISHED) {
      newCollectionName = 'target';
    }

    const valueGetter = (k: string) => ['current', k, 'v'];

    const configuration = get(device, ['configuration'], {}) as DeviceConfiguration;

    let formatterAttrPath = attrPath;
    const energyFields = Object.keys(DeviceHelpers.ENERGY_FUNCTIONAL_ATTRIBUTE_MAPPER);
    if (category === 'energy' && energyFields.includes(attrName)) {
      formatterAttrPath = attrPath(energyMode);
    }
    let oldValue = get(configuration, ['current', formatterAttrPath, 'v'], null);
    const newValue = get(configuration, [newCollectionName, formatterAttrPath], null);

    if (DeviceHelpers.COMPUTED_ATTRIBUTES.includes(attrName)) {
      // In case computed values are impacted by removed variables
      // We change their status to DELETED
      // oldValue === Old index
      // newValue === new index

      const currentIndexes = DeviceVariableHelpers.getAllVariableNames(configuration, true).map((item) => item.index);

      if (oldValue === 0 && newValue === null) {
        oldValue = null;
      }

      const noOldValueAndHasNewValue = oldValue === null && newValue !== null;
      const noNewValueAndHasOldValue = newValue === null && oldValue !== null;
      const hasBoth = oldValue !== null && newValue !== null;

      const noOldIndexAndNewIndexRemoved = noOldValueAndHasNewValue && !currentIndexes.includes(newValue);
      const hasBothAndNewIndexRemoved = hasBoth && !currentIndexes.includes(newValue);
      const noNewIndexAndOldIndexRemoved = noNewValueAndHasOldValue && !currentIndexes.includes(oldValue);

      // Optional variables could be unused
      // In this case variable index will be 0
      const isVariablePUnused = DeviceHelpers.OPTIONAL_COMPUTED_ATTRIBUTES.includes(attrName) && ((oldValue === 0 && newValue === null) || newValue === 0);

      // Unused variables should not be considered as removed
      if (!isVariablePUnused && (noOldIndexAndNewIndexRemoved || noNewIndexAndOldIndexRemoved || hasBothAndNewIndexRemoved)) {
        status = DeviceConfigurationStatus.DELETED;
      }

      // Set old computed value
      oldValue = DeviceHelpers.getComputedValue(categoryPath, attrName, configuration, valueGetter);
    }

    if (status !== DeviceConfigurationStatus.DELETED && newValue !== null && oldValue !== newValue) {
      status = deviceStatus;
    }

    let displayValue = oldValue;
    if (Object.keys(get(DeviceHelpers.MAPPED_PROPERTIES, categoryPath, {})).includes(attrName)) {
      displayValue = ModbusTableHelpers.getListItemByListName(
        modbusTable,
        get(DeviceHelpers.MAPPED_PROPERTIES, categoryPath.concat([attrName]), 'listName'),
        oldValue
      )?.label;
    }

    const severityFields = Object.keys(DeviceHelpers.SEVERITY_LEVEL_ATTRIBUTE_MAPPER);
    if (severityFields.includes(attrName)) {
      displayValue = ModbusTableHelpers.getSeverities().find((item) => item.value === oldValue)?.label;
    }

    return {
      oldValue,
      newValue,
      displayValue,
      status,
      attrName,
      key: formatterAttrPath
    };
  };

  public static readonly getComputedValue = (
    categoryPath: string[],
    attrName: string,
    configuration: DeviceConfiguration,
    valueGetter: (k: string) => string[]
  ) => {
    const key = get(DeviceHelpers.ATTRIBUTE_MAPPER, categoryPath.concat([attrName]));
    const value = get(configuration, valueGetter(key));
    const computedKey = `Var${StringUtils.padWith(value, 2, '0')}.${DeviceVariableHelpers.ATTRIBUTE_MAPPER.name}`;
    // In case computed value found and empty return initial value
    // Otherwise return calculated value
    const result = get(configuration, valueGetter(computedKey));
    if (result !== undefined) {
      if (result === '') {
        return value;
      }
      return result;
    }
    // In case computed value is not found
    // Look for related index in all configuration
    return get(configuration, ['target', computedKey], get(configuration, ['pending', computedKey], get(configuration, ['current', computedKey, 'v'], value)));
  };

  public static getDeviceConfigurationState = (device: DeviceDetails, modbusTable: ModbusTable): DeviceConfigurationState => {
    const status = DeviceHelpers.getConfigurationStatus(device);
    return Object.keys(DeviceHelpers.ATTRIBUTE_MAPPER).reduce(
      (acc, key) => {
        let isPendingOrTarget = false;
        let attributes = {};

        // Generate custom energy mode state
        if (key === 'energy') {
          attributes = DeviceHelpers.getEnergyState(device, modbusTable);
          isPendingOrTarget = Object.values(attributes)
            .map((item) => Object.values(item).some((v) => v.status === DeviceConfigurationStatus.PENDING || v.status === DeviceConfigurationStatus.PUBLISHED))
            .some((v) => !!v);
        } // Generate custom communication state
        else if (key === 'communication') {
          const modemTypes = DeviceHelpers.MODEM_TYPES.filter((v) => v.value !== 0).map(({ label }) => label.toString());
          attributes = DeviceHelpers.getCommunicationState(device, modbusTable);
          isPendingOrTarget = Object.entries(attributes)
            .map(([k, item]) => {
              if (modemTypes.includes(k)) {
                return Object.values(item).some((v) => v.status === DeviceConfigurationStatus.PENDING || v.status === DeviceConfigurationStatus.PUBLISHED);
              }
              return get(item, 'status') !== DeviceConfigurationStatus.CURRENT;
            })
            .some((v) => !!v);
        } // Generate default attribute state
        else {
          attributes = Object.keys(DeviceHelpers.ATTRIBUTE_MAPPER[key]).reduce(
            (acc1, attrName) => ({
              ...acc1,
              [attrName]: DeviceHelpers.getPropertyState(key, attrName, device, modbusTable)
            }),
            {}
          );
          isPendingOrTarget = Object.values(attributes).some(
            (item) => get(item, 'status') === DeviceConfigurationStatus.PENDING || get(item, 'status') === DeviceConfigurationStatus.PUBLISHED
          );
        }
        return {
          ...acc,
          [key]: {
            status: isPendingOrTarget ? status : DeviceConfigurationStatus.CURRENT,
            attributes
          }
        };
      },
      {
        status
      } as DeviceConfigurationState
    );
  };

  public static readonly cleanUpConfiguration = (
    deviceConfiguration: DeviceConfiguration,
    configuration: { [key: string]: unknown },
    targetConfiguration: 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Override pending configuration
    const config = {
      ...get(deviceConfiguration, [targetConfiguration], {}),
      ...configuration
    };

    // Clean up target configuration
    return Object.keys(config).reduce((acc, key) => {
      const currentValue = get(deviceConfiguration, ['current', key, 'v']);
      if (!(config[key] === currentValue)) {
        acc[key] = config[key];
      }
      return { ...acc };
    }, {});
  };

  public static readonly isModemOfTypeUnknown = (modemValue: number): boolean => modemValue === 0;

  public static readonly isModemOfTypePLS62 = (modemValue: number): boolean => modemValue === 1;

  public static readonly isModemOfTypeEXS82 = (modemValue: number): boolean => modemValue === 2;

  public static readonly isModemOfTypePLS63 = (modemValue: number): boolean => modemValue === 3;

  public static readonly isModemOfTypeESH8 = (modemValue: number): boolean => modemValue === null || modemValue === undefined;

  public static readonly getAvailableNextIndex = (indexes: number[], length: number): number => {
    const sortedIndexes = indexes.sort((a, b) => a - b);
    const array = Array.from({ length }, (_, i) => i + 1);
    return sortedIndexes.length ? array.find((n, index) => n !== sortedIndexes[index]) : 1;
  };

  public static readonly getAvailableIndexes = (indexes: number[], length: number, currentIndex?: number): ModbusListItem[] => {
    const allIndexes = Array.from({ length }, (_, i) => i + 1);
    const eligibleIndexes = allIndexes.filter((index) => !indexes.includes(index));
    // Used for edit mode
    // Should contain current index in the list
    // When edit mode otherwise current index field will be undefined
    if (currentIndex) {
      eligibleIndexes.push(currentIndex);
    }
    return uniq(eligibleIndexes).map((value) => ({
      label: `${value}`,
      value
    }));
  };

  public static readonly parseIndex = (key: string): number => parseInt(key.replace(/\D/g, ''));

  public static readonly reduceConfiguration = (
    configuration: DeviceConfiguration,
    entityKey: string,
    targetConfiguration: 'current' | 'pending' | 'target',
    retrieveProperties: boolean
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const config = cloneDeep(get(configuration, [targetConfiguration], {}));
    // Remove updated properties that match variable index
    return Object.entries(config).reduce((acc, [key]) => {
      if (key.includes(entityKey) === retrieveProperties) {
        if (targetConfiguration === 'current') {
          acc[key] = get(config, [key, 'v']);
        } else {
          acc[key] = get(config, [key]);
        }
      }
      return { ...acc };
    }, {});
  };

  private static getPowerModeLastValue = (device: DeviceDetails): number => get(device, ['expandedVariables', 'em', 'lastValue', 'value']);
}
