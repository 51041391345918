import { NgClass, UpperCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, MatIcon, MatButtonModule, MatMenuModule, UpperCasePipe, TranslateModule, NgClass],
  selector: 'iot-platform-ui-simple-card',
  templateUrl: './simple-card.component.html',
  styleUrls: ['./simple-card.component.scss']
})
export class SimpleCardComponent extends BaseCardComponent<unknown> {}
