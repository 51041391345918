import { Directive, effect, model, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class AbstractDebounceDirective implements OnDestroy {
  debounceTime = model<number>(300);

  protected emitEvent$: Subject<any>;
  protected subscription$: Subject<void>;

  constructor() {
    this.emitEvent$ = new Subject<any>();
    this.subscription$ = new Subject<void>();
    effect(() => {
      const delay = this.debounceTime();
      this.ngOnDestroy();
      this.initEvent(delay);
    });
  }

  initEvent(delay: number): void {
    this.emitEvent$.pipe(takeUntil(this.subscription$), debounceTime(delay), distinctUntilChanged()).subscribe((value) => this.onEvent(value));
  }

  abstract onEvent(value: any): void;

  ngOnDestroy(): void {
    this.subscription$.next();
    this.subscription$.complete();
  }
}
