import { Component, ContentChild, model, Signal, signal } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { TabContentComponent } from '../tab-content/tab-content.component';
import { TabHeaderComponent } from '../tab-header/tab-header.component';

@Component({
  selector: 'iot-platform-ui-tab',
  standalone: true,
  template: `
    @if (active()) {
      <ng-content />
    }
  `
})
export class TabComponent {
  active = model<boolean>(false);
  disabled = model<boolean>(false);

  id: Signal<string> = signal(uuidv4());

  @ContentChild(TabContentComponent) public content!: TabContentComponent;
  @ContentChild(TabHeaderComponent) public header!: TabHeaderComponent;
}
