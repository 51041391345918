import { NgModule } from '@angular/core';
import { TimelineListItemComponent } from './timeline-list-item/timeline-list-item.component';
import { TimelineListComponent } from './timeline-list.component';

const COMPONENTS = [TimelineListComponent, TimelineListItemComponent];

@NgModule({
  imports: COMPONENTS,
  exports: COMPONENTS
})
export class TimelineListModule {}
