import { inject, Injectable, Signal } from '@angular/core';
import { TrackingData } from '@iot-platform/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectAdditionalTrackingData } from '../selectors/tracking.selectors';

@Injectable({
  providedIn: 'root'
})
export class TrackingFacade {
  protected readonly store: Store = inject(Store);

  additionalTrackingData$: Observable<TrackingData> = this.store.select(selectAdditionalTrackingData);
  additionalTrackingData: Signal<TrackingData> = this.store.selectSignal(selectAdditionalTrackingData);
}
