import { TrackingEventLabels as BaseTrackingEventLabels } from '../../common/tracking/tracking-event-labels.model';

export const TrackingEventLabels = {
  ...BaseTrackingEventLabels,
  // Firmware
  NAVIGATE_TO_FIRMWARES_MASTER_VIEW: 'navigate_to_firmwares_master_view',
  OPEN_FIRMWARE_DETAILS: 'open_firmware_details',
  // Sensor
  NAVIGATE_TO_SENSORS_MASTER_VIEW: 'navigate_to_sensors_master_view',
  OPEN_SENSOR_DETAILS: 'open_sensor_details',
  // Device
  NAVIGATE_TO_DEVICES_MASTER_VIEW: 'navigate_to_devices_master_view',
  PUBLISH_DEVICE_GLOBAL_CONFIGURATION: 'publish_device_global_configuration',
  CANCEL_DEVICE_GLOBAL_CONFIGURATION: 'cancel_device_global_configuration',
  OPEN_MODBUS_EDIT_FORM: 'open_modbus_edit_form',
  UPDATE_MODBUS_CONFIGURATION: 'update_modbus_configuration',
  OPEN_ALARM_EDIT_FORM: 'open_alarm_edit_form',
  OPEN_AUTONOMY_EDIT_FORM: 'open_autonomy_edit_form',
  UPDATE_ALARM_CONFIGURATION: 'update_alarm_configuration',
  UPDATE_AUTONOMY_CONFIGURATION: 'update_autonomy_configuration',
  OPEN_ENERGY_MODE_EDIT_FORM: 'open_energy_mode_edit_form',
  UPDATE_ENERGY_MODE_CONFIGURATION: 'update_energy_mode_configuration',
  OPEN_COMMUNICATION_EDIT_FORM: 'open_communication_edit_form',
  UPDATE_COMMUNICATION_CONFIGURATION: 'update_communication_configuration',
  DEVICE_CONFIGURATION_APPLY_TEMPLATE: 'device_configuration_apply_template',
  DEVICE_CONFIGURATION_COPY_CONFIGURATION: 'device_configuration_copy_configuration',
  SAVE_DEVICE_ROLLING_PERIOD: 'save_device_rolling_period',
  OPEN_DEVICE_NAME_EDIT_FORM: 'open_device_name_edit_form',
  OPEN_DEVICE_STARTING_DATE_EDIT_FORM: 'open_device_starting_date_edit_form',
  OPEN_DEVICE_AFFILIATE_EDIT_FORM: 'open_device_affiliate_edit_form',
  OPEN_DEVICE_ENDPOINT_EDIT_FORM: 'open_device_endpoint_edit_form',
  // Device overview
  NAVIGATE_TO_DEVICE_OVERVIEW_PAGE: 'navigate_to_device_info_page',
  UPDATE_DEVICE_OVERVIEW_GENERAL_INFORMATION_CONFIGURATION: 'update_device_overview_general_information_configuration',
  CANCEL_PENDING_CONFIGURATION: 'cancel_pending_configuration',
  CANCEL_DELETE_CONFIGURATION: 'cancel_delete_configuration',
  // Device variable
  DELETE_DEVICE_VARIABLE: 'delete_device_variable',
  BULK_DELETE_DEVICE_VARIABLES: 'bulk_delete_device_variables',
  OPEN_DEVICE_VARIABLE_DETAILS: 'open_device_variable_details',
  OPEN_DEVICE_VARIABLE_CREATE_FORM: 'open_device_variable_create_form',
  OPEN_DEVICE_VARIABLE_EDIT_FORM: 'open_device_variable_edit_form',
  CREATE_DEVICE_VARIABLE: 'create_device_variable',
  EDIT_DEVICE_VARIABLE: 'edit_device_variable',
  // Device tank
  OPEN_DEVICE_TANK_CREATE_FORM: 'open_device_tank_create_form',
  CREATE_DEVICE_TANK: 'create_device_tank',
  OPEN_DEVICE_TANK_EDIT_FORM: 'open_device_tank_edit_form',
  UPDATE_DEVICE_TANK: 'update_device_tank',
  DELETE_DEVICE_TANK: 'delete_device_tank',
  // Device modbus request table
  OPEN_DEVICE_MODBUS_REQUEST_TABLE_CREATE_FORM: 'open_device_modbus_request_table_create_form',
  CREATE_DEVICE_MODBUS_REQUEST_TABLE: 'create_device_modbus_request_table',
  OPEN_DEVICE_MODBUS_REQUEST_TABLE_EDIT_FORM: 'open_device_modbus_request_table_edit_form',
  UPDATE_DEVICE_MODBUS_REQUEST_TABLE: 'update_device_modbus_request_table',
  DELETE_DEVICE_MODBUS_REQUEST_TABLE: 'delete_device_modbus_request_table',
  // Device template
  NAVIGATE_TO_DEVICE_TEMPLATES_MASTER_VIEW: 'navigate_to_device_templates_master_view',
  OPEN_DEVICE_TEMPLATE_DETAILS: 'open_device_template_details',
  DELETE_DEVICE_TEMPLATE: 'delete_device_template',
  OPEN_DEVICE_TEMPLATE_CREATE_FORM: 'open_device_template_create_form',
  CREATE_DEVICE_TEMPLATE: 'create_device_template',
  OPEN_DEVICE_TEMPLATE_EDIT_FORM: 'open_device_template_edit_form',
  UPDATE_DEVICE_TEMPLATE: 'update_device_template',
  OPEN_DEVICE_TEMPLATE_IMPORT_FROM_DEVICE_FORM: 'open_device_template_import_from_device_form',
  OPEN_DEVICE_TEMPLATE_IMPORT_FROM_TEMPLATE_FORM: 'open_device_template_import_from_template_form',
  // Device template overview
  NAVIGATE_TO_DEVICE_TEMPLATE_OVERVIEW_PAGE: 'navigate_to_device_template_overview_page'
};
