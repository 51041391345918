import { computed, inject, Injectable, Signal } from '@angular/core';
import { DirectoryFacade } from '@iot-platform/dalia/data-access';
import { DeviceTankHelpers } from '@iot-platform/dalia/util/devices';
import { BaseFacade, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceTank, DeviceTankState } from '@iot-platform/models/dalia';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { DeviceTanksActions } from '../actions';
import { DeviceTanksSelectors } from '../selectors/device-tanks.selectors';

@Injectable({
  providedIn: 'root'
})
export class DeviceTemplateTanksFacade extends BaseFacade<DeviceTank, Pagination, Filter> {
  currentTankIndex: Signal<number> = this.store.selectSignal(this.selector.selectCurrentTankIndex);
  protected readonly directoryFacade: DirectoryFacade = inject(DirectoryFacade);
  tankState: Signal<DeviceTankState> = computed(() => {
    const tank = this.currentEntity();
    const modbusTable = this.directoryFacade.modbusTable();
    return DeviceTankHelpers.getTankState(tank, modbusTable);
  });

  constructor(
    protected override store: Store,
    protected override selector: DeviceTanksSelectors
  ) {
    super(store, selector);
  }

  getAll() {
    noop();
  }

  setFilters(): void {
    noop();
  }

  setCurrentTank(tank: DeviceTank) {
    this.store.dispatch(DeviceTanksActions.setCurrentTank({ tank }));
  }

  setCurrentTankIndex(index: number) {
    this.store.dispatch(DeviceTanksActions.setCurrentTankIndex({ index }));
  }
}
