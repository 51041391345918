import { NgStyle } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, MatIcon, NgStyle],
  selector: 'iot-platform-ui-timeline-dot',
  templateUrl: './timeline-dot.component.html',
  styleUrls: ['./timeline-dot.component.scss']
})
export class TimelineDotComponent {
  icon = input<string>();
  label = input<string>();
  color = input<string>();

  style = computed(() => {
    const color = this.color();
    return { color };
  });
}
