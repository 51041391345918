import { TrackingData } from '../models/tracking-data.model';
import { TrackingProviders } from '../models/tracking-providers.enum';

export abstract class AbstractTrackingMapperService<T, R> {
  abstract getTrackingDataForProvider(trackingTag: T | undefined, trackingData: TrackingData): R;

  abstract getTrackingProviderName(): TrackingProviders;
}

export class FakeTrackingMapperService<T, R> extends AbstractTrackingMapperService<T, R> {
  constructor(private readonly provider: TrackingProviders) {
    super();
  }

  getTrackingProviderName(): TrackingProviders {
    return this.provider;
  }

  getTrackingDataForProvider(trackingTag: T | undefined, trackingData: TrackingData): R {
    return { ...trackingTag, ...trackingData } as unknown as R;
  }
}
